export class DateFunctions {
    /**
     * Converts a dateArray to a JavaScript date
     * @param {array} dateArray Array with 7 components (year, month, day, hour, minute, second, millis)
     * @returns {Date} JavaScript date
     */
    static arrayToJSDate(dateArray) {
        if (dateArray == null) {
            return null;
        }
        return new Date(dateArray[0], dateArray[1] - 1, dateArray[2],
            dateArray[3], dateArray[4], dateArray[5],
            dateArray[6] / 1e6);
    }

    static isoStringToJsDate(dateString) {
        if (dateString == null) {
            return null;
        }
        return new Date(dateString);
    }

    /**
     * Formats a dateArray to: YYYY-MM-DD'T'HH:mm:ss.SSS
     * @param {array} dateArray Array with 7 components (year, month, day, hour, minute, second, millis)
     * @returns {string} Formatted string
     */
    static arrayToISODateTime(dateArray) {
        const jsDate = DateFunctions.arrayToJSDate(dateArray);
        return jsDate.toISOString();
    }

    /**
     * Formats a dateArray to: YYYY-MM-DD
     * @param {string} date in ISO format
     * @returns {string} Formatted string
     */
    static toISODate(date) {
        if(!date) {
            return "";
        }
        const jsDate = new Date(date);
        return jsDate.toISOString().substring(0, 10);
    }

    /**
     * Formats a dateArray to: YYYY-MM-DD HH:mm:ss
     * @param {string} date in ISO format
     * @returns {string} Formatted string
     */
    static toFriendlyDateTime(date) {
        if(!date) {
            return "";
        }
        const jsDate = new Date(date);
        const isoString = jsDate.toISOString();
        return isoString.substring(0, 10) + " " + isoString.substring(11, 19);
    }

    /**
     * Formats a dateArray to: YYYY-MM-DD
     * @param {string} date in ISO format
     * @returns {string} Formatted string
     */

    static toFriendlyDate(date) {
        if(!date) {
            return "";
        }
        const jsDate = new Date(date);
        const isoString = jsDate.toISOString();
        return isoString.substring(0, 10);
    }

    static prettifyTimeOrDate(date) {
        if(!date) {
            return "";
        }
        const currentDateTime = new Date();
        const jsDate = new Date(date)

        const difference = currentDateTime - jsDate
        if (difference < 60000) {
            return `A few seconds ago`
        }
        if (difference < 3600000) {
            const minutes = Math.round(difference / 60000)
            return `${minutes} minute${minutes > 1 ? 's' : ''} ago`
        } else if (currentDateTime - jsDate < 43200000) {
            const hours = Math.round(difference / 3600000)
            return `About ${hours} hour${hours > 1 ? 's' : ''} ago`
        } else return this.toFriendlyDate(date)
    }
}