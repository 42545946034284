import React, {useEffect, useRef, useState} from "react";
import styles from "components/FormInputs/InputField.module.scss";
import {Icons} from "utils/Icons";
import F from "utils/F";

const defaultOptions = {
    type: 'text',
    initialValue: null, // gets overridden to empty
    validator: null,
    inputClass: null,
    inputPlaceholder: null,
    autoComplete: 'on',
    onEnterKey: null
};

const CreateInputField = (inputTitle, options) => {
    options = Object.assign(F.copy(defaultOptions), options);
    const [value, setInnerValue] = useState(options.initialValue || '');
    const valueRef = useRef(options.initialValue || '') // avoids stale state
    const [inputType, setInputType] = useState(options.type)
    const [inputStyle] = useState(options.style)
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        validate()
    })

    /**
     * @param setInlineErrorMessage In addition to returning any errors, these are set inline on the field.
     * @returns List of errors, or empty array.
     */
    const validate = (setInlineErrorMessage = false) => {
        if (options.validator) {
            const updatedErrors = options.validator(valueRef.current);
            if ((setInlineErrorMessage || errors.length > 0) && !F.arrayEquals(updatedErrors, errors)) {
                setErrors(updatedErrors)
            }
            return updatedErrors;
        }
        return []
    }

    const labelTransform = () => {
        if (value !== '') {
            return styles.floatLabel
        }
        return ''
    }

    const inputClass = `${styles.input} ${options.inputClass && options.inputClass} 
    ${errors.length > 0 && styles.error} ${options.type === 'password' && styles.passwordInput}`

    const getId = () => {
        return inputTitle.toLowerCase().replace(' ', '-')
    }

    const toggleShowPassword = () => {
        inputType === 'text' ? setInputType('password') : setInputType('text')
    }

    const renderShowPasswordIcon = () => {
        if (options.type === 'password') {
            if (inputType === 'password') {
                return Icons.view(styles.showPassword, toggleShowPassword)
            }
            return Icons.hide(styles.showPassword, toggleShowPassword)
        }
    }

    const getValue = () => {
        return valueRef.current;
    };

    const setValue = (newValue) => {
        if (value !== newValue) {
            setInnerValue(newValue);
            valueRef.current = newValue;
            validate();
            if (options.onChange) {
                options.onChange(newValue);
            }
        }
    }

    /**
     * Runs the supplied validator on this input. Returns false if there are no errors.
     */
    const hasErrors = () => {
        return validate().length > 0;
    }

    const onChange = (e) => {
        setValue(e.target.value)
    }

    const onKeyPress = (e) => {
        const code = e.keyCode || e.which;
        if (code === 13) {
            if (options.onEnterKey) {
                options.onEnterKey.current.click();
            }
        }
    }

    const input = inputType === 'textarea' ?
        <textarea id={getId()}
                  onChange={onChange}
                  value={value}
                  autoComplete={options.autoComplete}
                  placeholder={options.inputPlaceholder}
                  onKeyPress={onKeyPress}
                  className={`${styles.input} ${options.inputClass}`}

        /> :
        <input id={getId()}
               type={inputType}
               className={inputClass}
               name={inputTitle}
               style={inputStyle}
               value={value}
               onChange={onChange}
               onBlur={() => validate(true)}
               placeholder={options.inputPlaceholder}
               autoComplete={options.autoComplete}
               onKeyPress={onKeyPress}
        />

    const render = (
        <label key={getId()} className={styles.inputContainer}>
            {input}
            <div className={`${styles.inputTitle} ${labelTransform()}`}>
                {inputTitle && inputTitle}
            </div>
            {renderShowPasswordIcon()}
            {errors.length > 0 && <div className={styles.errors}>
                {errors.map(e => <span className={styles.errorMessage}>{e}</span>)}
            </div>}
        </label>);
    return {getValue, setValue, hasErrors, render};
};

export default CreateInputField;

