import {action, computed, makeAutoObservable} from 'mobx';
import PubSub from "pubsub-js";
import StoreUtils from "utils/StoreUtils";
import F from "utils/F";

class WorkflowStore {
    workflowTemplateMap = new Map();
    workflowMap = new Map();
    nodeMap = new Map();
    //
    initialised = false;
    filterText = '';

    constructor() {
        makeAutoObservable(this);
    }

    initialise = (workflowTemplates, workflows, nodes) => {
        workflowTemplates && this.setWorkflowTemplates(F.mapArrayByParam(workflowTemplates, wt => wt.code));
        workflows && this.setWorkflows(StoreUtils.mapArrayGroupByVersion(workflows));
        nodes && this.setNodes(StoreUtils.mapArrayGroupByVersion(nodes));

        PubSub.subscribe('ws.in', (action, message) => {
            if (message.eventType === 'SINGLE_UPDATE') {
                if (message.type === 'workflowTemplate') {
                    this.updateSingleWorkflowTemplate(message.payload);
                }
                if (message.type === 'workflow') {
                    this.updateSingleWorkflow(message.payload);
                }
                if (message.type === 'node') {
                    this.updateSingleNode(message.payload);
                }
            }
        });
        this.initialised = true;
    };


    setWorkflowTemplates(workflowTemplateMap) {
        this.workflowTemplateMap = workflowTemplateMap;
    }

    setWorkflows(workflowMap) {
        this.workflowMap = workflowMap;
    }

    setNodes(nodeMap) {
        this.nodeMap = nodeMap;
    }

    //

    @action updateSingleWorkflowTemplate(workflowTemplate) {
        StoreUtils.updateSingle(this.workflowTemplateMap, workflowTemplate);
    }

    @action updateSingleWorkflow(workflow) {
        StoreUtils.updateSingle(this.workflowMap, workflow);
    }

    @action updateSingleNode(node) {
        StoreUtils.updateSingle(this.nodeMap, node);
    }

    @action setFilterText(filterText) {
        this.filterText = filterText;
    }

    //

    getWorkflowTemplate(code) {
        return this.workflowTemplateMap.get(code);
    }

    @computed getWorkflow(workflowId, version = null) {
        return StoreUtils.getVersion(this.workflowMap.get(workflowId), version);
    }

    @computed get workflowMapLv() {
        return StoreUtils.getLatestVersions(this.workflowMap);
    }

    @computed get nodeMapLv() {
        return StoreUtils.getLatestVersions(this.nodeMap);
    }

    @computed getNodes(workflow) {
        const ids = workflow.nodes.map(wn => wn.nodeId);
        const filtered = Array.from(this.nodeMapLv.entries()).filter(n => ids.includes(n[0]));
        return Object.fromEntries(filtered);
    }

    @computed getNode(nodeId, version = null) {
        return StoreUtils.getVersion(this.nodeMap.get(nodeId), version);
    }

    @computed getNodesArray(workflow) {
        const ids = workflow.nodes.map(wn => wn.nodeId);
        return Array.from(this.nodeMapLv.entries())
            .filter(n => ids.includes(n[0]))
            .map(n => n[1]);
    }

    @computed getWorkflowForNode(nodeId) {
        return Array.from(this.workflowMapLv.values()).filter(w => w.nodes.some(wn => wn.nodeId === nodeId))
    }
}

export const workflowStore = new WorkflowStore();