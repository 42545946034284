import WorkflowWebService from "service/web/WorkflowWebService";
import {modalsStore} from "stores";
import {useNavigation} from "hooks/Navigation";

export const useCreate = () => {

    const navigation = useNavigation();

    const createWorkflow = async (templateCode) => {
        const workflow = await WorkflowWebService.createWorkflow({templateCode});
        modalsStore.showWorkflowModal && modalsStore.toggleWorkflowModal();
        navigation.goToUserPath('workflows/' + workflow.id);
    };

    return {createWorkflow}

}