import {userStore, workflowStore} from "stores";
import F from "utils/F";
import NodeService from "service/NodeService";

export default class WorkflowService {

    static getStageProgress(stages) {
        const completed = stages.filter(s => NodeService.isEndState(s)).length
        const total = stages.length

        return `${completed} / ${total}`
    }

    static getCurrentStage(stages) {
        const s = stages.find(s => s.status === 'IN_PROGRESS')
        if (s) {
            return s.name;
        }
        return stages[stages.length - 1].name;
    }

    static getActiveStage = (stages) => {
        const s = stages.find(s => s.status === 'IN_PROGRESS')
        if (s) {
            return s.id;
        }
        return stages[stages.length - 1].id;
    }

    static getFlowStatus = (flow) => {
        const nodes = workflowStore.getNodesArray(flow)
        if (nodes[nodes.length - 1].status === 'CANCELLED') {
            return 'CANCELLED'
        }
        if (nodes.every(n => NodeService.isEndState(n))) {
            return 'COMPLETED'
        } else {
            const node = nodes.filter(n => n.status === 'IN_PROGRESS')[0]
            return NodeService.getNodeStatus(node)
        }
    }

    static flowsByStatus = () => {
        let currentDrafts = [], awaitingSignatures = [], actionRequired = [], completed = [], rejected = [];

        if (workflowStore.initialised && userStore.user) {
            const workflows = F.arrayFromMapValues(workflowStore.workflowMapLv, {
                userFilter: true,
                userStore,
                excludeArchived: true
            });
            workflows.forEach(flow => {
                const nodes = workflowStore.getNodesArray(flow)
                if (nodes.every(n => NodeService.isEndState(n))) {
                    completed.push(flow)
                } else {
                    const node = nodes.filter(n => n.status === 'IN_PROGRESS')[0]
                    const nodeStatus = NodeService.getNodeStatus(node)
                    if (!node.parties || node.parties.length === 0
                        || node.parties.every(p => p.contactsTasks.every(ct => ct.task.status === 'DRAFT'))) {
                        currentDrafts.push(flow)
                    } else {
                        if (nodeStatus === 'ACTION_REQUIRED') {
                            actionRequired.push(flow);
                        }
                        if (nodeStatus === 'REJECTED') {
                            rejected.push(flow)
                        }
                        else {
                            awaitingSignatures.push(flow);
                        }
                    }
                }
            });
        }

        return {currentDrafts, awaitingSignatures, actionRequired, completed, rejected};
    };


}