import {makeAutoObservable} from 'mobx';

class ModalsStore {
    showPartyModal = false;
    showNewContractModal = false;
    showManagedCompanyModal = false;
    showParameterModal = false;
    showSigningRequest = false;
    showWorkflowModal = false;
    showImageUploadModal = false;

    editingParameterId = null;
    editingCompanyId = null;

    editingType = null;

    editingParam = null;

    constructor() {
        makeAutoObservable(this);
    }

    togglePartyModal = () => {
        this.showPartyModal = !this.showPartyModal;
    };

    toggleWorkflowModal = () => {
        this.showWorkflowModal = !this.showWorkflowModal;
    };

    toggleContractModal = () => {
        this.showNewContractModal = !this.showNewContractModal;
    };

    toggleManagedCompanyModal = () => {
        this.showManagedCompanyModal = !this.showManagedCompanyModal;
        if(this.showManagedCompanyModal === false) {
            this.editingCompanyId = null;
        }
    };

    toggleParameterModal = () => {
        this.showParameterModal = !this.showParameterModal;
    };

    toggleImageUploadModal = () => {
        this.showImageUploadModal = !this.showImageUploadModal;
    }

    setEditingParameterId = (id) => {
        this.editingParameterId = id;
    };

    setEditingCompanyId = (id) => {
        this.editingCompanyId = id;
    };

    setEditingType = (type) => {
        this.editingType = type
    }

}

export const modalsStore = new ModalsStore();
