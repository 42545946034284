import styles from 'LandingSite/layout/Products/Products.module.scss'
import React, {useState} from 'react'
import PropTypes from 'prop-types'
import SearchBox from "components/SearchBox/SearchBox";
import {observer} from "mobx-react";
import WorkflowSelector from "patterns/WorkflowSelector/WorkflowSelector";
import {useQuery} from "hooks/Navigation";
import questionsImg from 'images/Illustrations/questions.svg'
import {viewportStore} from "stores";

const ContractWorkflows = () => {

    const query = useQuery().get('search');
    const [searchText, setSearchText] = useState(query ? query : '');

    const renderHero = () => {
        return <div className={styles.hero}>
            <div className={styles.heroContent}>
                <div className={styles.heroText}>
                    <h1>Creating contracts has never been easier</h1>
                    <h5>Simply answer a few questions and let our smart contract tool do the rest.</h5>
                    {!viewportStore.isTablet &&
                        <SearchBox clearText={() => setSearchText('')} placeholder={'Search for a contract'}
                                   bg={'white'} value={searchText} onChange={e => setSearchText(e.target.value)}/>
                    }
                </div>
                <img className={styles.heroImage} src={questionsImg} alt={''}/>
            </div>
        </div>
    }

    return (
        <div>
            {renderHero()}
            <div className={styles.content}>
                <WorkflowSelector searchText={searchText} setSearchText={setSearchText}/>
            </div>
        </div>
    )
};

ContractWorkflows.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    summaries: PropTypes.array,
    heroImg: PropTypes.string
}
export default observer(ContractWorkflows)
