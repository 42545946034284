import {withRouter} from "react-router";
import * as React from "react";
import * as PropTypes from "prop-types";
import EventWebService from "service/web/EventWebService";
import F from "utils/F";
import Error404 from "../../LandingSite/layout/Error404/Error404";

@withRouter
class ErrorBoundary extends React.Component {
    state = {
        hasError: false
    };

    componentDidCatch = (error) => {
        EventWebService.event('ERROR', null, null,
            JSON.stringify(error, Object.getOwnPropertyNames(error)));
        this.setState({hasError: true});
        F.sleep(3000).then(() => {
            this.setState({hasError: false});
            if (this.props.redirectToDashboard) {
                this.props.history.push(`/`);
            }
        });
    }

    render() {
        if (this.state.hasError) {
            return <Error404 message='Sorry, we are having some issues dealing with your request'/>;
        }
        return this.props.children;
    }
}

ErrorBoundary.propTypes = {
    redirectToDashboard: PropTypes.bool
}

export default ErrorBoundary;