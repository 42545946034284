import styles from "components/WorkflowCard/WorkflowCard.module.scss";
import ProgressIcon from "components/ProgressIcon/ProgressIcon";
import WorkflowService from "service/WorkflowService";
import React from "react";
import Lozenge from "components/Lozenge/Lozenge";
import {StringFunctions} from "utils/StringFunctions";
import Button from "components/Button/Buttons";
import {useNavigation} from "hooks/Navigation";
import {useCreate} from "hooks/CreateUpdateDelete";
import ButtonGroup from "components/ButtonGroup/ButtonGroup";
import {userStore} from "stores";
import {useHistory} from "react-router-dom";
import NotifyButton from "components/NotifyButton";
import {getProductUrl} from "utils/Nav";

const WorkflowCard = ({flow, openFlow}) => {
    return <div className={styles.flowCard} onClick={() => openFlow(flow.id)}>
        <div className={styles.flowDetails}>
            <div className={styles.status}>
                <ProgressIcon size={'sm'}
                              status={WorkflowService.getFlowStatus(flow)}/>
            </div>
            <p style={{position: 'absolute', top: 12, left: 4}} className={styles.flowCategory}>{flow.category}</p>
            <h4 className={styles.flowCardName}>{flow.name}</h4>
        </div>
    </div>;
}

const WorkflowTemplateCard = ({flow, isComingSoon}) => {
    const navigation = useNavigation();
    const create = useCreate();
    const history = useHistory();

    const openFlow = () => {
        if (history.location.pathname.includes('user')) {
            create.createWorkflow(flow.code)
            return
        }
        navigation.goTo('/products/contract-workflows/' + getProductUrl(flow.code))
    }

    return <div className={styles.flowCard} key={flow.code}>
        <div className={styles.flowDetails}>
            <span className={styles.flowCategory}>{StringFunctions.getCategory(flow.category)}</span>
            <h4 className={styles.flowTitle} onClick={openFlow}>{flow.name}</h4>
            <div className={styles.flowDescriptionContainer}>
                <span className={styles.flowDescription}>{flow.description}</span>
            </div>
        </div>
        {isComingSoon ?
            <div className={styles.comingSoonBox}>
                <Lozenge style={{alignSelf: 'flex-end'}} isSmall content={'Coming soon'} colour={'Purple'}/>
                <NotifyButton flow={flow}/>
            </div>
            :
            <ButtonGroup addClass={styles.createButton}>
                <Button buttonId={'create-' + getProductUrl(flow.code)} style={{alignSelf: 'flex-end'}} title={userStore.isUser() ? 'Create' : 'Get Started'}
                        onClick={userStore.isUser() ? () => create.createWorkflow(flow.code) : () => navigation.goTo('/register', '/' + flow.code)}/>
            </ButtonGroup>
        }
    </div>
}


export {WorkflowCard, WorkflowTemplateCard}