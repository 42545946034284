import styles from 'LandingSite/layout/Pricing/Pricing.module.scss';
import React, {useEffect} from 'react';
import Button from "components/Button/Buttons";
import {Icons} from "utils/Icons";
import {userStore} from "stores";
import {observer} from "mobx-react";
import {Helmet} from "react-helmet";
import {useNavigation} from "hooks/Navigation";
import IconListItem from "components/IconListItem/IconListItem";
import imgSrc from "images/Illustrations/explore.svg";

const Pricing = observer(() => {

    useEffect(() => {
        window.scrollTo(0, 0);
    });

    const navigation = useNavigation();

    const showContact = () => {
        navigation.goTo('/contact');
    };

    const showRegister = () => {
        navigation.goTo('/register');
    };

    const renderPriceTier = (p) => {
        const user = userStore.isUser();

        return (
            <div key={p.title} className={styles.item}>
                <div className={styles.cardHeading}>
                    <h5>{p.title}</h5>
                    <span className={styles.headingInfo}>{p.description}</span>
                </div>
                <div className={styles.price}>
                    {p.price !== 'FREE PLAN' ? <h3>{p.price}</h3> :
                        <h3><s>£6 per month</s> Free for the first year!</h3>}
                </div>
                <span>{p.priceInfo}</span>
                {p.action === 'REGISTER' &&
                    <Button style={{width: '100%', marginTop: 24}} buttonId={'pricing-free-user-sign-up'}
                            onClick={user ? () => navigation.goToUserPath('manage-account') : showRegister}
                            title={user ? 'Go to my account' : 'Sign Up'}/>
                }
                {p.action === 'CONTACT' &&
                    <Button style={{width: '100%', marginTop: 24}} buttonId={'pricing-business-user-contact'}
                            onClick={showContact} title={'Contact us'}/>}
                <br/>
                <div className={styles.features}>
                    {p.title === 'Growing Business' && <h5>Everything in Creator, PLUS</h5>}
                    {p.title === 'Viewer' && <h5>View and manage contracts sent to you by an Acculex Creator</h5>}
                    {p.features.map(feature => <IconListItem icon={Icons.check()} text={feature}/>)}
                </div>
                <br/>
            </div>

        )
    }

    const basic = {
        title: 'Viewer',
        description: 'Sign and view contracts.',
        features: ['View and manage contracts you\'ve signed', 'Email notifications'],
        price: 'Free, forever',
        priceInfo: '',
        isFree: true,
        action: ''
    }
    const starter = {
        title: 'Creator',
        description: 'Draft custom contracts in minutes, no experience necessary.',
        highlight: true,
        price: 'FREE PLAN',
        priceInfo: 'billed monthly after the trial period is over',
        features: [
            'A single account, with independent profiles. Separate personal from business, and easily manage both. ',
            'Access to all our available contract types including new workflows as they become available',
            'Create an unlimited number of contracts, with free storage and management',
            'Unlimited e-signatures included, no need for expensive third party signing software',
            'Stay up to date with email notifications and reminders'
        ],
        action: 'REGISTER'
    }
    const business = {
        title: 'Growing Business',
        description: 'An inclusive management solution.',
        features: [
            'User ID verification to safeguard your business',
            'Audit trail for full compliance',
            'Reduce your risk. Included service liability up to £1,000 per year.*',
        ],
        price: 'Get a quote',
        priceInfo: 'plans are billed monthly or annually',
        action: 'CONTACT'
    }
    return (
        <>
            <Helmet>
                <title>Acculex | Pricing</title>
                <meta name='description'
                      content='Simple transparent pricing for small to large businesses. Choose the plan that suits
                      your needs. All plans include contract storage and electronic signatures'/>
            </Helmet>
            <div className={styles.hero}>
                <div className={styles.heroContent}>
                    <div className={styles.heroText}>
                        <h1 className={styles.blockHeading}>Flexible and affordable.</h1>
                        <h5>Monthly billing, cancel anytime.</h5>
                    </div>
                        <img className={styles.heroImage} src={imgSrc} alt={'explore our pricing'}/>
                </div>
            </div>
            <div className={styles.pricing}>
                <h1>Which plan is right for you?</h1>
                <div className={styles.content}>
                    {renderPriceTier(basic)}
                    {renderPriceTier(starter)}
                    {renderPriceTier(business)}
                </div>
                <p style={{marginTop: '32px'}}> For a package tailored to your needs and annual discounts, please
                    contact us.
                </p>
                <p>*To the extent that the claim arose due to an issue with the Service.</p>
            </div>
        </>
    );
});

export default Pricing;
