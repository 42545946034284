import styles from 'LandingSite/layout/Header/Header.module.scss';
import React, {useEffect, useRef, useState} from 'react';
import {Link, withRouter} from 'react-router-dom';
import Logo from 'images/icons/acculex-logo-Jan2021.svg';
import MobileMenu from "LandingSite/layout/Header/components/MobileMenu";
import {observer} from "mobx-react";
import HeaderUser from "LandingSite/layout/Header/components/HeaderUser";
import {Icons} from "utils/Icons";
import F from "utils/F";
import ExpandingMenu from "components/ExpandingMenu/ExpandingMenu";

const Header = (props) => {

    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const header = useRef();

    useEffect(() => {
        // setShowMobileMenu(false);
        window.addEventListener('resize', () => setShowMobileMenu(false));
        F.addShadow(header.current, 0);
    });

    const toggleMobileMenu = () => {
        setShowMobileMenu(!showMobileMenu);
    };

    const exclude = ['/login', '/register', '/account-verification', '/password', '/external/sign'];
    const path = props.location.pathname;
    if (exclude.some(e => path.includes(e))) {
        return <div/>
    }
    return (
        <header className={styles.siteHeader} ref={r => header.current = r}>
            <Link id={'header-logo'} to='/'>
                <img className={styles.logo} src={Logo} alt='Acculex logo'/>
            </Link>
            <div id={'header-menu-button'} className={styles.menuButton} onClick={toggleMobileMenu}>
                {Icons.menuBars(showMobileMenu ? 'open' : '')}
            </div>
            <div className={styles.headerMenu}>
                <ExpandingMenu openOnHover position={'DL'}
                               trigger={
                                   <span id={'header-home-nav'} className={styles.menuItem}>
                                       Products{Icons.chevronRight(styles.menuChev)}
                                   </span>
                               }>
                    <Link style={{whiteSpace: 'nowrap', padding: '8px 16px', color: '#424445'}}
                          to={'/products/contract-workflows'}>Contract Creation</Link>
                </ExpandingMenu>
                <Link id={'header-contact-nav'} className={styles.menuItem} to='/contact'>Contact</Link>
                <Link id={'header-pricing-nav'} className={styles.menuItem} to='/pricing'>Pricing</Link>
                <Link id={'header-pricing-nav'} className={styles.menuItem} to='/faqs'>FAQs</Link>
            </div>
            <MobileMenu showMobileMenu={showMobileMenu}
                        toggleMobileMenu={toggleMobileMenu}
            />
            <div className={styles.headerUser}>
                <HeaderUser showMobileMenu={showMobileMenu}
                />
            </div>
        </header>
    );
};

export default withRouter(observer(Header));