import HttpService from "service/web/HttpService";

export class CorrespondenceWebService {

    static sendMessage = async(data) => {
        await HttpService.postAsync('/correspondence/submit', data);
    }

    static subscribe = async(data) => {
        await HttpService.postAsync('/correspondence/subscribe', data)
    }

    static unsubscribe = async(data) => {
        await HttpService.postAsync('/correspondence/unsubscribe', data)
    }

    static upload = async(data) => {
        await HttpService.postAsync('/correspondence/upload', data)
    }
}