import {action, computed, makeAutoObservable} from 'mobx';
import StoreUtils from "utils/StoreUtils";
import PubSub from "pubsub-js";

const fixCompany = (c) => { // TODO: add support for multiple addresses
    c.initials = StoreUtils.initials(c);
    if(!c) {
        return;
    }
    c.address = c.registeredAddresses[0];
};

const fixContact = (c) => { // TODO: add support for multiple addresses
    StoreUtils.setContactName(c);
    if (!c || !c.addresses) {
        return;
    }
    c.address = c.addresses[0];
};


class PartyStore {
    appUserMap = new Map();
    contactMap = new Map();
    companyMap = new Map();
    //
    selectedPartyId = null;
    filterText = '';
    //
    initialised = false;

    constructor() {
        makeAutoObservable(this);
    }

    initialise = (appUsers, contacts, companies) => {
        if (appUsers) {
            appUsers.forEach(c => {
                fixContact(c);
                c.type = 'APPUSER';
            });
            this.setAppUsers(StoreUtils.mapArrayGroupByVersion(appUsers));
        }
        if (contacts) {
            contacts.forEach(c => {
                fixContact(c);
                c.type = 'CONTACT';
            });
            this.setContacts(StoreUtils.mapArrayGroupByVersion(contacts));
        }
        if (companies) {
            companies.forEach(c => fixCompany(c));
            this.setCompanies(StoreUtils.mapArrayGroupByVersion(companies));
        }

        PubSub.subscribe('ws.in', (action, message) => {
            if (message.eventType === 'SINGLE_UPDATE') {
                if (message.type === 'legalEntity') {
                    this.updateSingleLegalEntity(message.payload);
                }
                if (message.type === 'contact') {
                    this.updateSingleContact(message.payload);
                }
                if (message.type === 'applicationUser') {
                    this.updateSingleAppUser(message.payload);
                }
            }
        });
        this.initialised = true;
    };

    @action setContacts(contactMap) {
        this.contactMap = contactMap;
    };

    @action setCompanies(companyMap) {
        this.companyMap = companyMap;
    };

    @action setAppUsers(appUserMap) {
        this.appUserMap = appUserMap;
    };

    @action updateSingleLegalEntity(company) {
        fixCompany(company);
        StoreUtils.updateSingle(this.companyMap, company);
    };

    @action updateSingleContact(contact) {
        fixContact(contact);
        contact.type = 'CONTACT';
        StoreUtils.updateSingle(this.contactMap, contact);
    };

    @action updateSingleAppUser(appUser) {
        fixContact(appUser);
        appUser.type = 'APPUSER';
        StoreUtils.updateSingle(this.appUserMap, appUser);
    };

    @action setFilterText(filterText) {
        this.filterText = filterText;
    };

    @action setSelectedParty(id) {
        this.selectedPartyId = id;
    };

    getCompany(id, version = null) {
        return StoreUtils.getVersion(this.companyMap.get(id), version);
    };

    getContact(id, version = null) {
        return StoreUtils.getVersion(this.contactMap.get(id), version);
    };

    getAppUser(id, version = null) {
        return StoreUtils.getVersion(this.appUserMap.get(id), version);
    };

    isIndividualSelected() {
        return this.selectedPartyId && (this.contactMap.has(this.selectedPartyId) || this.appUserMap.has(this.selectedPartyId));
    };

    isCompanySelected() {
        return this.selectedPartyId && this.companyMap.has(this.selectedPartyId);
    };

    // retrieve an array of individuals linked to a company

    getLinkedContacts(coId) {
        const linkedContacts = [];
        this.contactMapLv.forEach((value, key) => {
            let contact = this.contactMapLv.get(key);
            if (contact.entityRoles.length > 0) {
                contact.entityRoles.forEach(pR => {
                    if (pR.partyId === coId) {
                        linkedContacts.push(contact)
                    }
                })
            }
        });
        return linkedContacts
    };

    @computed get appUsersMapLv() {
        return StoreUtils.getLatestVersions(this.appUserMap);
    };

    @computed get contactMapLv() {
        return StoreUtils.getLatestVersions(this.contactMap);
    };

    @computed get companyMapLv() {
        return StoreUtils.getLatestVersions(this.companyMap);
    };

    // get an array of individuals linked to the current selected company
    @computed get linkedContacts() {
        return this.getLinkedContacts(this.selectedPartyId)
    };

    @computed get selectedParty() {
        if (this.selectedPartyId == null) {
            return null;
        }
        if (this.contactMap.has(this.selectedPartyId)) {
            return this.getContact(this.selectedPartyId);
        }
        if (this.appUserMap.has(this.selectedPartyId)) {
            return this.getAppUser(this.selectedPartyId);
        }
        return this.getCompany(this.selectedPartyId);
    };

    getContactsForCompany(companyId) {
        if (!companyId || !this.contactMap || !this.contactMap.has(companyId)) {
            return null;
        }
        return this.contactMapLv.get(companyId);
    };

    isAppUser(party) {
        return party.type === 'APPUSER';
    };

    isContact(party) {
        return party.type === 'CONTACT';
    };

    isCompany(party) {
        return party.type === 'COMPANY';
    };

    getParty(party) {
        const id = party.partyId || party.id;
        const version = party.version;
        if (this.isAppUser(party)) {
            return this.getAppUser(id, version);
        }
        if (this.isContact(party)) {
            return this.getContact(id, version);
        }
        if (this.isCompany(party)) {
            return this.getCompany(id, version);
        }
    };

    @computed get selectedCompanyContacts() {
        return this.getContactsForCompany(this.selectedPartyId);
    };
}

export const partyStore = new PartyStore();
