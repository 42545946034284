const ColourGenerator = (id) => {

    // Links a random colour to the user / party id to create a background for the user icon

    const getHash = (id) => {
        var hash = 0, i;
        if (id.length === 0) return hash;
        for (i = 0; i < id.length; i++) {
            hash += (id.charCodeAt(i) - "0".charCodeAt(0));
        }
        return hash;
    }

    const colors = ['#F8694A', '#C2360B', '#304988', '#096237', '#306C6F', '#43325F', '#43979B', '#FCA701', '#3A68DF', '#58595B', '#FDCF2E', '#0A8A54', '#8664BD']

    return colors[getHash(id) % colors.length]

}

export default ColourGenerator