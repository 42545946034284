import 'LandingSite/layout/AccountServices/AccountServices.scss';
import React, {useRef, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import AuthWebService from "service/web/AuthWebService";
import Button from "components/Button/Buttons";
import InputField from "components/FormInputs/InputField";
import Nav from "utils/Nav";
import {useNavigation} from "hooks/Navigation";
import {userStore} from "stores";
import {action} from "mobx";

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const history = useHistory();
    const actionLogin = useRef();
    const navigation = useNavigation();

    const handleLogin = async () => {
        await AuthWebService.logIn(email, password);
        setEmail('')
        setPassword('')
        if (userStore.redirectLink !== null) {
            const redirectLink = userStore.redirectLink
            action (() => userStore.redirectLink = null)();
            navigation.goTo(redirectLink)
        } else {
            navigation.goToUserPath('');
        }
    };

    const showRecover = () => {
        history.push('/password-reset')
    };

    return (
        <form>
            <h4>Please sign in</h4>
            <div style={{height: '24px'}}/>
            <InputField inputId={'email'}
                        inputValue={email}
                        type={'text'}
                        onChange={(e) => setEmail(e.target.value)}
                        inputTitle={'Email Address'}
                        onKeyPress={(e) => Nav.keyPressWithTarget(e, actionLogin)}
                        autoComplete={'username'}
            />
            <InputField inputId={'password'}
                        inputValue={password}
                        type={'password'}
                        onChange={(e) => setPassword(e.target.value)}
                        inputTitle={'Password'}
                        onKeyPress={(e) => Nav.keyPressWithTarget(e, actionLogin)}
                        autoComplete={'current-password'}
            />
            <div style={{height: '8px'}}/>
            <Button buttonId={'action-login'} setRef={actionLogin} style={{width: '100%'}}
                    onClick={handleLogin} title={'Sign in'}/>
            <div className="create-account">
                <div tabIndex={0} className='forgot-password'
                     onClick={showRecover}>Forgotten your password?
                </div>
                <span>Don't have an account? </span>
                <Link className="account-create-link" to={'/register'}>
                    Create one here
                </Link>
            </div>
        </form>
    );
}

export default Login
