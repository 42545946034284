import HttpService, {RetryPolicy} from "service/web/HttpService";
import {userStore} from "../../stores";

export default class DataWebService {
    static getDataAsync = async () => {
        return HttpService.getAsync('/data/',
            {retryPolicy: RetryPolicy.RETRY_WITH_BACKOFF})
            .then(r => {
                if(userStore.networkReconnected === false) {
                    userStore.setNetworkStatus(true);
                }
                return r.data;
            });
    }

    static getBinaryDataAsync = async () => {
        return HttpService.getAsync('/data/binary',
            {retryPolicy: RetryPolicy.RETRY_WITH_BACKOFF})
            .then(r => {
                if(userStore.networkReconnected === false) {
                    userStore.setNetworkStatus(true);
                }
                return r.data;
            });
    }
}