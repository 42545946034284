import {userStore} from "stores";
import ConfigService from "service/ConfigService";
import CookieService from "service/CookieService";
import ErrorHandler from "service/ErrorHandler";
import WebClientService from "service/web/WebClientService";
import F from "utils/F";

export const RetryPolicy = Object.freeze({
    NO_RETRY: {retries: 1, backoff: false},
    RETRY_AND_GIVE_UP: {retries: 3, backoff: false},
    RETRY_WITH_BACKOFF: {retries: 100, backoff: true},
    RETRY_FOREVER: {retries: 1000, backoff: false}
});

export const NotificationPolicy = Object.freeze({
    SHOW: {show: true},
    HIDE: {show: false}
});

export const ReportIssuePolicy = Object.freeze({
    REPORT: {report: true},
    SUPPRESS: {report: false}
});

const DEFAULT_OPTIONS = {
    retryPolicy: RetryPolicy.NO_RETRY,
    notificationPolicy: NotificationPolicy.SHOW,
    reportIssuePolicy: ReportIssuePolicy.REPORT,
    errorHandler: null
};

export default class HttpService {
    static getAsync = async (endpoint, options = {}) => {
        return this._handleRequest(WebClientService.getWebClient().get, endpoint, undefined, options);
    };

    static downloadFile = async (endpoint) => {
        const api = await this._getApi();
        const link = document.createElement("a");
        link.href = `${api}${endpoint}`;
        document.body.appendChild(link);
        link.click();
        link.remove();
    };

    static postAsync = async (endpoint, data, options = {}) => {
        return this._handleRequest(WebClientService.getWebClient().post, endpoint, data, options);
    };

    static putAsync = async (endpoint, data, options = {}) => {
        return this._handleRequest(WebClientService.getWebClient().put, endpoint, data, options);
    };

    static deleteAsync = async (endpoint, options = {}) => {
        return this._handleRequest(WebClientService.getWebClient().delete, endpoint, undefined, options);
    };

    static _handleRequest = async (webFunction, endpoint, data, options) => {
        const optionsWithDefaults = Object.assign(F.copy(DEFAULT_OPTIONS), options);
        const {retryPolicy, notificationPolicy, reportIssuePolicy} = optionsWithDefaults;
        const config = this._getConfig();
        const api = await this._getApi();
        const url = `${api}${endpoint}`;
        const delay = 2000;
        for (let count = 0; count < retryPolicy.retries; count++) {
            try {
                const asyncWebCall = data !== undefined ? webFunction(url, data, config) : webFunction(url, config);
                const response = await asyncWebCall;
                if (response) {
                    return response;
                }
            } catch (error) {
                if (options.errorHandler) {
                    return options.errorHandler(error)
                }
                const showNotification = count === 0 && notificationPolicy.show;
                const reportIssues = reportIssuePolicy.report;
                const canRetry = await ErrorHandler.defaultErrorHandler(error, showNotification, reportIssues);
                if (!canRetry || count === retryPolicy.retries - 1) {
                    throw error;
                }
            }
            const currentDelay = retryPolicy.backoff ? delay + (Math.pow(count, 2) * 10 + 100) : delay;
            await F.sleep(currentDelay);
        }
    };

    static _getConfig = () => {
        const headers = {};
        const token = CookieService.get('token');
        if (token) {
            headers['Authorization'] = token;
            headers['CompanyId'] = userStore.selectedCompanyId;
        }
        return {
            headers,
            timeout: 10000
        };
    }

    static _getApi = async () => {
        return ConfigService.getConfigAsync('api_url');
    }
}