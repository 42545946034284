import {action, computed, makeAutoObservable} from 'mobx';
import {partyStore} from "stores/index";
import PubSub from "pubsub-js";
import F from "utils/F";

class UserStore {
    authUser = null;
    selectedCompanyId = null;
    networkReconnected = null;
    redirectLink = null;

    initialised = false;

    constructor() {
        makeAutoObservable(this);
    }

    initialise = (authUser) => {
        this.setAuthUser(authUser);

        PubSub.subscribe('ws.in', (action, message) => {
            if (message.eventType === 'SINGLE_UPDATE') {
                if (message.type === 'authUser') {
                    this.setAuthUser(message.payload);
                }
            }
        });

        this.initialised = true;
    };

    @action setAuthUser(authUser) {
        this.authUser = authUser;
    }

    @action setSelectedCompany = (id) => {
        if (id !== this.selectedCompanyId) {
            this.selectedCompanyId = id;
            partyStore.setSelectedParty(null);
        }
    };

    @action setNetworkStatus = (status) => {
        this.networkReconnected = status;
    }

    @computed get user() {
        if (!this.authUser || !this.authUser.party) {
            return null;
        }
        if (this.authUser.party.type === 'CONTACT') {
            return partyStore.getContact(this.authUser.party.partyId);
        }
        if (this.authUser.party.type === 'APPUSER') {
            return partyStore.getAppUser(this.authUser.party.partyId);
        }
        return null;
    }

    @computed isExternal() {
        if (!this.authUser || !this.user) {
            return false;
        }
        return this.authUser.authorities.includes('ROLE_EXTERNAL')
    }

    @computed isUser() {
        if (!this.authUser || !this.user) {
            return false;
        }
        return this.authUser.authorities.includes('ROLE_USER')
    }

    @computed isAdmin() {
        if (!this.authUser) {
            return false;
        }
        return this.authUser.authorities.includes('ROLE_ADMIN')
    }

    @computed get managedLegalEntities() {
        if (this.authUser === null || partyStore.companyMapLv.size === 0) {
            return [];
        }
        const legalEntityArray = Array.from(partyStore.companyMapLv.values());
        return legalEntityArray.filter(c => c.entityRoles.some(er => F.objectEquals(er.party, this.authUser.party)));
    }
}

export const userStore = new UserStore();