import React from 'react';
import styles from 'components/SearchResults/SearchResults.module.scss';

const SearchResults = ({children, id}) => {

    return (
        <ul id={'search-results-' + (id && id)} className={styles.searchResults}>
            {children}
        </ul>
    )
}

export default SearchResults