import React from 'react'
import PropTypes from 'prop-types'
import styles from 'components/ContactCard/ContactCard.module.scss'
import UserImage from "components/UserCircle/UserImage";
import CheckCircleField from "components/FormInputs/CheckCircleField";

const ContactCard = ({party, size, floating, isSmall, color, type, style, label, hideEmail}) => {

    const name = () => {
        if (party.name) {
            return party.name
        } else {
            return `${party.firstName} ${party.lastName}`
        }
    }

    const contactCardStyle = `${styles.contactCard} ${floating && styles.floating}`

    return (
        <div style={style} className={contactCardStyle}>
            <div className={styles.user}>
                <UserImage type={type} image={party.profileImageId} canEdit={type} id={party.id} size={(isSmall && 'sm') || size}
                           initials={party.initials}/>
            </div>
            <div className={styles.details}>
                <span className={styles.name + ' ' + (isSmall && styles.small)}>
                    {name()}
                    {label && <span className={styles.role}><span style={{marginRight: 4}}>| </span>{label}</span>}
                </span>
                {!hideEmail && party.email && <span className={styles.email}>{party.email}</span>}
            </div>
        </div>
    )
}

ContactCard.propTypes = {
    size: PropTypes.string,
    floating: PropTypes.bool,
    isSmall: PropTypes.bool,
    color: PropTypes.string,
    type: PropTypes.oneOf(['COMPANY', 'USER']),
};

ContactCard.defaultProps = {
    floating: false
}

const SelectableContactCard = ({party}) => {
    return (
        <div className={styles.contactCard}>
            <UserImage id={party.id} initials={party.initials} size={'md'}/>
            <div className={styles.details}>
                <div className={styles.name}>{party.name}</div>
                <label className={styles.companyDetails}>
                    <div style={{flexBasis: '92%'}}>
                        <span className={styles.role}>{party.role} | </span>
                        <span className={styles.companyName}>{party.companyName}</span>
                        <div className={styles.email}>{party.email}</div>
                    </div>
                    <div className={styles.checked}>
                        <CheckCircleField inputId={party.contactId + '-check'} inputTitle={''}/>
                    </div>
                </label>
            </div>
        </div>
    )
}

export {SelectableContactCard}
export default ContactCard