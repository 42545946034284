import style from './index.scss';

import React, {Suspense, useEffect} from 'react'
import {createRoot} from "react-dom/client";
import {BrowserRouter} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import {Route, Switch, useHistory} from "react-router";

import PrivacyConsent from "LandingSite/layout/Home/PrivacyConsent";
import {NotificationsContainer} from "./service/Notifications";
import EventWebService from "./service/web/EventWebService";
import {observer} from "mobx-react";
import {initialiseStores, viewportStore} from "./stores";
import Loader from "./components/Loader/Loader";
import ScrollToTop from "./utils/ScrollToTop";
import WebSocketsService from "./service/web/WebSocketsService";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import ErrorHandler from "./service/ErrorHandler";
import ConfigService from "service/ConfigService";
import LandingSite from "LandingSite/LandingSite";

const Admin = React.lazy(() => import("Admin/Admin"));
const UserApp = React.lazy(() => import("UserApp/UserApp"));
const ExternalUserApp = React.lazy(() => import("UserApp/layout/ExternalUserApp/ExternalUserApp"));

const resizeListener = () => viewportStore.setViewWidth(window.innerWidth);
ConfigService.init();

const PrimaryLayout = observer(() => {
    const history = useHistory();

    useEffect(() => {

        let prevPath = history.location.pathname

        // register global rejection handler
        window.onunhandledrejection = ErrorHandler.handleGlobalRejection;

        viewportStore.setViewWidth(window.innerWidth); // initialise on load
        window.addEventListener('resize', resizeListener); // reset on change

        EventWebService.initialise();
        EventWebService.pageView(prevPath, document.referrer)

        const fetchData = async () => {
            await WebSocketsService.init();
            await initialiseStores();
        }

        fetchData();

        history.listen((location) => {
            EventWebService.pageView(location.pathname, prevPath);
            prevPath = location.pathname
        })

        return () => {
            window.removeEventListener('resize', resizeListener);
        }
    }, [history]);

    return (
        <>
            <Helmet defaultTitle='Acculex' titleTemplate='%s | Acculex'>
                <title>Acculex | AI Powered Legal Services | Contract Management | Electronic Signature</title>
                <meta property="og:image" content="https://acculex.co.uk/Acculex_share.png" data-react-helmet={true}/>
                <meta name="description"
                      content="Acculex provides affordable online legal services and contract management tools. Our mission? To empower small and medium businesses to meet their legal needs in a cost effective way."/>
                <meta charSet='utf-8'/>
                <link rel="canonical" href="https://acculex.co.uk"/>
                <meta name='robots' content='index,follow'/>
            </Helmet>
            <NotificationsContainer/>
            <Suspense fallback={<div style={{height: '100vh', background: '#fff'}}><Loader fullscreen/></div>}>
                <div className={style.contentContainer}>
                    <Switch>
                        <Route path='/external' component={ExternalUserApp}/>
                        <Route path='/user/:accId' component={UserApp}/>
                        <Route path='/admin' component={Admin}/>
                        <Route component={LandingSite}/>
                    </Switch>
                </div>
            </Suspense>
            <PrivacyConsent/>
        </>
    );
});

const root = createRoot(document.getElementById('root'))
root.render(
    <BrowserRouter>
        <ErrorBoundary>
            <ScrollToTop/>
            <PrimaryLayout/>
        </ErrorBoundary>
    </BrowserRouter>
);