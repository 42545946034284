import React from "react";
import {userStore} from "stores";
import {PERSONAL_ACC_ID} from "utils/Nav";

const {useHistory, useLocation, useParams} = require("react-router-dom");

export const useQuery = () => {
    const {search} = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const useNavigation = () => {
    const history = useHistory();
    const location = useLocation();
    const params = useParams();
    const {search} = location;
    const queryParams = React.useMemo(() => new URLSearchParams(search), [search]);

    const nthIndex = (str, pat, n) => {
        let L = str.length, i = -1;
        while (n-- && i++ < L) {
            i = str.indexOf(pat, i);
            if (i < 0) break;
        }
        return i;
    }

    const goToUserPath = (path) => {
        let selectedAccount = userStore.selectedCompanyId;
        if (!path) {
            const currentPath = location.pathname;
            const idx = nthIndex(currentPath, '/', 3);
            if (idx >= 0) {
                path = currentPath.substring(idx + 1);
            } else {
                path = '';
            }
        }
        if (selectedAccount == null) {
            selectedAccount = PERSONAL_ACC_ID;
        }
        history.push(`/user/${selectedAccount}/${path}`);
    }

    const goTo = (path, referrer = null) => {
        let from
        if (referrer) {
            from = referrer
        } else {
            from = location.pathname
        }
        history.push(path, {from: from});
    }

    const addParam = (param) => {
        history.push("?" + param)
    }

    return {
        goTo, goToUserPath, location, params, queryParams, addParam
    }
};
