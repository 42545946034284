import Notifications from 'service/Notifications';
import AuthWebService from 'service/web/AuthWebService';
import EventWebService from 'service/web/EventWebService';
import F from 'utils/F';
import {userStore} from "stores";

export default class ErrorHandler {


    static _handle = async (title, message, isWarning, error, showNotification, reportIssues) => {
        if (showNotification) {
            if (isWarning) {
                Notifications.warn(message, title);
            } else {
                Notifications.error(message, title);
            }
        }
        const category = isWarning ? 'WARN' : 'ERROR';
        if (reportIssues) {
            if (error) {
                await EventWebService.event(category, null, null,
                    JSON.stringify(error, Object.getOwnPropertyNames(error)));
            } else {
                await EventWebService.event(category, null, null, message);
            }
        }
    };

    static handleGlobalRejection = async (event) => {
        await ErrorHandler.defaultErrorHandler(event.reason);
    };

    static defaultErrorHandler = async (error, showNotification, reportIssues) => {
        let isWarning = true;
        let title = 'Sorry';
        let message = '';
        let canRetry = false;
        let then = () => {
        };
        if (error.response) {
            if (error.response.status === 403) {
                title = 'Please login';
                message = 'Session expired';
                then = async () => {
                    F.sleep(3000).then(() => {AuthWebService.logOut();window.location.href = '/login'});
                }
            }
            if (error.response.status === 409) {
                canRetry = true;
                showNotification = false;
                reportIssues = false;
            }
            if (error.response.data !== undefined
                && error.response.data.message !== undefined) {
                message = error.response.data.message;
            }
            if (error.response.status === 429) {
                title = 'Sorry, we are experiencing a large number of requests';
                message = 'We\'ll be back shortly';
                canRetry = true;
            }
        }
        if (error.message !== undefined) {
            if (error.message.toLowerCase().includes('network')) {
                title = 'Sorry, we are having issues contacting the server';
                message = 'Please check you are connected to the Internet. Retrying...';
                isWarning = false;
                canRetry = true;
                userStore.setNetworkStatus(false);
            }
            if (error.message.includes('timeout') && error.message.includes('exceeded')) {
                title = 'Sorry, we are having trouble reaching our servers';
                message = 'We\'ll be back shortly';
                canRetry = true;
            }
            if (message === '') {
                message = error.message;
            }
        }
        await ErrorHandler._handle(title, message, isWarning, error, showNotification, reportIssues);
        await then();
        return canRetry;
    };
}