import React, {useState} from 'react';
import styles from "components/UserCircle/UserImage.module.scss";
import PropTypes from 'prop-types';
import ColourGenerator from "utils/ColourGenerator";
import {Icons} from "utils/Icons";
import {binaryStore, modalsStore} from "stores";
import {observer} from "mobx-react";
import ContactCard from "components/ContactCard/ContactCard";

const UserImage = observer((props) => {

    const [showContact, setShowContact] = useState(false);

    const sizeClass = () => {
        switch (props.size) {
            case "sm":
                return styles.small;
            case "md":
                return styles.medium;
            case "lg":
                return styles.large;
            case "xlg":
                return styles.xLarge;
            default :
                return styles.medium;
        }
    }

    const image = props.image && props.image.startsWith("data") ? props.image : binaryStore.getBinaryData(props.image);

    const openImageModal = (id, type) => {
        modalsStore.setEditingType(type)
        modalsStore.setEditingCompanyId(id)
        modalsStore.toggleImageUploadModal()
    }

    const initials = props.initials ? props.initials.toUpperCase() : Icons.spinner()

    const colorId = props.id ? props.id : ''

    const circleClass = `${styles.icon} ${sizeClass()}`

    const renderEditImage = () => {
        return props.type &&
            <div id={'user-image-upload'} onClick={() => openImageModal(props.id, props.type)} className={styles.editTip}>
                <span>{Icons.camera(styles.cameraIcon)}</span>
            </div>
    }

    const renderImage = () => {
        return <img className={styles.userImage} src={image} alt={'user-profile'}/>
    }

    return <>
        <div onMouseOver={() => setShowContact(true)} onMouseLeave={() => setShowContact(false)} style={props.style} className={circleClass}
             onClick={props.onClick}>
            {image ? renderImage() :
                <span style={{backgroundColor: ColourGenerator(colorId)}}
                      className={styles.initials}>{initials}</span>}
            {renderEditImage()}
        </div>
        {showContact && props.party && <span>
            <ContactCard style={{position: 'absolute', zIndex: 100}} party={props.party} floating/>
            </span>
        }
    </>;

});

UserImage.propTypes = {
    id: PropTypes.string,
    initials: PropTypes.string,
    image: PropTypes.string,
    onClick: PropTypes.func,
    style: PropTypes.string,
    size: PropTypes.oneOf(['sm', 'md', 'lg', 'xlg']),
    type: PropTypes.oneOf(['COMPANY', 'USER']),
}

export default UserImage;
