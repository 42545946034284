import React, {useState} from "react";
import {userStore} from "stores";
import {CorrespondenceWebService} from "service/web/CorrespondenceWebService";
import Notifications from "service/Notifications";
import InputField from "components/FormInputs/InputField";
import ConfirmationModal from "modals/ConfirmationModal/ConfirmationModal";
import {Icons} from "utils/Icons";
import Button from "components/Button/Buttons";

const NotifyButton = ({flow, isSmall = true}) => {
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [userEmail, setUserEmail] = useState('')

    const submitNotifyRequest = async () => {
        const email = userStore.isUser() ? userStore.user.email : userEmail

        await CorrespondenceWebService.subscribe({
            subscriptionCode: flow.code,
            subscriptionType: 'workflow',
            email
        });

        Notifications.success('Thanks for subscribing. We\'ll let you know when this workflow becomes available ');
        setShowConfirmationModal(false);
    }

    const notify = () => {
        if (userStore.isUser()) {
            submitNotifyRequest()
            return
        }
        setShowConfirmationModal(true);
    }

    return <>
        <ConfirmationModal submit={submitNotifyRequest}
                           cancelMessage={'Cancel'} confirmMessage={'Get notified'}
                           isOpen={showConfirmationModal} toggle={setShowConfirmationModal}
                           title={'Sign up for notifications'}
                           text={'We\'ll let you know when ' + flow.name + ' becomes available'}>
            <InputField onBlur={() => {
            }} inputValue={userEmail} inputTitle={'Email Address'}
                        onChange={e => setUserEmail(e.target.value)}/>
        </ConfirmationModal>
        <Button onClick={notify} isSmall={isSmall}
                iconAlignRight icon={Icons.notification()} isSecondary
                title={'Notify me'}/>
    </>

}

export default NotifyButton