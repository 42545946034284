import HttpService from "service/web/HttpService";
import CookieService from "../CookieService";
import {initialiseStores, userStore} from "stores";
import {action} from "mobx";

export default class AuthWebService {

    static changePasswordWithToken = async (token, newPassword) => {
        await HttpService.postAsync('/auth/change-password-token', {token, newPassword});
    }

    static updateUser = async (user) => {
        await HttpService.postAsync('/auth/update-account', user);
    }

    static isFreeUser() {
        return true;
    }

    static isPremiumUser() {
        return false;
    }

    static logIn = async (email, password) => {
        const response = await HttpService.postAsync('/login', {email, password})
        await CookieService.set('token', response.headers.authorization);
        await initialiseStores();
    }

    static authenticateWithKey = async (key) => {
        const token = "Temp_" + key;
        const currentToken = CookieService.get('token');
        if (currentToken !== token) {
            await CookieService.set('token', token);
            await initialiseStores();
        }
    }

    static logOut = async () => {
        action (() => userStore.redirectLink = null)();
        CookieService.remove('token', '/');
        await initialiseStores();
    }

    static recover = async (email) => {
        await HttpService.postAsync('/auth/recover', {email});
    }

    static register = async (user) => {
        return HttpService.postAsync('/auth/register', user);
    }

    static subscriptionRequest = async ({email, providerToken, plan}) => {
        await HttpService.postAsync('/subscription/request', {email, providerToken, plan});
    }

    static verifyAccount = async (token) => {
        return HttpService.getAsync('/auth/verify/' + token);
    }

    static uploadProfileImage = async (id, image) => {
        return HttpService.postAsync('/auth/profile-image', {id, image}).then(r => r.data);
    }
}