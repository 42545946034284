import React from "react";
import styles from 'components/Loader/Loader.module.scss'

// Loader always needs its parents background colour set

const Loader = ({fullscreen = false, invert = false, message = '', background = 'inherit'}) => {
    const loaderClass = styles.loaderContainer + ' ' + (fullscreen ? styles.fullscreen : '');

    return (
        <div style={{background: background}} className={loaderClass}>
            <h4 style={{marginBottom: 48}}>{message}</h4>
            <div className={styles.loader}>
                <div className={styles.rect1 + ' ' + (invert && styles.invert)}/>
                <div className={styles.rect4 + ' ' + (invert && styles.invert)}/>
                <div className={styles.rect2 + ' ' + (invert && styles.invert)}/>
                <div className={styles.rect3 + ' ' + (invert && styles.invert)}/>
            </div>
        </div>
    )
};

export default Loader