import React, {useRef, useState} from 'react'
import PropTypes from 'prop-types'
import styles from 'components/ExpandingMenu/ExpandingMenu.module.scss'
import {Icons} from "utils/Icons";
import {FloatButton, IconButton} from "components/Button/Buttons";
import useOnClickOutside from "hooks/useOnClickOutside";

const ExpandingMenu = (props) => {

    const [isOpen, setOpen] = useState(false)
    const menuRef = useRef();
    useOnClickOutside(menuRef, () => setOpen(false))

    const keyPress = (event) => {
        const code = event.keyCode || event.which;
        if (code === 13) {
            toggleMenu()
        }
    }

    const toggleMenu = () => {
        setOpen(!isOpen)
    };

    const menuPosition = () => {
        switch (props.position) {
            case 'DR' :
                return {right: 0};
            case 'DL' :
                return {left: 0};
            case 'TR' :
                return {right: 0, bottom: 'calc(100% + 4px)'};
            case 'TL' :
                return {left: 0, bottom: '100%'};
            case 'L' :
                return {right: 40, top: 0}
            default :
                return {right: 0, top: 'calc(100% + 4px)'}
        }
    }

    return (
        <div id={props.id} ref={menuRef}
             onMouseLeave={() => props.openOnHover && setOpen(false)}
             className={styles.expandingMenu}
             data-for={props.toolTip && props.toolTip.id}
             data-tip={props.toolTip && props.toolTip.title}>
            {props.fab ? <FloatButton buttonId={'create-new'} onClick={toggleMenu} activeState={isOpen}/> :
                (props.trigger ?
                        <div onMouseEnter={() => props.openOnHover && setOpen(true)}
                             tabIndex={0} style={{lineHeight: 0}}
                             onKeyPress={keyPress} onClick={toggleMenu}>
                            {props.trigger}
                        </div>
                        :
                        <IconButton onClick={toggleMenu} icon={Icons.ellipsis()}/>
                )
            }
            <div onClick={toggleMenu} style={menuPosition()}
                 className={styles.dropdownWrapper + ' ' + (!isOpen && styles.hideMenu)}>
                {props.children}
            </div>
        </div>
    )
}

ExpandingMenu.propTypes = {
    // trigger is the button or interface that expands the menu
    trigger: PropTypes.any,
    position: PropTypes.oneOf(['DR', 'DL', 'TR', 'TL', 'L']),
    toolTip: PropTypes.object,
    //FAB - Floating action button as menu trigger, true or false
    fab: PropTypes.bool,
    openOnHover: PropTypes.bool
};

export default ExpandingMenu;