import {action, computed, makeAutoObservable} from 'mobx';
import PubSub from "pubsub-js";
import F from "utils/F";

class BinaryStore {
    binaryMap = new Map();
    //
    initialised = false;

    constructor() {
        makeAutoObservable(this);
    }

    initialise = (binaryDataMap) => {
        binaryDataMap && this.setBinaryData(F.mapMap(Object.entries(binaryDataMap), (k, v) => [k, v]));

        PubSub.subscribe('ws.in', (action, message) => {
            if (message.eventType === 'SINGLE_UPDATE') {
                if (message.type === 'binaryEntity') {
                    this.updateSingleBinaryData(message.payload.binaryDataMap);
                }
            }
        });
        this.initialised = true;
    };

    setBinaryData(binaryMap) {
        this.binaryMap = binaryMap;
    }

    //

    @action updateSingleBinaryData(binaryDataMap) {
        this.binaryMap = new Map([...this.binaryMap, ...F.mapMap(Object.entries(binaryDataMap), (k, v) => [k, v])])
    }

    //

    @computed getBinaryData(id) {
        return this.binaryMap.get(id);
    }
}

export const binaryStore = new BinaryStore();