import {observer} from "mobx-react";
import {useNavigation} from "hooks/Navigation";
import React from "react";
import styles from "LandingSite/layout/Resources/Resources.module.scss";
import {websiteConfigStore} from "stores/WebsiteConfigStore";
import {BlogImages} from "images/BlogImages";
import {Helmet} from "react-helmet";
import {getProductUrl} from "utils/Nav";

const Resources = observer(() => {

    const navigation = useNavigation()

    const renderHero = () => {
        return <div className={styles.hero}>
            <div className={styles.heroContent}>
                <div className={styles.heroText}>
                    <h5>Resources</h5>
                    <h1>Grow your business</h1>
                    <h5>Insights, tips and advice to keep you on track and maximise your business's potential</h5>
                </div>
            </div>
        </div>
    }

    const templates = websiteConfigStore.websiteConfig.pageTemplates

    const teaserCard = (t) => {
        const summaryBlock = t.blocks.find(b => b.summary != null)
        const image = BlogImages(t.id)

        return <div className={styles.teaserCard}
                    onClick={() => navigation.goTo('resources/' + getProductUrl(t.id))}>
            <div className={styles.image}>
                <img src={image.src} alt={image.alt}/>
            </div>
            <div className={styles.rightColumn}>
                <h1 className={styles.headline}>{t.title}</h1>
                <p className={styles.summary}>{summaryBlock.summary}</p>
            </div>
        </div>
    }

    return (
        <>
            <Helmet>
                <title>Resources | Acculex</title>
            </Helmet>
            <div>
                {renderHero()}
                <div className={styles.content}>
                    {templates && templates.filter(pT => pT.type === 'RESOURCE').map(t => teaserCard(t))}
                </div>
            </div>
        </>
    )
});

export default Resources;