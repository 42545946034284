import styles from 'LandingSite/layout/Terms/Terms.module.scss';
import React, {useEffect} from 'react';

const Terms = () => {

    useEffect(() => {
            window.scrollTo(0, 0);
        }
    );

    return (
        <div className={styles.terms}>
            <h1>Terms of Use</h1>
            <div>
                <h5>1. DEFINITIONS</h5> Lex Search Ltd, a company incorporated in England and Wales at 1
                Aquarelle House, 259 City Road, EC1V 1AG with registration number 11345154, the "Supplier" of
                services. An authorised user of the platform, the "User". The website and all data accessible
                through the website, the "Service". All information made available through the Service that is
                not in the public domain at the time it is accessed, the "Data".
            </div>
            <div>
                <h5>2. SERVICE</h5> The Supplier grants to the User a non-exclusive, non-transferable licence to use
                the Service in accordance with this Agreement.
            </div>
            <div>
                <h5>3. OWNERSHIP</h5> The User acknowledges that the Services are protected by copyrights,
                trademarks, trade secrets, patents or other proprietary rights, and that these worldwide rights
                are valid and protected in all forms, media and technologies now existing or developed in the
                future. You agree to comply with all intellectual property laws and you shall not directly or
                indirectly assert any rights to the Services. Data uploaded by the User remains the property of
                the User at all times.
            </div>
            <div>
                <h5>4. SERVICE ACCESS</h5> The supplier reserves the right to suspend the Service temporarily and
                without notice for reasons beyond its control. The supplier reserves the right to make
                modifications or improvements to the Service.
            </div>
            <div>
                <h5>5. PERMITTED ACTIVITIES</h5> The User and Licensed Users may create, upload, edit and perform
                any legal relationship management function permitted by the application. While every care has been
                taken to ensure lawful use of the Service, the User is only permitted to use the Service to the
                extend permitted by law in the User's jurisdiction.
            </div>
            <div>
                <h5>6. PROHIBITED ACTIVITIES</h5> Except as permitted by law or by this Agreement, the User shall
                not itself, nor allow any third party to, duplicate or otherwise reproduce the Services or the Data
                or any part of them. Except to the extent permitted by law, the User shall not modify, reverse
                assemble, decompile or reverse engineer the Service or any part thereof or merge it with any
                other software or database or printed publication or create any derivative work from it.
            </div>
            <div>
                <h5>7. LIMITATION OF LIABILITIES</h5> Unless explicitly agreed with the User as part of a subscription,
                the Service is supplied to the User on an "as is" basis and neither the Service nor any part thereof has
                been created
                to meet the individual requirements of the User. It is the sole responsibility of the User to
                satisfy itself prior to entering this Agreement that the Service will meet their requirements. The
                Supplier makes no warranty or representation in that respect and no failure of any part or the
                whole of the Service to be suitable for the User’s requirements shall entitle the User not to
                accept the same or give rise to any right or claim against the Supplier. The Supplier does not
                seek to exclude or limit its liability where it may not do so as a matter of law, whether in
                respect of death or personal injury resulting from negligence or otherwise. The Supplier is not
                liable for any indirect loss, consequential loss, loss of profit, revenue, data or goodwill
                howsoever arising suffered by the User for any wasted management time, failure to make
                anticipated savings or liability of the User to any third party arising in any way in connection
                with this Agreement or otherwise, whether or not such loss has been discussed by the parties
                pre-contract, or for any account for profit, costs or expenses arising from such damage or loss.
            </div>
            <div>
                <h5>8. E-SIGNING</h5>Electronic signatures are legally binding and enforceable in all the countries
                we operate. These include: Australia, all member states of the European Union, New Zealand, Singapore,
                United Kingdom and the United States of America.
            </div>
            <div>
                <h5>9. SEVERABILITY</h5>If any clause in this Agreement is found by any competent court to be
                unenforceable
                or invalid, that clause will be limited to the minimum extent necessary so the rest of the Agreement
                remains in effect.
            </div>
            <div>
                <h5>10. GOVERNING LAW</h5>This Agreement is governed by, and is to be construed in accordance with,
                English law. The English Courts will have non-exclusive jurisdiction to deal with any dispute which has
                arisen or may arise out of, or in connection with, this Agreement.
            </div>
            <br/>
            <i>Last updated: 10th of May 2021 14:00:00</i>
        </div>
    );
}

export default Terms;
