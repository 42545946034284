import {ObservableMap} from "mobx";
import {userStore} from 'stores';

export default class F {
    static numberFormatter = new Intl.NumberFormat('en-UK', {style: 'decimal'});

    /**
     * Formats a number in the standard way.
     * @param n input number
     * @returns {*} formatted number
     */
    static formatNumber(n) {
        return F.numberFormatter.format(n);
    }

    /**
     * Generates a random string of arbitrary length
     * @param {int} length of the string to be generated
     * @returns {string} Generated string
     */
    static generateID(length) {
        const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let text = "";
        for (let i = 0; i < length; i++) {
            text += possible.charAt(Math.floor(Math.random() * length) % (possible.length));
        }
        return text;
    }

    /**
     * Creates a map, with id as key and the objects as value
     * @param {array} objectsArray Array of objects to be converted to a map
     * @returns {map} Map
     */
    static mapArrayById = (objectsArray) => {
        return F.mapArrayByParam(objectsArray, o => o.id);
    };

    /**
     * Creates a map, with the first param as key and the object as value
     * @param {array} objectsArray Array of objects to be converted to a map
     * @param keyGetter function to generate the key
     * @param valueGetter function to generate the value (default is the entire object)
     * @returns {map} Map
     */
    static mapArrayByParam = (objectsArray, keyGetter, valueGetter = o => o) => {
        return new Map(objectsArray.map(data => [keyGetter(data), valueGetter(data)]))
    };

    /**
     * Creates a map, with the first param as key and the object as value
     * @param {array} objectsArray Array of objects to be converted to a map
     * @param level1Getter function to generate the key for the 1st level map
     * @param level2Getter function to generate the key for the 2nd level map
     * @param level3Getter function to generate the key for the 3rd level map
     * @returns {map} Map
     */
    static mapArrayGroupBy = (objectsArray, level1Getter, level2Getter = o => o.id, level3Getter = null) => {
        const level1Map = new Map();
        objectsArray.forEach((item) => {
            const level1Key = level1Getter(item);
            let level2Map;
            if (!level1Map.has(level1Key)) {
                level2Map = new Map();
                level1Map.set(level1Key, level2Map);
            } else {
                level2Map = level1Map.get(level1Key);
            }
            const level2Key = level2Getter(item);
            if (level3Getter) {
                let level3Map;
                if (!level2Map.has(level2Key)) {
                    level3Map = new Map();
                    level2Map.set(level2Key, level3Map);
                } else {
                    level3Map = level2Map.get(level2Key);
                }
                const level3Key = level3Getter(item);
                level3Map.set(level3Key, item);
            } else {
                level2Map.set(level2Key, item);
            }
        });
        return level1Map;
    };

    /**
     * Gets an array of all the items in a map. Disregards the keys in the map.
     * @param map map
     * @param options includes
     * userStore
     * excludeArchived exclude archived items
     * userFilter whether to filter only objects belonging to the current user / company
     * @returns {unknown[]|Array} array
     */
    static arrayFromMapValues = (map, options = {}) => {
        if (!map) {
            return [];
        }
        let array = Array.from(map.values());
        if (options.userFilter) {
            array = array.filter(F.createAccountFilter());
        }
        if (options.excludeArchived) {
            array = array.filter(i => !i.archived);
        }
        if (options.sort) {
            array = array.sort(options.sort)
        }
        return array;
    };

    // use getter functions for cases where the target is not the default

    static mapByParam = (map, functions = {}, options = {}) => {
        const keyGetter = functions.keyGetter || (o => o.id); // default o.id
        const valueGetter = functions.valueGetter || (o => o.name); // default o.name
        return F.mapArrayByParam(F.arrayFromMapValues(map, options), keyGetter, valueGetter);
    };

    static mapMap = (map, f, filter = null) => {
        if (!map) {
            throw new Error("Map is null");
        }
        let array = Array.from(map);
        if (filter) {
            array = array.filter(([k, v]) => filter(k, v));
        }
        array = array.map(([k, v]) => f(k, v));
        return new Map(array);
    };

    static createAccountFilter = () => {
        const currentAccount = F.getCurrentAccount();
        return (item) => {
            if (!item) {
                return false;
            }
            return F.objectEquals(item.owner, currentAccount) ||
                (item.parties && (item.parties.some(np => F.objectEquals(np.partyWithVersion.party, currentAccount) ||
                    np.contactsTasks.some(ct => F.objectEquals(ct.partyContact.party, currentAccount)))));
        };
    };

    static getCurrentAccount = () => {
        if (userStore.selectedCompanyId) {
            return {
                partyId: userStore.selectedCompanyId,
                type: 'COMPANY'
            };
        }
        if (userStore.authUser) {
            return userStore.authUser.party;
        }
        return {};
    };

    static sortByQuery = (field, query) => {
        return (a, b) => {
            const fieldA = a[field].replace('_', '-').toLowerCase()
            const fieldB = b[field].replace('_', '-').toLowerCase()
            if (fieldA === query && fieldB !== query) {
                return -1;
            }
            if (fieldA !== query && fieldB === query) {
                return 1;
            }
            return 0
        }
    }

    static sortAtoZ = (field = 'name') => {
        return (a, b) => {
            if (a[field].toLowerCase() < b[field].toLowerCase())
                return -1;
            if (a[field].toLowerCase() > b[field].toLowerCase())
                return 1;
            return 0;
        }
    };

    static sortZtoA = (field = 'name') => {
        return (a, b) => {
            if (a[field].toLowerCase() < b[field].toLowerCase())
                return 1;
            if (a[field].toLowerCase() > b[field].toLowerCase())
                return -1;
            return 0;
        }
    };

    static sortByTime = (time) => {
        return (a, b) => {
            if (a[time] < b[time]) {
                return 1
            }
            if (a[time] > b[time]) {
                return -1
            }
            return 0
        }
    }

    static addListener = (func, event = 'click', delay = false) => {
        if (delay) {
            setTimeout(() => document.addEventListener(event, func), 10)
        } else {
            document.addEventListener(event, func)
        }
    };

    static removeListener = (func, event = 'click') => {
        setTimeout(() => document.removeEventListener(event, func), 10)
    };

    /**
     * Returns the deeply-nested value of field, or if this does not exist, it returns the alternative value.
     * @param object an object or a map
     * @param field deeply-nested field. Example: party.name
     * @param alternative value to return if the field does not exist
     * @returns {*} value
     */
    static getOr = (object, field, alternative = null) => {
        if (object == null) {
            return alternative;
        }
        if (object instanceof Map || object instanceof ObservableMap) {
            return F.getOrElse(object, field, alternative);
        } else {
            return F._getOrFromObject(object, field, alternative);
        }
    };

    /**
     * Copies an object deeply.
     * @param object object to copy
     * @returns {any} deep copy
     */
    static copy = (object) => {
        if (!object) {
            throw new Error("Object to copy is null");
        }
        return JSON.parse(JSON.stringify(object));
    };

    /**
     * Returns the deeply-nested value of a component's state, or if this does not exist, it returns the alternative value.
     * @param c the component (usually it is 'this')
     * @param field deeply-nested field. Example: party.name
     * @param alternative value to return if the field does not exist
     * @returns {*} value
     */
    static getStateOr = (c, field, alternative = null) => {
        return F.getOr(c.state, field, alternative);
    };

    /**
     * Sets a deeply-nested value in a state.
     * @param c the component (usually it is 'this')
     * @param field deeply-nested field to set
     * @param value value to set
     */
    static setState = (c, field, value) => {
        const fields = field.split('.');
        let objectRef = c.state;
        F.setValue(objectRef, field, value);
        c.setState({
            [fields[0]]: c.state[fields[0]]
        });
    };

    static setValue = (objectRef, field, value) => {
        const fields = field.split('.');
        for (let i = 0; i < fields.length - 1; i++) {
            const nestedField = fields[i];
            if (!objectRef.hasOwnProperty(nestedField) || objectRef[nestedField] == null) {
                objectRef[nestedField] = {}
            }
            objectRef = objectRef[nestedField];
        }
        objectRef[fields[fields.length - 1]] = value;
    };

    static getOrElse = (map, field, alternative = null) => {
        if (map.has(field)) {
            return map.get(field);
        }
        return alternative;
    };

    static _getOrFromObject = (object, field, alternative = null) => {
        const fields = field.split('.');
        let objectRef = object;
        for (const nestedFieldIdx in fields) {
            const nestedField = fields[nestedFieldIdx];
            if (objectRef == null || !objectRef.hasOwnProperty(nestedField)) {
                return alternative;
            }
            objectRef = objectRef[nestedField];
        }
        if (objectRef == null) {
            return alternative;
        }
        return objectRef;
    };

    static addShadow = (component, offset) => {
        component && window.addEventListener('scroll', () => {
                if (window.pageYOffset > offset) {
                    component.classList.add("add-shadow");
                } else {
                    component.classList.remove("add-shadow");
                }
            }
        )
    }

    static sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    };

    static returnWithDelay = (result, milliseconds = 500) => {
        return new Promise((res) => setTimeout(() => res(result), milliseconds))
    };

    static returnErrorWithDelay = (statusCode, message, milliseconds = 500) => {
        return new Promise((res, rej) => setTimeout(
            () => rej({response: {status: statusCode, data: {message}}}),
            milliseconds))
    };

    static arrayEquals = (a1, a2) => {
        return a1 && a2 && a1.length === a2.length && a1.every((item, idx) => item === a2[idx])
    }

    static objectEquals = (o1, o2) => {
        if (o1 == null || o2 == null) {
            return o1 === o2;
        }
        if (Array.isArray(o1)) {
            return JSON.stringify(o1) === JSON.stringify(o2);
        }
        return F.stringify(o1) === F.stringify(o2);
    }

    static stringify = (o) => {
        return JSON.stringify(o, Object.keys(o).sort());
    }
}