import React, {useEffect, useState} from 'react'
import styles from 'components/FormInputs/InputField.module.scss'
import {Icons} from "utils/Icons";
import PropTypes from "prop-types";

const InputField = ({
                        validator,
                        inputValue,
                        inputClass,
                        inputPlaceholder,
                        inputTitle,
                        onChange,
                        id,
                        type,
                        step,
                        autoComplete,
                        onBlur,
                        isTextarea,
                        onKeyPress,
                        onWheelCapture
                    }) => {
    const [errors, setErrors] = useState([]);
    const [inputType, setInputType] = useState(type || 'text')

    useEffect(() => {
        if (validator) {
            if (errors.length > 0) {
                catchError()
            }
        }
    })

    const labelTransform = () => {
        if (inputValue !== '') {
            return styles.floatLabel
        } else return ''
    }

    const styledClass = `${styles.input} ${inputClass && inputClass} ${errors.length > 0 && styles.error}`

    const catchError = () => {
        const currentErrors = validator(inputValue, inputTitle.toUpperCase());
        if (currentErrors && errors && currentErrors.length === errors.length) {
        } else {
            setErrors(currentErrors)
        }
    }

    const updateInput = (e) => {
        validator && catchError();
        onChange(e)
    }

    const getId = () => {
        return inputTitle.toLowerCase().replace(' ', '-') + (id ? id : '');
    }

    const toggleShowPassword = () => {
        inputType === 'text' ? setInputType('password') : setInputType('text')
    }

    const renderShowPasswordIcon = () => {
        if (type === 'password') {
            if (inputType === 'password') return Icons.view(styles.showPassword, toggleShowPassword)
            return Icons.hide(styles.showPassword, toggleShowPassword)
        }
    }

    const passwordInputStyle = () => {
        if (type === 'password') return {
            paddingRight: 36
        }
    }

    return (
        <label className={styles.inputContainer}>
            {isTextarea ? <textarea onChange={updateInput}
                                    id={getId()}
                                    value={inputValue}
                                    autoComplete={autoComplete ? autoComplete : "on"}
                                    placeholder={inputPlaceholder}
                                    onKeyPress={onKeyPress}
                                    className={`${styles.input} ${styledClass}`}

                /> :
                <input type={inputType}
                       onWheelCapture={onWheelCapture}
                       style={passwordInputStyle()}
                       onChange={updateInput}
                       name={inputTitle}
                       id={getId()}
                       onBlur={() => {
                           validator && catchError();
                           onBlur()
                       }}
                       value={inputValue}
                       className={styledClass}
                       placeholder={inputPlaceholder}
                       autoComplete={autoComplete ? autoComplete : "on"}
                       onKeyPress={onKeyPress}
                       step={step}
                />}
            <div className={`${styles.inputTitle} ${labelTransform()}`}>
                {inputTitle && inputTitle}
            </div>
            {renderShowPasswordIcon()}
            {errors.length > 0 && <span className={styles.errorMessage}>{errors.join(' ')}</span>}
        </label>
    )
}

InputField.propTypes = {
    inputTitle: PropTypes.string.isRequired
}

export default InputField