export class StringFunctions {

    static truncate(string, maxLength) {
        if (string.length > 20)
            return string.substring(0, maxLength || 15) + '...' + string.substring(string.length - 7, string.length);
        else
            return string;
    };

    static shorten(string, oldLength, newLength) {
        if (string.length > oldLength)
            return string.substring(0, newLength) + '...';
        else
            return string;
    };

    static prettyEnum(enumString) {
        const strings = enumString.split("_");
        return strings.map(s => (s[0].toUpperCase() + s.substring(1).toLowerCase())).join(" ");
    }
    static getCategory = (cat) => {
        switch (cat) {
            case 'IP' : return 'Intellectual Property (IP)';
            case 'HR' : return 'Human Resources (HR)';
            case 'BA' : return 'Business Administration';
            case 'FUND' : return 'Funding';
            case 'SALES' : return 'Sales';
            case 'PROPERTY' : return 'Property';

            default : return ''
        }
    }
}