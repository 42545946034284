import {action, computed, makeAutoObservable} from 'mobx';
import PubSub from "pubsub-js";
import StoreUtils from "utils/StoreUtils";
import F from "utils/F";
import {DateFunctions} from "utils/DateFunctions";

class CommentsStore {
    commentsMap = new Map();
    //
    initialised = false;

    constructor() {
        makeAutoObservable(this);
    }

    initialise = (commentsMap) => {

        if (commentsMap) {
            this.setComments(StoreUtils.mapArrayGroupByVersion(commentsMap));
        }
        PubSub.subscribe('ws.in', (action, message) => {
            if (message.eventType === 'SINGLE_UPDATE') {
                if (message.type === 'comment') {
                    const comment = message.payload;
                    this.updateSingleComment(comment);
                }
            }
        });

        this.initialised = true;
    };


    @action setComments(map) {
        this.commentsMap = map;
    }

    //

    @action updateSingleComment(comment) {
        StoreUtils.updateSingle(this.commentsMap, comment);
    }

    //

    @computed get commentsMapLV() {
        return StoreUtils.getLatestVersions(this.commentsMap);
    }

    @computed getComments(nodeId, status = null, clausePath = null, parentId = null) {
        const lvArray = F.arrayFromMapValues(this.commentsMapLV);
        return lvArray
            .filter(c => c.nodeId === nodeId &&
                c.parentId == null &&
                (!status || c.status === status) &&
                (!clausePath || F.arrayEquals(c.clausePath, clausePath)) &&
                (!parentId || c.id === parentId))
            .map(chiefComment => ({
                chiefComment,
                replies: lvArray
                    .filter(r => r.parentId === chiefComment.id)
                    .sort((a, b) =>
                        DateFunctions.isoStringToJsDate(a.updatedTime) - DateFunctions.isoStringToJsDate(b.updatedTime))
            }))
    }

    @computed getActiveCommentThread(nodeId, clausePath) {
        const comments = this.getComments(nodeId, 'ACTIVE', clausePath);
        return comments.length > 0 ? comments[0] : null;
    }

    @computed getCommentThreadByParentId(nodeId, parentId) {
        const comments = this.getComments(nodeId, null, null, parentId);
        return comments.length > 0 ? comments[0] : null;
    }
}

export const commentsStore = new CommentsStore();