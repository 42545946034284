import styles from "LandingSite/Hero.module.scss";
import {userStore, viewportStore} from "stores";
import React from "react";
import {observer} from "mobx-react";
import RegisterForm from "components/RegisterForm/RegisterForm";

const Hero = observer(({heading, subHeading, imgSrc, imgAlt, showForm = false, style, children}) => {

    return <div style={style} className={styles.homeHero}>
        <div className={styles.heroContent}>
            {!userStore.isUser() && viewportStore.isWide && showForm &&
                <div style={{marginBottom: -40}} className="login-container">
                    <div className={'login-form-wrapper'}>
                        <RegisterForm hideSignIn heading={"Start creating contracts with a FREE account"}/>
                    </div>
                </div>
            }
            {(userStore.isUser() || viewportStore.isTablet || !showForm) && <div className={styles.heroImg}>
                <img src={imgSrc} alt={imgAlt}/>
            </div>
            }
            <div className={styles.heroText}>
                <h1>{heading}</h1>
                <h5 style={{flexBasis: '100%'}}>{subHeading}</h5>
                <br/>
                {children}
                {!userStore.isUser() && !viewportStore.isTablet && showForm &&
                    <div style={{flex: '1 1 40%'}} className={styles.heroImg}>
                        <img style={{maxWidth: 240}} src={imgSrc} alt={imgAlt}/>
                    </div>}
            </div>
        </div>
    </div>

})

export default Hero