import 'react-toastify/dist/ReactToastify.css';
import React from "react";
import "service/Notifications.scss";
import {ToastContainer, toast, Slide} from 'react-toastify';
import {Icons} from "../utils/Icons";

export default class Notifications {
    static info = (message, title = "", retry = 3000) => {
        const options = {
            autoClose: retry,
            toastId: message
        };
        if (title !== "") {
            message = title + ": " + message;
        }
        toast.dismiss();
        toast.info(message, options);
    };

    static success = (message, title = "", retry = 3000) => {
        const options = {
            autoClose: retry,
            toastId: message
        };
        if (title !== "") {
            message = title + ": " + message;
        }
        toast.dismiss();
        toast.success(message, options);
    };

    static error = (message, title = "", retry = 8000) => {
        const options = {
            autoClose: retry,
            toastId: message
        };
        if (title !== "") {
            message = title + ": " + message;
        }
        toast.dismiss();
        toast.error(message, options);
    };

    static warn = (message, title = "", retry = 3000) => {
        const options = {
            autoClose: retry,
            toastId: message
        };
        if (title !== "") {
            message = title + ": " + message;
        }
        toast.dismiss();
        toast.warn(message, options);
    };
}

// this is required to avoid a Warning/Error
const CloseButton = () => (
    Icons.cross('closeToast')
);

export const NotificationsContainer = () => {
    return <ToastContainer closeButton={CloseButton}
                           hideProgressBar
                           pauseOnHover
                           limit={3}
                           pauseOnFocusLoss={false}
                           transition={Slide}
                           draggable={false}
                           position="top-center"/>;
};
