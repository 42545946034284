import styles from 'LandingSite/layout/Terms/Terms.module.scss';
import React, {Component} from 'react';

class Privacy extends Component {

    componentDidMount() {
        window.scrollTo(0,0)
    }

    render() {
        return (
            <div className={styles.terms}>
                <h1>Privacy Policy</h1>
                <div>
                    <h3>Information we collect</h3>
                    <h5>Directly from you</h5>
                    <ul>
                        <li>Personal details: name, firm, e-mail address and other contact details - supplied at registration</li>
                        <li>Feedback you provide</li>
                        <li>Contact preferences</li>
                    </ul>
                    <h5>Indirectly, through the use of cookies</h5>
                    <ul>
                        <li>Service delivery - we monitor usage for suspicious activity and fraud detection.
                        </li>
                        <li>Anonymised usage statistics - we use cookies to better understand what our clients need,
                            identify usability issues and improve the quality of our service.
                        </li>
                    </ul>
                    <h5>Information we share with third parties</h5>
                    <ul>
                        <li>Name, email and address for billing purposes, with our payment processor:&nbsp;
                            <a target="_blank" rel='noopener noreferrer' href="https://stripe.com/gb">Stripe</a></li>
                        <li>Your privacy is important to us. We do not share any other data with third parties
                            and we do not use any third party cookies except Stripe's for preventing fraud.
                        </li>
                    </ul>
                    <h5>How long we retain data</h5>
                    <ul>
                        <li>Payment records - for regulatory reasons, we are required to retain them for 7 years</li>
                        <li>Feedback and customer communications - 7 years</li>
                        <li>Business intelligence - anonymised, 24 months</li>
                    </ul>
                    <h5>How we keep your information secure</h5>
                    <ul>
                        <li>We use 4096-bits encryption throughout the website</li>
                        <li>All data is stored securely on our servers in the UK</li>
                        <li>We do not share any of your data with third parties except for processing payments</li>
                    </ul>
                    <h5>Your rights</h5>
                    <ul>
                        <li>Access the personal data that we process about you</li>
                        <li>Request the deletion of your personal data</li>
                        <li>Right to unsubscribe from our marketing and promotional campaigns</li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default Privacy;
