import F from "utils/F";

export default class StoreUtils {
    /**
     * Creates a map, with the first param as key and the object as value
     * @param {array} objectsArray Array of objects to be converted to a map
     * @param l1KeyGetter function to get the 1st level key
     * @param l2KeyGetter function to get the 2nd level key
     * @param l3KeyGetter function to get the 3rd level key
     * @returns {map} Map of maps
     */
    static mapArrayGroupByVersion = (objectsArray, l1KeyGetter = o => o.id, l2KeyGetter = o => o.version, l3KeyGetter = null) => {
        return F.mapArrayGroupBy(objectsArray, l1KeyGetter, l2KeyGetter, l3KeyGetter);
    };

    static getLatestVersions = (map) => {
        const lvMap = new Map();
        map.forEach((v, k) => {
            const latestVersion = Math.max(...v.keys());
            lvMap.set(k, v.get(latestVersion));
        });
        return lvMap;
    };

    static getVersion = (map, version = null) => {
        if (!map) {
            return null;
        }
        if (version == null) {
            version = Math.max(...map.keys());
        }
        return map.get(version);
    };

    static getLastValueFromMap = (map) => {
        return Array.from(map.values())[map.size - 1];
    };

    static updateSingle = (map, item, l1KeyGetter = i => i.id, l2KeyGetter = null) => {
        const l1Key = l1KeyGetter(item);
        if (!map.has(l1Key)) {
            const l1Map = new Map();
            map.set(l1Key, l1Map);
        }
        let innerMap = map.get(l1Key);
        if (l2KeyGetter) {
            const l2Key = l2KeyGetter(item);
            if (!innerMap.has(l2Key)) {
                const l2Map = new Map();
                innerMap.set(l2Key, l2Map);
            }
            innerMap = innerMap.get(l2Key);
        }
        innerMap.set(item.version, item);
    }

    static setContactName = (c) => {
        if (c == null || typeof c !== 'object') {
            return;
        }
        let fullName = '';
        if (c.firstName != null) {
            fullName += c.firstName;
        }
        if (c.lastName != null) {
            fullName += ' ';
            fullName += c.lastName;
        }
        c.name = fullName;
        c.initials = StoreUtils.initials(c);
    }

    static initials(c) {
        let initials = "";
        if (c == null) {
            return initials;
        }
        if (c.firstName != null && c.firstName.trim() !== '') {
            initials += c.firstName[0];
        }
        if (c.lastName != null && c.lastName.trim() !== '') {
            initials += c.lastName[0];
        }
        if (initials === '') {
            if (c.name != null) {
                initials = c.name[0]
            }
        }
        return initials;
    }
}