export default class Validators {

    static validateEmail = (value) => {
        if (value === '' || !/^[a-zA-Z0-9.\-_]+@[a-zA-Z0-9.\-_]+\.[A-Za-z]+$/.test(value)) {
            return ['Please enter a valid e-mail address'];
        }
        return [];
    }

    static validatePassword = (value) => {
        const errors = [];
        if (value === '' || value.length < 8) {
            errors.push('Password should contain at least 8 characters');
        }
        if (!/[A-Z]/.test(value)) {
            errors.push('Password should include an UPPERCASE letter');
        }
        if (!/[a-z]/.test(value)) {
            errors.push('Password should include a lowercase letter');
        }
        if (!/[!@#$%^&*0-9]/.test(value)) {
            errors.push('Password should include a number or symbol');
        }
        return errors;
    }

    static validateMinLength = (value, type = null, minLength = 1,) => {
        if (value.length < 1) {
            switch (type) {
                case 'USER_FIRST_NAME' :
                    return ['Please enter your first name'];
                case 'USER_LAST_NAME' :
                    return ['Please enter your last name'];
                case 'PARTY_FIRST_NAME' :
                    return ['Please enter a first name'];
                case 'PARTY_LAST_NAME' :
                    return ['Please enter a last name'];
                case 'COMPANY_NAME' :
                    return ['Please enter the name of the company'];
                case 'COMPANY_NUMBER' :
                    return ['Please enter the company\'s registration number'];
                default :
                    return ['This field is required']
            }
        }
        return [];
    }

    static validateNumber = (value, type = null, minLength = 1) => {
        if (/[^\d+$]/.test(value)) {
            return ['Please only use numbers']
        }
        return []
    }
}