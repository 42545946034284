import DataWebService from "service/web/DataWebService";
import {binaryStore} from "stores/BinaryStore";
import {commentsStore} from "stores/CommentsStore";
import {contractStore} from "stores/ContractStore";
import {jurisdictionsStore} from "stores/JurisdictionsStore";
import {modalsStore} from "stores/ModalsStore";
import {partyStore} from "stores/PartyStore";
import {userStore} from "stores/UserStore";
import {viewportStore} from "stores/ViewportStore";
import {workflowStore} from "stores/WorkflowStore";
import {websiteConfigStore} from "stores/WebsiteConfigStore";

const initialiseStores = async () => {
    const data = await DataWebService.getDataAsync();
    commentsStore.initialise(data.comments)
    jurisdictionsStore.initialise(data.jurisdictions, data.countries);
    partyStore.initialise(data.appUsers, data.contacts, data.companies);
    userStore.initialise(data.authUser);
    workflowStore.initialise(data.workflowTemplates, data.workflows, data.nodes);
    websiteConfigStore.initialise(data.websiteConfigurationDTO);

    const binaryData = await DataWebService.getBinaryDataAsync();
    binaryStore.initialise(binaryData.binaryDataMap);
};

export {
    initialiseStores, binaryStore, contractStore, jurisdictionsStore, modalsStore, partyStore, userStore, viewportStore,
    workflowStore, commentsStore
}