import styles from "LandingSite/layout/ReportError/ReportError.module.scss"
import React from 'react'
import {observer} from "mobx-react";
import ContactForm from "LandingSite/layout/Contact/ContactForm";
import Fix from "images/Illustrations/Fix.svg"
import {useParams} from "react-router-dom";

const ReportError = observer(() => {
    const params = useParams();
    const email = params.email;

    return (
        <div className={styles.errorContents}>
            <div className={styles.errorHero}>
                <div className={styles.errorContent}>
                    <div className={styles.errorImg}>
                        <img src={Fix} loading={'lazy'}
                             alt={'Man with a hammer trying to fix a computer'}/>
                    </div>
                    <div className={styles.errorText}>
                        <h1>Sorry about that, we've taken action to fix this.</h1>
                        <h5>We’ll take a look at what's gone wrong. Please use the form below to provide additional details.</h5>
                        <div style={{maxWidth: 600}}>
                            <ContactForm userEmail={email}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default ReportError;

