import {action, makeAutoObservable} from 'mobx';
import PubSub from "pubsub-js";

class JurisdictionsStore {
    jurisdictions = [];
    countries = [];
    //
    initialised = false;

    constructor() {
        makeAutoObservable(this);
    }

    initialise = (jurisdictions, countries) => {
        if (jurisdictions) {
            this.setJurisdictions(jurisdictions);
        }
        if (countries) {
            this.setCountries(countries);
        }

        PubSub.subscribe('ws.in', (action, message) => {
            if (message.eventType === 'SINGLE_UPDATE') {
                if (message.type === 'jurisdiction') {
                    //this.updateSingleCompany(message.payload);
                }
            }
        });

        this.initialised = true;
    }

    @action setJurisdictions(jurisdictions) {
        this.jurisdictions = jurisdictions;
    }

    @action setCountries(countries) {
        this.countries = countries;
    }

    getCountry(code) {
        return this.countries.filter(c => c.code === code)[0];
    }

    getCounty(countryCode, countyCode) {
        const country = this.getCountry(countryCode);
        return country && country.counties && country.counties.filter(c => c.code === countyCode)[0];
    }

    getCountryMap() {
        return new Map(this.countries.map(c => [c.code, c.name]));
    }

    getCountyMap(countryCode) {
        const map = new Map();
        const country = this.getCountry(countryCode);
        country && country.counties && country.counties.forEach(c => map.set(c.code, c.name));
        return map;
    }

    getCompanyTypes(countryCode) {
        const map = new Map();
        this.jurisdictions
            .filter(j => j.code.startsWith(countryCode))
            .forEach(j => {
                Object.entries(j.entityTypes).forEach(ct => {
                    if (!map.has(ct[0])) {
                        if (ct[1].suffix != null) {
                            map.set(ct[0], `${ct[1].name} (${ct[1].suffix})`)
                        } else {
                            map.set(ct[0], `${ct[1].name}`)
                        }
                    }
                });
            });
        return map;
    }

    getOfficerRoles(countryCode, includeCorporate = false) {
        const map = new Map();
        this.jurisdictions
            .filter(j => j.countryCode === countryCode)
            .forEach(j => {
                Object.entries(j.entityRoles).forEach(co => {
                    if (!map.has(co[0]) && (includeCorporate || !co[0].includes('CORPORATE'))) {
                        map.set(co[0], co[1].name)
                    }
                });
            });
        return map;
    }
}

export const jurisdictionsStore = new JurisdictionsStore();
