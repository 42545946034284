import React, {useState} from "react";
import Notifications from "service/Notifications";
import {CorrespondenceWebService} from "service/web/CorrespondenceWebService";
import InputField from "components/FormInputs/InputField";
import styles from "LandingSite/layout/Contact/ContactUs.module.scss";
import ButtonGroup from "components/ButtonGroup/ButtonGroup";
import Button from "components/Button/Buttons";

const ContactForm = ({referral, onFinish, isUser=false, showPrivacy = true}) => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')

    const sendMessage = async () => {
        if (!isUser && (email === '' || message === '')) {
            Notifications.error('Please fill in both fields', 'Contact');
            return;
        }
        await CorrespondenceWebService.sendMessage({
            name: name,
            email: email,
            message: (referral ? referral : '{New contact}') + message
        });
        onFinish && onFinish()
        Notifications.info('Thanks, your message has been sent. We\'ll get back to you as soon as possible', '');
        setName('')
        setEmail('')
        setMessage('')
    };

    return (
        <>
            {!isUser &&
                <>
                    <InputField
                        onBlur={() => {
                        }}
                        class={styles.contactInput}
                        inputTitle={'Name'} inputId={'name'} inputPlaceholder={''}
                        inputValue={name} onChange={(e) => setName(e.target.value)}/>
                    <InputField
                        onBlur={() => {
                        }}
                        class={styles.contactInput} inputTitle={'Email Address'}
                        inputPlaceholder={''}
                        onChange={(e) => setEmail(e.target.value)} inputValue={email}/>
                </>
            }
            {showPrivacy &&
                <p className={styles.info}>We'll never share your details with anyone else without prior consent</p>}
            <InputField isTextarea onBlur={() => {
            }} class={styles.contactInput} textarea={true} onChange={(e) => setMessage(e.target.value)}
                        inputTitle={'Message'} inputValue={message}/>
            <ButtonGroup>
                <Button buttonId={'contact-submit'} buttonClass={styles.contactButton} onClick={sendMessage}
                        title={'Submit'}/>
            </ButtonGroup>
        </>
    );
}

export default ContactForm
