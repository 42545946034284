import styles from 'LandingSite/layout/Home/Home.module.scss'
import {Helmet} from "react-helmet"
import Button from "components/Button/Buttons";
import heroI from "images/home/contract_exchange.svg"
import {Icons} from "utils/Icons";
import createImg from "images/home/workflow_creation.png"
import commentImg from "images/home/comments_editing.png"
import signingImg from "images/home/signing.png"
import workflowIcons from "images/home/workflow.svg"
import {useNavigation} from "hooks/Navigation";
import IconListItem from "components/IconListItem/IconListItem";
import React from "react";
import {userStore} from "stores";
import {observer} from "mobx-react";
import Hero from "LandingSite/Hero";

const Home = observer(() => {

    const navigation = useNavigation();

    const renderHero = () => {
        return (
            <Hero heading={"Manage your legal relationships."}
                  subHeading={"Create, execute and manage contracts tailored to your needs."}
                  imgSrc={heroI}
                  showForm
                  imgAlt={'legal-agreement-illustration'}
            >
                <div className={styles.checkList}>
                    <IconListItem icon={Icons.check()} text={"Custom contracts based on your requirements"}/>
                    <IconListItem icon={Icons.check()} text={"Integrated E-Signatures"}/>
                    <IconListItem icon={Icons.check()} text={"Free 1 Year Trial"}/>
                    <br/>
                    <Button buttonId={'home-hero-create-account'} class={styles.homeButton}
                            onClick={() => {
                                !userStore.isUser() ? navigation.goTo('/register') : navigation.goToUserPath('dashboard')
                            }}
                            title={!userStore.isUser() ? 'Get started' : 'Go to my account'}/>
                </div>
            </Hero>
        );
    };

    const renderSummaryCard = (card) => {
        return (
            <div className={styles.summaryCard}>
                <div className={styles.summaryIcon}>{card.icon}</div>
                <h3 className={styles.summaryTitle}>{card.title}</h3>
                <span className={styles.summaryContent}>{card.content}</span>
            </div>
        )
    }

    const renderSummaries = () => {
        const create = {
            icon: Icons.plus(styles.createIcon),
            title: 'Create',
            content: 'Our workflow tool allows you to create and maintain  complex contracts, all with an easy step by step guide'
        }
        const communicate = {
            icon: Icons.comments(styles.commentIcon),
            title: 'Collaborate',
            content: 'Comment, share and discuss key points with internal and external parties and their representatives'
        }
        const sign = {
            icon: Icons.sign(styles.signIcon),
            title: 'Sign',
            content: 'Integrated e-signing means your document stays safe within our system'
        }
        const manage = {
            icon: Icons.contractStack(styles.contractIcon),
            title: 'Manage',
            content: 'Our contract storage solution allows you to manage all your documents from one easy to use dashboard'
        }
        return <div className={styles.summaries}>
            {renderSummaryCard(create)}
            {renderSummaryCard(communicate)}
            {renderSummaryCard(sign)}
            {renderSummaryCard(manage)}
        </div>
    }

    const renderCreation = (feature) => {
        return (
            <div className={styles.section}>
                <div className={styles.sectionImageContainer}>
                    <img className={styles.sectionImage} src={feature.image}
                         alt='screennshot of the documents view page' loading="lazy"/>
                </div>
                <div style={{display: 'flex', flexFlow: 'row', flex: '0 1 40%'}}>
                    <div style={{flex: '1 1 auto', paddingRight: 40}} className={styles.textSection}>
                        <h1>{feature.title}</h1>
                        <p>{feature.content}</p>
                    </div>
                    <img style={{width: '15%'}} src={workflowIcons}
                         alt='illustration depicting connected workflow stages' loading="lazy"/>
                </div>
            </div>
        )
    }

    const renderComment = (feature) => {
        return (
            <div className={styles.commentSection}>
                <div className={styles.sectionImageContainer}>
                    <img className={styles.sectionImage} src={feature.image}
                         alt='illustration depicting the chat and edit functionality' loading="lazy"/>
                </div>
                <div className={styles.textSection}>
                    <h1>{feature.title}</h1>
                    <p style={{color: '#74757B'}}>{feature.content}</p>
                </div>
            </div>
        )
    }

    const renderSigning = (feature) => {
        return (
            <div style={{backgroundColor: '#58595B'}} className={styles.section}>
                <div className={styles.sectionImageContainer}>
                    <img className={styles.sectionImage} src={feature.image}
                         alt='illustration depicting the signing functionality' loading="lazy"/>
                </div>
                <div className={styles.textSection}>
                    <h1 style={{color: '#fbfbfd'}}>{feature.title}</h1>
                    <p style={{color: '#fbfbfd'}}>{feature.content}</p>
                </div>
            </div>
        )
    }

    const renderFeatures = () => {
        const creation = {
            title: 'Workflow Creator',
            content: 'Let us do the heavy lifting. Our workflow generator identifies and manages each stage of your process, keeping your time input to a minimum.',
            image: createImg
        }
        const commenting = {
            title: 'Secure collaboration, Built-in editing ',
            content: 'Share contracts and communicate with parties through our secure chat function with threading. \n' +
                'Integrate with your business’s prefererred messaging system (coming soon) \n' +
                'Need to customise a contract to your specific needs? Our built in editing capability allows for easy addition of clauses and chapters.',
            image: commentImg
        }
        const signing = {
            title: 'Integrated e-signing',
            content: 'Convenient and secure, our integrated digital signature capabilites means no reliance on third party software, and no additional costs.',
            image: signingImg
        }

        return (
            <div className={styles.features}>
                {renderCreation(creation)}
                {renderComment(commenting)}
                {renderSigning(signing)}
            </div>
        )
    };


    return (
        <>
            <Helmet>
                <title>Acculex | Legal Relationships and Contract Management</title>
                {/*<meta name='description' content='Future-proof your business. Create, execute and manage*/}
                {/*        contracts tailored to your needs. '/>*/}
            </Helmet>
            <div className={styles.homeContents}>
                {renderHero()}
                {renderSummaries()}
                {renderFeatures()}
                <div className={styles.signUp}>
                    <h2>Ready to give it a go? Try it now for free</h2>
                    {Icons.arrow(styles.arrow)}
                    <Button style={{marginRight: 20}} buttonId={'home-section-create-account'}
                            title={'Get Started'}
                            onClick={() => navigation.goTo('register')}
                    />
                    <h5>(No card details required)</h5>
                </div>
            </div>
        </>
    );
})

export default Home
