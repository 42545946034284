import {makeAutoObservable} from "mobx";

class ViewportStore {
    isMobile = false;
    isTablet = false;
    isWide = false;

    constructor() {
        makeAutoObservable(this);
    }

    setViewWidth = (width) => {
        width <= 560 ? this.isMobile = true : this.isMobile = false;
        width <= 768 ? this.isTablet = true : this.isTablet = false;
        width >= 1080 ? this.isWide = true : this.isWide = false;
    };

}

export const viewportStore = new ViewportStore();