import styles from "LandingSite/layout/Unsubscribe/Unsubscribe.module.scss"
import React, {useEffect} from 'react'
import Button from "components/Button/Buttons";
import {userStore} from "stores";
import {observer} from "mobx-react";
import {useNavigation} from "hooks/Navigation";
import ButtonGroup from "components/ButtonGroup/ButtonGroup";
import {CorrespondenceWebService} from "service/web/CorrespondenceWebService";
import {useParams} from "react-router-dom";

const Unsubscribe = observer(() => {

    const params = useParams();
    const navigation = useNavigation();
    useEffect(() => {
        CorrespondenceWebService.unsubscribe({
            email: params.email,
            subscriptionType: params.subscriptionType,
            subscriptionCode: params.subscriptionCode
        })
    })

    return (
        <div className={styles.errorContents}>
            <div className={styles.errorHero}>
                <div className={styles.errorContent}>
                    <div className={styles.errorText}>
                        <h1>You’ve successfully unsubscribed</h1>
                        <h5>We’re sad to see you go.</h5>
                        <p>Please note it may take up to 24 hours for these changes to be reflected in your account</p>
                        <ButtonGroup addClass={styles.buttonGroup}>
                            {userStore.isUser() ?
                                <Button style={{marginRight: 16}} buttonId={'home-hero-create-account'}
                                        class={styles.button}
                                        onClick={() => navigation.goToUserPath('dashboard')}
                                        title={'Take me to my dashboard'}/>
                                :
                                <Button buttonId={'home-hero-create-account'} class={styles.button}
                                        onClick={() => navigation.goTo('/')}
                                        title={'Go to the homepage'}/>
                            }
                        </ButtonGroup>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default Unsubscribe;

