import Cookies from "universal-cookie";
import ConfigService from "service/ConfigService";

const cookies = new Cookies();

export default class CookieService {
    static get(name) {
        return cookies.get(name);
    }

    static set = async (name, value, path = '/') => {
        const secure = await ConfigService.getConfigAsync('is_secure');
        let maxAge;
        if (secure) {
            maxAge = 2592000;
        } else {
            maxAge = 604800;
            // cannot set secure cookies on localhost (no https)
        }
        cookies.set(name, value, {path, maxAge, secure});
    }

    static remove(name, path) {
        cookies.remove(name, {path});
    }
}