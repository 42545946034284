import {useNavigation} from "hooks/Navigation";
import React, {useEffect, useRef, useState} from "react";
import ConfigService from "service/ConfigService";
import AuthWebService from "service/web/AuthWebService";
import CreateInputField from "components/FormInputs/CreateInputField";
import Validators from "utils/Validators";
import ReactGA from "react-ga4";
import Button from "components/Button/Buttons";
import {GoogleReCaptcha, GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import {Link} from "react-router-dom";

const RegisterForm = ({referrer, completeRegister, firstName, lastName, email, heading, hideSignIn}) => {
    const navigation = useNavigation();
    const [screen, setScreen] = useState('FORM');
    const [recaptchaKey, setRecaptchaKey] = useState(null)
    const [recaptcha, setRecaptcha] = useState(null)
    const actionRegister = useRef()

    useEffect(() => {
        const readRecaptchaConfig = async () => {
            const recaptchaKey = await ConfigService.getConfigAsync("recaptcha_key");
            setRecaptchaKey(recaptchaKey);
        }
        readRecaptchaConfig()
    })


    const handleRegister = async () => {
        let onboarding = null
        if (referrer) {
            onboarding = {
                subscriptionCode: referrer,
                subscriptionType: 'WORKFLOW'
            }
        }

        const user = {
            firstName: firstNameField.getValue(),
            lastName: lastNameField.getValue(),
            email: emailField.getValue(),
            password: passwordField.getValue(),
            onboarding
        }
        try {
            const result = await AuthWebService.register({
                user,
                recaptcha
            });
            const newScreen = result ? 'SUCCESS' : 'PENDING';
            setScreen(newScreen)
            completeRegister(result)
        } catch (err) {
            //
        }
        return Promise.resolve();
    };

    const fieldOptions = {
        onEnterKey: actionRegister
    };
    const firstNameField = CreateInputField('First Name', {
        ...fieldOptions,
        validator: (value) => Validators.validateMinLength(value, 'USER_FIRST_NAME'),
        initialValue: firstName
    });
    const lastNameField = CreateInputField('Last Name', {
        ...fieldOptions,
        validator: (value) => Validators.validateMinLength(value, 'USER_LAST_NAME'),
        initialValue: lastName
    });
    const emailField = CreateInputField('Email', {
        ...fieldOptions,
        initialValue: email,
        type: 'email',
        validator: Validators.validateEmail,
        autoComplete: 'off'
    });
    const passwordField = CreateInputField('Password', {
        ...fieldOptions,
        type: 'password',
        validator: Validators.validatePassword,
        autoComplete: 'new-password'
    });

    const tracker = () => {
        ReactGA.gtag('event', 'conversion', {'send_to': 'AW-831508926/zOwiCKOUsLsDEL6jv4wD'});
        return <img height={"1"} width={"1"} style={{display: 'none'}} alt={""}
                    src={"https://px.ads.linkedin.com/collect/?pid=3192332&conversionId=7699900&fmt=gif"}/>
    }

    const renderSuccess = () => {
        return <>
            {screen === 'SUCCESS' &&
                <>
                    <h4 className='acc-heading'>Please check you email</h4>
                    <p style={{marginBottom: '24px', fontSize: 16}}>Thanks for signing up. We've sent you an email with
                        a verification
                        link to complete your account set up</p>
                    <p style={{marginBottom: '24px'}}>If you haven't received it, please check your spam/junk folder. If
                        you have any issues, please <Link className="account-create-link" to={'/contact'}>contact us</Link>
                    </p>
                    <Button style={{marginTop: '8px', width: '100%'}} title={'Go to sign in'}
                            onClick={() => navigation.goTo('/login')}/>
                    {tracker()}
                </>}
            {screen === 'PENDING' &&
                <>
                    <h4 className='acc-heading'>Almost there</h4>
                    <p style={{marginBottom: '24px'}}>Thanks for signing up. You will receive an email once your account
                        has been validated.</p>
                    <Button style={{marginTop: '8px', width: '100%'}} title={'Go to sign in'}
                            onClick={() => navigation.goTo('/login')}/>
                </>
            }
        </>
    }

    const form = () => {
        return <form>
            <h4 className='acc-heading'>{heading ? heading : "Let's get started"}</h4>
            {recaptchaKey &&
                <GoogleReCaptchaProvider reCaptchaKey={recaptchaKey}>
                    <GoogleReCaptcha onVerify={(recaptcha) => setRecaptcha(recaptcha)}/>
                </GoogleReCaptchaProvider>}
            {firstNameField.render}
            {lastNameField.render}
            {emailField.render}
            {passwordField.render}
            <Button buttonId={'action-register'} setRef={actionRegister} style={{marginTop: '8px', width: '100%'}}
                    title={'Get started'}
                    onClick={handleRegister}/>
            <div className="create-account">
                <span>By registering, you are accepting our </span>
                <Link className="terms" to={'/terms'}>Terms and Conditions</Link>
            </div>
            {!hideSignIn && <div className="create-account">
                <span>Already have an account? </span>
                <Link className="account-create-link" to={'/login'}>Sign in here</Link>
            </div>}
        </form>
    }

    return (
        <>
            {screen === 'FORM' ? form() : renderSuccess()}
        </>
    )
}

export default RegisterForm