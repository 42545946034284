import React from 'react'
import styles from 'components/Lozenge/Lozenge.module.scss'
import PropTypes from 'prop-types'

const Lozenge = ({onClick, content, colour, active, showMargin, isSmall, style}) => {
    const color = () => {
        switch (colour) {
            case 'Red' :
                return styles.red
            case 'Blue' :
                return styles.blue
            case 'Teal' :
                return styles.teal
            case 'Purple' :
                return styles.purple
            case 'Dark Purple' :
                return styles.purpleReverse
            case 'Green' :
                return styles.green
            case 'Grey' :
                return styles.grey
            default :
                return
        }
    }

    const activeClass = (active && styles.active) + ' ' + (showMargin && styles.margin) + ' ' + (isSmall && styles.small);
    return (
        <div style={style} onClick={onClick} className={styles.lozenge + ' ' + color() + ' ' + activeClass}>
            {content}
        </div>
    )
}

Lozenge.propTypes = {
    colour: PropTypes.oneOf(['Red', 'Blue', 'Purple', 'Grey', 'Yellow', 'Green', 'Teal', 'Dark Purple'])
}

export default Lozenge