import 'LandingSite/layout/AccountServices/AccountServices.scss';
import React from 'react';
import {Link} from "react-router-dom";
import {Icons} from "utils/Icons";
import PropTypes from 'prop-types'

const AccountServices = (props) => {
    return (
        <div className='login-page'>
            <div className="login-page-container">
                <div className="login-container">
                    {props.hasLogo && <Link className="site-logo-container" to={'/'}>
                        {Icons.acculexLogo()}
                    </Link>}
                    <div className={'login-form-wrapper'}>
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    );
}

AccountServices.propTypes = {
    hasLogo: PropTypes.bool
}

AccountServices.defaultProps = {
    hasLogo: true
}
export default AccountServices