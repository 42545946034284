import 'LandingSite/layout/Home/PrivacyConsent.scss';
import {Link} from "react-router-dom";
import React, {Component} from "react";
import CookieService from "service/CookieService";
import F from "utils/F";
import ConfigService from "service/ConfigService";
import Button from "components/Button/Buttons";
import {Icons} from "utils/Icons";
import ButtonGroup from "components/ButtonGroup/ButtonGroup";

export default class PrivacyConsent extends Component {
    state = {
        show: false
    };

    async componentDidMount() {
        const enabled = await ConfigService.getConfigAsync('is_privacy_notice_enabled');
        if (enabled) {
            this.processNotice(enabled);
        }
    }

    processNotice(enabled) {
        const token = CookieService.get('guid');
        const notice = CookieService.get('notice');
        if (token === undefined) {
            PrivacyConsent.setInitialToken();
        }
        if (enabled && notice === undefined) {
            this.setState({show: true});
        }
    }

    static setInitialToken = async () => {
        const id = F.generateID(20);
        await CookieService.set('guid', id);
    }

    hideNotice = async () => {
        await CookieService.set('notice', 'true');
        this.setState({show: false});
    };

    renderPrivacyNotice() {
        if (this.state.show) {
            return (
                <div className={'alert'}>
                    <h4>Your Privacy</h4>
                    <p>
                        Welcome to Acculex. We use cookies to improve your experience and increase the quality of
                        our service. For more details please see our <Link to='/privacy'> Privacy Policy</Link>
                    </p>
                    <ButtonGroup>
                        <Button isSecondary buttonClass={'alert-button'}
                                icon={Icons.cross('privacy-consent-button-icon')} title={'Close'}
                                onClick={this.hideNotice}/>
                    </ButtonGroup>
                </div>
            );
        } else {
            return (
                <div/>
            );
        }
    }

    render() {
        return (
            <div className='privacy-consent'>
                {this.renderPrivacyNotice()}
            </div>
        );
    }
}