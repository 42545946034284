import React from 'react'
import {observer} from "mobx-react";
import styles from 'patterns/WorkflowSelector/WorkflowSelector.module.scss'
import {workflowStore} from "stores";
import F from "utils/F";
import Loader from "components/Loader/Loader";
import {WorkflowTemplateCard} from "components/WorkflowCard/WorkflowCard";
import {StringFunctions} from "utils/StringFunctions";
import Lozenge from "components/Lozenge/Lozenge";

const WorkflowSelector = observer(({searchText, setSearchText}) => {

    const workflowTemplates = F.arrayFromMapValues(workflowStore.workflowTemplateMap, {userFilter: false});

    const workflows = workflowTemplates.length > 0 && workflowTemplates
        .filter(w => w.name.toLowerCase().includes(searchText.toLowerCase()) || StringFunctions.getCategory(w.category).toLowerCase().includes(searchText.toLowerCase()))
        .sort(F.sortByQuery('availability', 'available'))
        .map(w =>
            <WorkflowTemplateCard key={w.code} flow={w} isComingSoon={w.availability === 'COMING_SOON'}/>
        )

    if (!workflowStore.initialised) {
        return <Loader background={'white'}/>;
    }

    const renderQuickSearch = () => {
        const categories = new Set()
        workflowTemplates.forEach(w => categories.add(w.category))
        const renderCategories = [...categories].map(c => {
            const catName = StringFunctions.getCategory(c);
            return <Lozenge showMargin key={c} active={searchText === catName}
                            onClick={() => setSearchText(catName)} colour={'Blue'}
                            content={catName}/>
        })
        return <div className={styles.quickSearch}>
            <h5>Quick Search</h5>
            <Lozenge showMargin active={searchText === ''}
                     onClick={() => setSearchText('')} colour={'Blue'}
                     content={'View All'}/>
            {renderCategories}
        </div>
    }

    return <div className={styles.flowSelector}>
        <div className={styles.workflows}>
            {renderQuickSearch()}
            {workflows}
        </div>
    </div>
})

export default WorkflowSelector