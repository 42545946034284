import styles from 'LandingSite/layout/Header/components/MobileMenu.module.scss';
import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import HeaderUser from "LandingSite/layout/Header/components/HeaderUser";
import PropTypes from 'prop-types'
import {viewportStore} from "stores";

const MobileMenu = withRouter((props) => {

    return !viewportStore.isWide && (
        <div className={!props.showMobileMenu ? styles.mobileMenuContainer : styles.open}>
            <div className={styles.menuHeader}/>
            <div className={!props.showMobileMenu ? styles.menu : styles.menuOpen}>
                <Link onClick={props.toggleMobileMenu} id={'mobile-menu-home-nav'} className={styles.menuItem}
                      to='/'>Home</Link>
                <Link onClick={props.toggleMobileMenu} id={'mobile-menu-products-nav'}
                      className={styles.menuItem} to='/products/contract-workflows'>Products</Link>
                <Link onClick={props.toggleMobileMenu} id={'mobile-menu-contact-nav'}
                      className={styles.menuItem} to='/contact'>Contact</Link>
                <Link onClick={props.toggleMobileMenu} id={'mobile-menu-pricing-nav'}
                      className={styles.menuItem} to='/pricing'>Pricing</Link>
                <Link onClick={props.toggleMobileMenu} id={'mobile-menu-pricing-nav'}
                      className={styles.menuItem} to='/faqs'>FAQs</Link>
                <hr/>
                <Link onClick={props.toggleMobileMenu} id={'mobile-menu-support-nav'}
                      className={styles.menuItem} to='/contact'>Support</Link>
                <Link onClick={props.toggleMobileMenu} id={'mobile-menu-terms-nav'} className={styles.menuItem}
                      to='/terms'>Terms</Link>
                <hr/>
                <HeaderUser showMobileMenu={props.showMobileMenu}/>
            </div>
        </div>
    );
})

MobileMenu.propTypes = {
    showMobileMenu: PropTypes.bool,
    toggleMobileMenu: PropTypes.func,
};

export default MobileMenu;