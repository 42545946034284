import React, {useEffect, useRef, useState} from 'react'
import styles from 'components/Button/Buttons.module.scss'
import {Icons} from "utils/Icons";
import ReactTooltip from "react-tooltip";

const Button = ({
                    isSecondary = false,
                    isLink = false,
                    disabled = false,
                    fillContainer = false,
                    isSmall = false,
                    iconAlignRight = false,
                    buttonClass = ' ',
                    buttonId,
                    onClick,
                    setRef,
                    icon,
                    style,
                    title,
                    children
                }) => {
    const [loading, setLoading] = useState(false);
    const mounted = useRef(false);

    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        }
    });

    const renderIcon = () => {
        if (loading) {
            return Icons.spinner(styles.spinner);
        }
        if (icon) {
            return icon;
        }
    }

    const onClickInternal = async (e) => {
        e.preventDefault();
        if (!onClick) {
            return;
        }
        setLoading(true);
        try {
            await onClick();
        } catch (error) {
            //
        }
        if (mounted.current) {
            setLoading(false);
        }
    }

    const reduceMargin = () => {
        if (title && icon) {
            if (iconAlignRight) {
                return {order: 2}
            } else return {marginRight: 8}
        }
    }

    const isPrimary = !isSecondary && !isLink && !disabled;
    const buttonClassInternal =
        (isPrimary && styles.primary) + ' ' +
        (isSecondary ? styles.secondary : '') + ' ' +
        (isLink ? styles.linkButton : '') + ' ' +
        (disabled ? styles.linkButton : '') + ' ' + // TODO: make this look different
        (iconAlignRight ? styles.iconRight : '') + ' ' +
        (fillContainer ? styles.fillContainer : '') + ' ' +
        (isSmall ? styles.smallButton : '') + ' ' +
        buttonClass;

    return (
        <button ref={setRef}
                id={buttonId}
                disabled={disabled}
                style={style}
                onClick={onClickInternal}
                className={buttonClassInternal}>
            <span className={styles.buttonIcon} style={reduceMargin()}>{renderIcon()}</span>
            <span>{children ? children : title}</span>
        </button>
    )
}

const IconButton = ({icon, onClick, tooltip, background = true, style, extraClass}) => {
    const classname = `${styles.iconCircle} ${background ? styles.bg : ''} ${extraClass}`
    return <>
        <ReactTooltip delayShow={800} className={styles.toolTip} clickable id={tooltip} effect={'solid'}/>
        <button style={style} data-for={tooltip} data-tip={tooltip} tabIndex={'0'}
                onClick={onClick}
                className={classname}>
            {icon ? icon : Icons.ellipsis()}
        </button>
    </>
}

const FloatButton = ({onClick, activeState, icon, buttonId}) => {
    return (
        <button id={buttonId} onClick={onClick}
                className={styles.floatButton}>
            {icon ? icon : Icons.plus(styles.plusIcon + ' ' + (activeState === false ? '' : styles.rotate))}
        </button>
    )
}

export {IconButton, FloatButton}
export default Button
