import axios from "axios";

export default class WebClientService {
    static getWebClient = () => {
        if(this.OVERRIDDEN_CLIENT) {
            return this.OVERRIDDEN_CLIENT;
        }
        return axios;
    }

    static overrideClient = (client) => {
        console.debug("Overriding web client")
        this.OVERRIDDEN_CLIENT = client;
    };
}