import F from "../utils/F";
import {userStore, workflowStore} from "stores";

export default class NodeService {
    /**
     * Topological sort based on Kahn's algorithm
     * @param nodesObj object of id to node
     * @param wfNodes node inter-dependencies
     * @returns A list of stages
     */
    static getStagesFromGraph = (nodesObj, wfNodes) => {
        const stages = [];
        const nodesMap = new Map(Object.entries(F.copy(nodesObj)));
        const nodeDepMap = new Map(wfNodes.map(wn => [wn.nodeId, wn.dependencyIds]))

        let count = 0;

        // find the nodes with no dependencies
        while (true) {
            if (nodesMap.size === 0) {
                break;
            }
            const nodeWithNoDependants = new Set();
            nodesMap.forEach((node, nodeId) => {
                const dependencies = nodeDepMap.get(nodeId) || [];
                if (dependencies.every(d => !nodesMap.has(d))) {
                    nodeWithNoDependants.add(node);
                }
            });
            nodeWithNoDependants.forEach(node => {
                // create single node subflow
                stages.push({
                    id: node.id,
                    name: node.name,
                    description: node.description,
                    status: node.status,
                    subFlows: [
                        {
                            name: node.name,
                            nodes: [
                                {
                                    id: node.id,
                                    name: node.name,
                                    description: node.description,
                                    status: node.status,
                                }
                            ]
                        }
                    ]
                });
                nodesMap.delete(node.id);
            });
            count++;
            if (count > 100) {
                throw new Error('Infinite loop: failing')
            }
        }
        return stages;
    };

    static getNodeStatus = (node) => {
        const ACTION_REQUIRED_STATUSES = ['SENT', 'ACTION_REQUIRED'];
        const END_STATES = ['PENDING_DEPS', 'COMPLETED', 'CANCELLED'];
        if (END_STATES.includes(node.status)) {
            return node.status;
        }
        if (!node.parties || node.parties.length === 0
            || node.parties.every(p => p.contactsTasks.every(ct => ct.task.status === 'DRAFT'))) {
            return 'IN_PROGRESS';
        } else if (node.docStatus && node.docStatus === 'REJECTED') {
            return node.docStatus;
        } else {
            node.parties.forEach(p => {
                if ((p.partyWithVersion.party.partyId === userStore.selectedCompanyId && p.partyWithVersion.party.type === "COMPANY") ||
                    (p.partyWithVersion.party.partyId === userStore.user.id && p.partyWithVersion.party.type === 'APPUSER')) {
                    p.contactsTasks.forEach(ct => {
                        if (ct.partyContact.party.partyId === userStore.user.id
                            && ct.partyContact.party.type === 'APPUSER') {
                            if (ACTION_REQUIRED_STATUSES.includes(ct.task.status)) {
                                return 'ACTION_REQUIRED'
                            }
                            if (ct.task.status === 'REJECTED') {
                                return 'REJECTED';
                            }
                        }
                    })
                }
            });
        }

        return 'AWAITING_SIGNATURES';
    }

    static nodesByStatus = (filter) => {
        let currentDrafts = [], awaitingSignatures = [], actionRequired = [], completed = [];

        if (workflowStore.initialised && userStore.user) {
            const nodes = F.arrayFromMapValues(workflowStore.nodeMapLv, {
                userFilter: true, userStore,
            });
            nodes.filter(filter)
                .forEach(node => {
                    if (node.status === 'COMPLETED') {
                        completed.push(node)
                    } else {
                        const nodeStatus = NodeService.getNodeStatus(node)
                        if (!node.parties || node.parties.length === 0
                            || node.parties.every(p => {
                                return p.contactsTasks.every(ct => ct.task.status === 'DRAFT')
                            })) {
                            currentDrafts.push(node)
                        } else {
                            if (nodeStatus === 'ACTION_REQUIRED') {
                                actionRequired.push(node);
                            } else {
                                awaitingSignatures.push(node);
                            }
                        }
                    }
                });
        }
        return {currentDrafts, awaitingSignatures, actionRequired, completed};
    };

    static isEndState = (node) => {
        const END_STATES = ['COMPLETED', 'CANCELLED'];
        return END_STATES.includes(node.status)
    };
}