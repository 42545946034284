import React, {useEffect, useRef, useState} from "react";
import PropType from 'prop-types';
import styles from 'patterns/ModalDialog/ModalDialog.module.scss'
import {viewportStore} from "stores";
import {Icons} from "utils/Icons";
import F from "utils/F";

export const ModalBody = (props) => {
    return (
        <div className={styles.modalBody}>
            {props.children}
        </div>
    )
}

export const ModalHeader = (props) => {

    const defaultCloseButton = () => {
        return Icons.chevronLeft(styles.closeModal, props.toggle)
    }

    return (
        <header className={styles.header}>
            {props.showCloseButton && (props.customButton ?
                props.customButton(styles.closeModal, props.toggle) : defaultCloseButton())}
            <h4 className={styles.modalTitle}>{props.title}</h4>
            {props.children}
        </header>
    )
}

ModalHeader.propTypes = {
    showCloseButton: PropType.bool,
    customButton: PropType.func,
    toggle: PropType.func,
    title: PropType.string
}

ModalHeader.defaultProps = {
    showCloseButton: true
}


const ModalDialog = (props) => {
    const [isOpen, setIsOpen] = useState(props.isOpen);
    const [visible, setVisible] = useState(props.isOpen);
    const modalBody = useRef();

    useEffect(() => {
        if (props.isOpen && !isOpen) {
            openModal()
        } else if (!props.isOpen) {
            closeModal()
        }
    })

    const width = () => {
        switch (props.size) {
            case 'sm':
                return {maxWidth: '500px'};
            case 'md':
                return {maxWidth: '700px'};
            case 'lg':
                return {maxWidth: '900px'}
            case 'fullscreen':
                return {width: '100%', height: '100%', maxWidth: '100%', maxHeight: '100%'}
            default:
                return {}
        }
    }

    const openModal = () => {
        setIsOpen(true);
        setVisible(true);
        document.body.style.overflow = 'hidden'
    }

    const closeModal = () => {
        setVisible(false)
        F.sleep(200).then(() => setIsOpen(false))
        document.body.style.overflow = 'unset'
    }

    const renderBuffer = () => {
        return !(props.size === 'fullscreen' || (viewportStore.isMobile && props.fillMobile)) &&
            <div style={{minHeight: 0, width: '100%', flex: '1 1 auto'}}/>
    }

    const modalClass = styles.modal + ' ' + (visible ? styles.show : styles.hide) + ' ' + (props.blur && styles.blur);
    const bodyClass = styles.body + ' ' + props.className + ' ' + (props.fillMobile && styles.fillMobile);
    if (!isOpen) {
        return <div/>;
    }
    return (
        <div className={modalClass}>
            {renderBuffer()}
            <div style={width()} ref={modalBody} className={bodyClass}>
                {props.children}
            </div>
            {renderBuffer()}
        </div>
    )
}

ModalDialog.propTypes = {
    isOpen: PropType.bool.isRequired,
    toggle: PropType.func,
    className: PropType.string,
    size: PropType.oneOf(['sm', 'md', 'lg', 'fullscreen']),
    fillMobile: PropType.bool,
    blur: PropType.bool
}

ModalDialog.defaultProps = {
    showCloseButton: true,
    header: true,
    fillMobile: true
}

export default ModalDialog