import WebClientService from "service/web/WebClientService";


export default class ConfigService {
    static CONFIG_PROMISE;

    static init = () => {
        this.CONFIG_PROMISE = WebClientService.getWebClient().get('/config.json').then(r => r.data)
    }

    static initWithConfig = (config) => {
        this.CONFIG_PROMISE = Promise.resolve(config);
    }

    static getConfigAsync = async (configName) => {
        const config = await this.CONFIG_PROMISE;
        return config[configName];
    };
}
