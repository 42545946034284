import {Route, Switch, Redirect} from "react-router";
import Home from "LandingSite/layout/Home/Home";
import About from "LandingSite/layout/About/About";
import Pricing from "LandingSite/layout/Pricing/Pricing";
import Privacy from "LandingSite/layout/Terms/Privacy";
import Terms from "LandingSite/layout/Terms/Terms";
import AccountServices from "LandingSite/layout/AccountServices/AccountServices";
import Login from "LandingSite/layout/AccountServices/Login";
import AccountVerified from "LandingSite/layout/AccountServices/AccountVerified";
import React from "react";
import ErrorPage from "LandingSite/layout/Error404/Error404";
import Header from "LandingSite/layout/Header/Header";
import Footer from "LandingSite/layout/Footer/Footer";
import Unsubscribe from "LandingSite/layout/Unsubscribe/Unsubscribe";
import ReportError from "LandingSite/layout/ReportError/ReportError";
import ContractWorkflows from "LandingSite/layout/Products/Products";
import Resources from "LandingSite/layout/Resources/Resources";

const ContactContainer = React.lazy(() => import("LandingSite/layout/Contact/ContactUs"));
const PasswordResetWithToken = React.lazy(() => import("LandingSite/layout/AccountServices/PasswordResetWithToken"));
const PasswordResetRequest = React.lazy(() => import("LandingSite/layout/AccountServices/PasswordResetRequest"));
const Register = React.lazy(() => import("LandingSite/layout/AccountServices/Register"));
const RegistrationCapture = React.lazy(() => import("LandingSite/layout/ExternalSigning/RegistrationCapture"));
const MarketingTemplate = React.lazy(() => import("LandingSite/layout/MarketingTemplate/MarketingTemplate"));
const BlogTemplate = React.lazy(() => import("LandingSite/layout/BlogTemplate/BlogTemplate"));
const FAQs = React.lazy(() => import("LandingSite/layout/FAQs/FAQs"));

const LandingSite = () => {
    return (
        <>
            <Header/>
            <React.StrictMode>
                <Switch>
                    <Route exact path='/' component={Home}/>
                    <Route exact path='/products' render={() => <Redirect to={'/products/contract-workflows'}/>}/>
                    <Route exact path='/products/contract-workflows' component={ContractWorkflows}/>
                    <Route exact path='/products/contract-workflows/:productURL' component={MarketingTemplate}/>
                    <Route exact path='/blog/:blogURL' component={BlogTemplate}/>
                    <Route exact path='/resources' component={Resources}/>
                    <Route exact path='/resources/:blogURL' component={BlogTemplate}/>
                    <Route exact path='/about' component={About}/>
                    <Route exact path='/faqs' component={FAQs}/>
                    <Route exact path='/contact' component={ContactContainer}/>
                    <Route exact path='/pricing' component={Pricing}/>
                    <Route exact path='/privacy' component={Privacy}/>
                    <Route exact path='/terms' component={Terms}/>
                    <Route exact path='/sign-up' component={RegistrationCapture}/>

                    <Route exact path='/register'>
                        <Register/>
                    </Route>
                    <Route exact path='/login'>
                        <AccountServices><Login/></AccountServices>
                    </Route>
                    <Route exact path='/password-reset'>
                        <AccountServices><PasswordResetRequest/></AccountServices>
                    </Route>
                    <Route exact path='/password-reset/:token'>
                        <AccountServices><PasswordResetWithToken/></AccountServices>
                    </Route>
                    <Route exact path='/account-verification/:token'>
                        <AccountServices><AccountVerified/></AccountServices>
                    </Route>
                    <Route exact path={'/unsubscribe/:subscriptionType/:subscriptionCode/:email'}
                           component={Unsubscribe}/>
                    <Route exact path={'/unsubscribe/:subscriptionType/:subscriptionCode/:email'}
                           component={Unsubscribe}/>
                    <Route exact path={'/report-error/:email'} component={ReportError}/>
                    <Route component={ErrorPage}/>
                </Switch>
            </React.StrictMode>
            <Footer/>
        </>
    )
}

export default LandingSite