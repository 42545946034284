import { useEffect } from "react";
import {useHistory} from "react-router";

export default function ScrollToTop() {
    const history = useHistory();

    useEffect(() => {
        let prevPath = history.location.pathname
        let y = window.scrollY
        let x = window.scrollX

        history.listen((location) => {
            if (location.pathname === prevPath) {
                window.scrollTo(x, y)
            } else {
                window.scrollTo(0, 0);
            }
            prevPath = location.pathname
        })
    }, [history]);

    return null;
}