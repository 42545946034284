import {observer} from "mobx-react";
import PubSub from "pubsub-js";
import SockJS from "sockjs-client";
import Stomp from "stompjs";
import ConfigService from "service/ConfigService";
import {autorun} from "mobx";
import {initialiseStores, userStore} from "stores";
import Notifications from "../Notifications";

@observer
class WebSocketsService {

    static connected = false;
    static stompClient = null;

    static init = async () => {
        const enabled = await ConfigService.getConfigAsync("web_sockets");
        if (!enabled) {
            console.warn("WS not enabled")
            return;
        }

        autorun(() => {
            if (userStore.user == null) {
                this.stopWS();
            } else {
                this.startWS();
            }
        });

        autorun(() => {
            if (userStore.networkReconnected) {
                Notifications.success('We are back online', 'Reconnected')
                userStore.setNetworkStatus(null);
            }
        });
    };

    static startWS = async () => {
        const api = await ConfigService.getConfigAsync('api_url');
        const isSecure = await ConfigService.getConfigAsync('is_secure');
        const wsUrl = api + "/ws";
        if (WebSocketsService.connected === false) {
            const sock = new SockJS(wsUrl);
            WebSocketsService.stompClient = Stomp.over(sock);
            if (isSecure) {
                WebSocketsService.stompClient.debug = () => {
                };
            }
            WebSocketsService.connected = true;
            WebSocketsService.stompClient.connect({}, () => {
                WebSocketsService.stompClient.subscribe('/user/topic/all', (message) => {
                    PubSub.publish('ws.in', JSON.parse(message.body));
                });

                PubSub.subscribe('ws.out', (action, message) => {
                    if (WebSocketsService.connected) {
                        WebSocketsService.stompClient.send("/app/message", {}, JSON.stringify(message));
                    }
                });

                PubSub.publish('ws.status', {status: "CONNECTED"});
            }, (disconnectMessage) => {
                WebSocketsService.connected = false;
                PubSub.publish('ws.status', {status: "DISCONNECTED", message: disconnectMessage});
                initialiseStores();
            });
        }
    };

    static stopWS() {
        if (WebSocketsService.connected) {
            WebSocketsService.stompClient.disconnect();
            WebSocketsService.connected = false;
        }
    }
}

export default WebSocketsService;