import {action, computed, makeAutoObservable} from 'mobx';
import PubSub from "pubsub-js";
import StoreUtils from "utils/StoreUtils";

class ContractStore {
    legalContentMap = new Map();
    // contentParameterMap = new Map();
    externalContracts = new Map();
    //
    initialised = false;

    constructor() {
        makeAutoObservable(this);
    }

    initialise = (externalContracts) => {

        this.setExternalContacts(StoreUtils.mapArrayGroupByVersion(externalContracts));
        PubSub.subscribe('ws.in', (action, message) => {
            if (message.eventType === 'SINGLE_UPDATE') {
                if (message.type === 'legalContent') {
                    const legalContent = message.payload;
                    this.updateSingleLegalContent(legalContent);
                }
            }
        });

        this.initialised = true;
    };

    @action setLegalContents(map) {
        this.legalContentMap = map;
    }

    @action setExternalContacts(map) {
        this.externalContracts = map;
    }

    //

    @action updateSingleLegalContent(legalContent) {
        StoreUtils.updateSingle(this.legalContentMap, legalContent);
    }

    //

    @computed get contractsMapLv() {
        return StoreUtils.getLatestVersions(this.externalContracts);
    }

    getContract(id, version = null) {
        return StoreUtils.getVersion(this.externalContracts.get(id), version);
    };

    getLegalContent(legalContentId, version = null) {
        return StoreUtils.getVersion(this.legalContentMap.get(legalContentId), version);
    }
}

export const contractStore = new ContractStore();