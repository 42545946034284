import styles from "components/IconListItem/IconListItem.module.scss";
import {Icons} from "utils/Icons";
import React from "react";

const IconListItem = ({icon, text}) => {
    return <div className={styles.listItem}>
        {Icons.iconCircle({classname: styles.iconButton, icon})}
        <span>{text}</span>
    </div>
}

export default IconListItem