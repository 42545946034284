import React from 'react'
import styles from 'components/SearchBox/SearchBox.module.scss'
import {Icons} from "utils/Icons";
import SearchResults from "components/SearchResults/SearchResults";

const SearchBox = ({value, clearText, bg, onChange, searchResults, placeholder, customId,}) => {

    return (
        <div className={styles.search}>
            {value !== '' && Icons.cross(styles.clearIcon, clearText)}
            <input id={'search-box-' + (customId && customId)}
                   type='text'
                   autoComplete={'off'}
                   className={styles.searchBox}
                   style={bg && {backgroundColor: bg}}
                   placeholder={placeholder}
                   value={value}
                   onChange={onChange}
            />
            <label aria-label={'Search box'} htmlFor={'search-box'}> {Icons.search(styles.searchIcon)}</label>
            {searchResults && searchResults.length > 0 &&
            <SearchResults id={customId}>
                {searchResults}
            </SearchResults>
            }
        </div>
    )
}

export default SearchBox