import RTWImage from "images/Illustrations/right-to-work.svg";
import RTWOgImage from "images/Illustrations/rtw_check.png";

const BlogImages = (id) => {
    switch (id) {
        case 'RIGHT_TO_WORK_EVIDENCE':
            return {src: RTWImage, alt: "legal-agreement-illustration", og: RTWOgImage};
        default :
            return null
    }
}

export {BlogImages}