import styles from 'LandingSite/layout/About/About.module.scss';
import React, {useEffect} from 'react';

const About = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    });

    return (
        <div className={styles.wrapper}>
            <div className={styles.contents}>
                <h1>Contract management for the digital age</h1>
                <div>
                    Simplify your legal agreements with one robust and comprehensive service - Acculex.
                </div>
                <div>
                    With Acculex you are in control. Our contract generation assistant helps you create
                    contracts that maximise your business potential.
                </div>
                <div>
                    We continually expand our jurisdiction coverage so you can seamlessly trade with the
                    entire world.
                </div>
            </div>

        </div>
    );
}

export default About;
