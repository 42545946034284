import React from 'react';
import './Icons.scss';

export class Icons {
    static acculexLogo = (classname) => {
        return <svg width="100%" height="100%" className={'acculexLogo ' + classname} id="b1965b93-17b3-4f6c-b625-d4d74236aab0"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 160 40">
            {/*<title>Acculex Logo</title>*/}
            <path
                d="M24.43,34a.51.51,0,0,1-.44-.29L21.1,25.52H10.16L7.28,33.68a.55.55,0,0,1-.44.29H5.62a.23.23,0,0,1-.23-.23.13.13,0,0,1,0-.06L14.4,9a.51.51,0,0,1,.43-.29h1.66a.51.51,0,0,1,.43.29l9,24.69a.22.22,0,0,1-.14.28H24.43Zm-3.9-10L15.65,10.18,10.74,23.93Z"
                fill="#232425"/>
            <path
                d="M37.11,34.42c-6.07,0-9.27-4.35-9.27-11S31,12.4,37.15,12.4c5.08,0,8,2.91,8.49,7.26a.32.32,0,0,1-.27.37H44.16a.44.44,0,0,1-.41-.37A6.2,6.2,0,0,0,37.15,14c-4.92,0-7.22,3.69-7.22,9.43s2.26,9.31,7.14,9.35c3.85,0,6.48-2.25,6.89-6.27a.43.43,0,0,1,.41-.37h1.14a.3.3,0,0,1,.33.28.14.14,0,0,1,0,.09C45.47,31.1,42.48,34.42,37.11,34.42Z"
                fill="#232425"/>
            <path
                d="M57.65,34.42c-6.07,0-9.27-4.35-9.27-11s3.2-11.07,9.31-11.07c5.08,0,8,2.91,8.49,7.26a.32.32,0,0,1-.27.37H64.7a.44.44,0,0,1-.41-.37A6.2,6.2,0,0,0,57.69,14c-4.92,0-7.18,3.69-7.18,9.43s2.26,9.31,7.14,9.35c3.85,0,6.48-2.25,6.89-6.27a.43.43,0,0,1,.41-.37H66.1a.31.31,0,0,1,.33.29s0,.05,0,.08C66,31.1,63,34.42,57.65,34.42Z"
                fill="#232425"/>
            <path
                d="M90.93,34a.36.36,0,0,1-.37-.35v0l.12-14.27L90.53,5.64a.36.36,0,0,1,.35-.37h1.4a.37.37,0,0,1,.37.35v0L92.5,19.27l.12,14.35a.36.36,0,0,1-.35.37h0Z"
                fill="#232425"/>
            <path
                d="M114.39,26.51a.33.33,0,0,1,.33.33v0c-.78,4.63-3.61,7.54-8.65,7.54-6.27,0-9.56-4.47-9.56-11s3.61-11.07,9.27-11.07,8.94,3.9,9.06,10.58a.36.36,0,0,1-.35.37H98.61c0,5.95,2.5,9.43,7.46,9.43,3.85,0,6-2.21,6.68-5.94a.41.41,0,0,1,.41-.37ZM105.78,14c-4.14,0-6.6,3-7.09,7.71h14C112.63,17.45,110.29,14,105.78,14Z"
                fill="#232425"/>
            <path
                d="M131,34a.76.76,0,0,1-.58-.29l-6.27-9.47-6.31,9.47a.76.76,0,0,1-.58.29h-1.47a.2.2,0,0,1-.2-.18.2.2,0,0,1,0-.11l7.31-10.86-6.52-9.68a.18.18,0,0,1,.06-.26.19.19,0,0,1,.1,0h1.64a.8.8,0,0,1,.58.29l5.53,8.28,5.41-8.24a.72.72,0,0,1,.58-.33h1.43a.21.21,0,0,1,.21.21.25.25,0,0,1,0,.12l-6.32,9.64,7.26,10.86a.18.18,0,0,1-.06.26.19.19,0,0,1-.1,0Z"
                fill="#232425"/>
            <path
                d="M151.31,25.27,154,26.76a.42.42,0,0,1,.11.58.57.57,0,0,1-.11.11l-9.79,6.43a.3.3,0,0,1-.34,0l-9.94-6.39a.44.44,0,0,1-.1-.6l.1-.1,2.74-1.52"
                style={{fill: 'none', stroke: '#5079e3', strokeMiterlimit: '10'}}/>
            <path
                d="M151.33,21.42,154,22.9a.42.42,0,0,1,.11.58.57.57,0,0,1-.11.11L144.21,30a.3.3,0,0,1-.34,0l-9.94-6.43a.44.44,0,0,1-.1-.6.47.47,0,0,1,.1-.1l2.63-1.46"
                style={{fill: 'none', stroke: '#5079e3', strokeMiterlimit: '10'}}/>
            <path
                d="M154,19.69l-9.79,6.39a.3.3,0,0,1-.34,0l-10-6.43a.44.44,0,0,1-.1-.6.47.47,0,0,1,.1-.1l9.82-5.46a.32.32,0,0,1,.3,0L154,19a.42.42,0,0,1,.11.58l-.11.11"
                style={{fill: 'none', stroke: '#5079e3', strokeMiterlimit: '10'}}/>
            <path
                d="M78,32.74c3.2,0,5.33-1.93,5.33-7V13.22a.38.38,0,0,1,.37-.37h1.35a.38.38,0,0,1,.37.37h0l-.08,8.7v4c0,5.79-2.62,8.53-6.89,8.53H76.59c-4.26,0-6.89-2.74-6.89-8.53v-4l-.08-8.65A.38.38,0,0,1,70,12.9h1.35a.38.38,0,0,1,.37.37h0V25.78c0,5.08,2.13,7,5.33,7h1Z"
                fill="#232425"/>
            <path
                d="M85.38,26.08V13.5a.37.37,0,0,0-.37-.36H83.68a.37.37,0,0,0-.37.35V25.86c0,5.63-2.73,6.9-5.81,6.88s-5.83-1.47-5.83-7.1V13.27c0-.2-.86-.12-1-.12h-.62a.37.37,0,0,0-.37.36V26.08c0,5.92,3.39,8.67,7.82,8.65S85.38,32,85.38,26.08Z"
                fill="#232425"/>
        </svg>
    }

    static acculexLogoInverted = (classname) => {
        return <svg className={'acculexLogo ' + classname} id="b1965b93-17b3-4f6c-b625-d4d74236aab0"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 160 40">
            {/*<title>Acculex Logo</title>*/}
            <path
                d="M24.43,34a.51.51,0,0,1-.44-.29L21.1,25.52H10.16L7.28,33.68a.55.55,0,0,1-.44.29H5.62a.23.23,0,0,1-.23-.23.13.13,0,0,1,0-.06L14.4,9a.51.51,0,0,1,.43-.29h1.66a.51.51,0,0,1,.43.29l9,24.69a.22.22,0,0,1-.14.28H24.43Zm-3.9-10L15.65,10.18,10.74,23.93Z"
                fill="#fbfbfd"/>
            <path
                d="M37.11,34.42c-6.07,0-9.27-4.35-9.27-11S31,12.4,37.15,12.4c5.08,0,8,2.91,8.49,7.26a.32.32,0,0,1-.27.37H44.16a.44.44,0,0,1-.41-.37A6.2,6.2,0,0,0,37.15,14c-4.92,0-7.22,3.69-7.22,9.43s2.26,9.31,7.14,9.35c3.85,0,6.48-2.25,6.89-6.27a.43.43,0,0,1,.41-.37h1.14a.3.3,0,0,1,.33.28.14.14,0,0,1,0,.09C45.47,31.1,42.48,34.42,37.11,34.42Z"
                fill="#fbfbfd"/>
            <path
                d="M57.65,34.42c-6.07,0-9.27-4.35-9.27-11s3.2-11.07,9.31-11.07c5.08,0,8,2.91,8.49,7.26a.32.32,0,0,1-.27.37H64.7a.44.44,0,0,1-.41-.37A6.2,6.2,0,0,0,57.69,14c-4.92,0-7.18,3.69-7.18,9.43s2.26,9.31,7.14,9.35c3.85,0,6.48-2.25,6.89-6.27a.43.43,0,0,1,.41-.37H66.1a.31.31,0,0,1,.33.29s0,.05,0,.08C66,31.1,63,34.42,57.65,34.42Z"
                fill="#fbfbfd"/>
            <path
                d="M90.93,34a.36.36,0,0,1-.37-.35v0l.12-14.27L90.53,5.64a.36.36,0,0,1,.35-.37h1.4a.37.37,0,0,1,.37.35v0L92.5,19.27l.12,14.35a.36.36,0,0,1-.35.37h0Z"
                fill="#fbfbfd"/>
            <path
                d="M114.39,26.51a.33.33,0,0,1,.33.33v0c-.78,4.63-3.61,7.54-8.65,7.54-6.27,0-9.56-4.47-9.56-11s3.61-11.07,9.27-11.07,8.94,3.9,9.06,10.58a.36.36,0,0,1-.35.37H98.61c0,5.95,2.5,9.43,7.46,9.43,3.85,0,6-2.21,6.68-5.94a.41.41,0,0,1,.41-.37ZM105.78,14c-4.14,0-6.6,3-7.09,7.71h14C112.63,17.45,110.29,14,105.78,14Z"
                fill="#fbfbfd"/>
            <path
                d="M131,34a.76.76,0,0,1-.58-.29l-6.27-9.47-6.31,9.47a.76.76,0,0,1-.58.29h-1.47a.2.2,0,0,1-.2-.18.2.2,0,0,1,0-.11l7.31-10.86-6.52-9.68a.18.18,0,0,1,.06-.26.19.19,0,0,1,.1,0h1.64a.8.8,0,0,1,.58.29l5.53,8.28,5.41-8.24a.72.72,0,0,1,.58-.33h1.43a.21.21,0,0,1,.21.21.25.25,0,0,1,0,.12l-6.32,9.64,7.26,10.86a.18.18,0,0,1-.06.26.19.19,0,0,1-.1,0Z"
                fill="#fbfbfd"/>
            <path
                d="M151.31,25.27,154,26.76a.42.42,0,0,1,.11.58.57.57,0,0,1-.11.11l-9.79,6.43a.3.3,0,0,1-.34,0l-9.94-6.39a.44.44,0,0,1-.1-.6l.1-.1,2.74-1.52"
                style={{fill: 'none', stroke: '#5079e3', strokeMiterlimit: '10'}}/>
            <path
                d="M151.33,21.42,154,22.9a.42.42,0,0,1,.11.58.57.57,0,0,1-.11.11L144.21,30a.3.3,0,0,1-.34,0l-9.94-6.43a.44.44,0,0,1-.1-.6.47.47,0,0,1,.1-.1l2.63-1.46"
                style={{fill: 'none', stroke: '#5079e3', strokeMiterlimit: '10'}}/>
            <path
                d="M154,19.69l-9.79,6.39a.3.3,0,0,1-.34,0l-10-6.43a.44.44,0,0,1-.1-.6.47.47,0,0,1,.1-.1l9.82-5.46a.32.32,0,0,1,.3,0L154,19a.42.42,0,0,1,.11.58l-.11.11"
                style={{fill: 'none', stroke: '#5079e3', strokeMiterlimit: '10'}}/>
            <path
                d="M78,32.74c3.2,0,5.33-1.93,5.33-7V13.22a.38.38,0,0,1,.37-.37h1.35a.38.38,0,0,1,.37.37h0l-.08,8.7v4c0,5.79-2.62,8.53-6.89,8.53H76.59c-4.26,0-6.89-2.74-6.89-8.53v-4l-.08-8.65A.38.38,0,0,1,70,12.9h1.35a.38.38,0,0,1,.37.37h0V25.78c0,5.08,2.13,7,5.33,7h1Z"
                fill="#fbfbfd"/>
            <path
                d="M85.38,26.08V13.5a.37.37,0,0,0-.37-.36H83.68a.37.37,0,0,0-.37.35V25.86c0,5.63-2.73,6.9-5.81,6.88s-5.83-1.47-5.83-7.1V13.27c0-.2-.86-.12-1-.12h-.62a.37.37,0,0,0-.37.36V26.08c0,5.92,3.39,8.67,7.82,8.65S85.38,32,85.38,26.08Z"
                fill="#fbfbfd"/>
        </svg>
    }

    static acculexIcon = (classname, onclick) => {
        return <svg className={classname} onClick={onclick} width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M32.0642 24.1564L35.7513 26.1935C35.876 26.2793 35.9617 26.4107 35.9899 26.5591C36.0181 26.7076 35.9866 26.8611 35.9021 26.9865C35.8589 27.0433 35.8082 27.0939 35.7513 27.1369L22.3323 35.9278C22.2638 35.9749 22.1825 36 22.0993 36C22.0161 36 21.9349 35.9749 21.8663 35.9278L8.24174 27.1916C8.1174 27.0987 8.03374 26.9617 8.00821 26.8088C7.98268 26.656 8.01722 26.4993 8.10467 26.3713L8.24174 26.2345L11.9974 24.1564" stroke="#5079E3" strokeMiterlimit="10"/>
            <path d="M32.0916 18.8928L35.7513 20.9162C35.876 21.0021 35.9617 21.1334 35.9899 21.2819C36.0181 21.4303 35.9866 21.5838 35.9021 21.7092C35.8589 21.766 35.8082 21.8166 35.7513 21.8596L22.3323 30.6506C22.2638 30.6976 22.1825 30.7228 22.0993 30.7228C22.0161 30.7228 21.9349 30.6976 21.8663 30.6506L8.24174 21.8596C8.1174 21.7668 8.03374 21.6297 8.00821 21.4769C7.98268 21.3241 8.01722 21.1673 8.10467 21.0393C8.14318 20.9871 8.1894 20.941 8.24174 20.9026L11.8466 18.9065" stroke="#5079E3" strokeMiterlimit="10"/>
            <path d="M35.7787 16.5276L22.3598 25.2638C22.2912 25.3109 22.21 25.336 22.1268 25.336C22.0435 25.336 21.9623 25.3109 21.8937 25.2638L8.24174 16.4729C8.1174 16.38 8.03374 16.2429 8.00821 16.0901C7.98268 15.9373 8.01722 15.7806 8.10467 15.6526C8.14685 15.6036 8.19268 15.5579 8.24174 15.5158L21.7018 8.05104C21.7652 8.01753 21.8358 8 21.9074 8C21.9791 8 22.0497 8.01753 22.113 8.05104L35.6965 15.5295C35.8211 15.6153 35.9069 15.7467 35.9351 15.8951C35.9633 16.0435 35.9318 16.1971 35.8473 16.3225L35.6965 16.4729" stroke="#5079E3" strokeMiterlimit="10"/>
        </svg>

    }

    // brand icons

    static iconCircle = (args = {classname: '', icon: {}}) => {
        return <div className={`icon-circle ${args.classname}`}>{args.icon}</div>
    }

    static addComment = (classname, onclick) => {
        return <svg className={classname} onClick={onclick} width="24" height="24" viewBox="0 0 92 92" fill="#424445"
                    xmlns="http://www.w3.org/2000/svg">
            {/*<title>Add Comment</title>*/}
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M16.4348 65.3775H10C7.79086 65.3775 6 63.5866 6 61.3775V12C6 9.79086 7.79086 8 10 8H82C84.2091 8 86 9.79086 86 12V61.3775C86 63.5866 84.2091 65.3775 82 65.3775H32.9783L16.4348 84V65.3775ZM35.6737 71.3775H82C87.5229 71.3775 92 66.9003 92 61.3775V12C92 6.47715 87.5228 2 82 2H10C4.47715 2 0 6.47716 0 12V61.3775C0 66.9003 4.47715 71.3775 10 71.3775H10.4348V84C10.4348 86.4914 11.9744 88.7236 14.3033 89.6086C16.6322 90.4937 19.2658 89.8474 20.9204 87.9849L35.6737 71.3775Z"
                  fill=""/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M46 13C47.6569 13 49 14.3431 49 16V56C49 57.6569 47.6569 59 46 59C44.3431 59 43 57.6569 43 56V16C43 14.3431 44.3431 13 46 13Z"
                  fill=""/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M23 36C23 34.3431 24.3431 33 26 33H66C67.6569 33 69 34.3431 69 36C69 37.6569 67.6569 39 66 39H26C24.3431 39 23 37.6569 23 36Z"
                  fill=""/>
        </svg>

    }

    static arrow = (classname, onclick) => {
        return <svg className={classname} onClick={onclick} width="24" height="24" viewBox="0 0 92 92" fill="#424445"
                    xmlns="http://www.w3.org/2000/svg">
            {/*<title>Arrow</title>*/}
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M49.4613 7.38223C51.0126 5.83092 53.5277 5.83092 55.079 7.38223L90.0845 41.7867C92.4114 44.1137 92.4114 47.8864 90.0845 50.2134L55.079 85.2188C53.5277 86.7701 51.0126 86.7701 49.4612 85.2188C47.9099 83.6675 47.9099 81.1523 49.4612 79.601L83.0622 46L49.4613 13C47.91 11.4487 47.91 8.93353 49.4613 7.38223Z"
                  fill=""/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M0 46C0 43.7909 1.63869 42 3.66011 42H87.8426C89.864 42 91.5027 43.7909 91.5027 46C91.5027 48.2091 89.864 50 87.8426 50H3.66011C1.63869 50 0 48.2091 0 46Z"
                  fill=""/>
        </svg>
    };

    static alert = (classname, onclick) => {
        return <svg className={classname} onClick={onclick} width="24" height="24" viewBox="0 0 92 93" fill="#424445"
                    xmlns="http://www.w3.org/2000/svg">
            {/*<title>Alert</title>*/}
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M46 4.94428L6.47214 84H85.5279L46 4.94428ZM2.89443 82.2112C1.56463 84.8708 3.49861 88 6.47214 88H85.5279C88.5014 88 90.4354 84.8708 89.1056 82.2112L49.5777 3.15542C48.1036 0.207225 43.8964 0.207228 42.4223 3.15542L2.89443 82.2112Z"
                  fill=""/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M41.7692 60.6382C41.7692 63.0471 43.6634 65 46 65C48.3366 65 50.2308 63.0471 50.2308 60.6382L51 30.3618C51 27.9529 48.3366 26 46 26C43.6634 26 41 27.9529 41 30.3618L41.7692 60.6382Z"
                  fill=""/>
            <circle cx="46" cy="74" r="5" fill=""/>
        </svg>
    }

    static camera = (classname, onclick) => {
        return <svg className={classname} onClick={onclick} width="24" height="24" viewBox="0 0 92 92" fill="#424445"
                    xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M23.8841 13.1869C25.7758 11.1545 28.4275 10 31.2041 10H61.696C64.4726 10 67.1242 11.1545 69.0159 13.1869L73.6837 18.202C74.4404 19.015 75.5011 19.4768 76.6117 19.4768H82C87.5228 19.4768 92 23.954 92 29.4768V71C92 76.5228 87.5229 81 82 81H10C4.47715 81 0 76.5229 0 71V29.4768C0 23.954 4.47716 19.4768 10 19.4768H16.2883C17.399 19.4768 18.4596 19.015 19.2163 18.202L23.8841 13.1869ZM31.2041 16C30.0934 16 29.0328 16.4618 28.2761 17.2748L23.6083 22.2899C21.7166 24.3224 19.065 25.4768 16.2883 25.4768H10C7.79086 25.4768 6 27.2677 6 29.4768V71C6 73.2091 7.79086 75 10 75H82C84.2091 75 86 73.2091 86 71V29.4768C86 27.2677 84.2091 25.4768 82 25.4768H76.6117C73.8351 25.4768 71.1835 24.3224 69.2917 22.2899L64.624 17.2748C63.8673 16.4618 62.8066 16 61.696 16H31.2041Z"
                  fill=""/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M46 64C54.8366 64 62 56.8366 62 48C62 39.1634 54.8366 32 46 32C37.1634 32 30 39.1634 30 48C30 56.8366 37.1634 64 46 64ZM46 70C58.1503 70 68 60.1503 68 48C68 35.8497 58.1503 26 46 26C33.8497 26 24 35.8497 24 48C24 60.1503 33.8497 70 46 70Z"
                  fill=""/>
        </svg>

    }

    static cancel = (classname, onclick) => {
        return <svg className={classname} onClick={onclick} width="92" height="92" viewBox="0 0 92 92" fill="#424445" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M46 86C68.0914 86 86 68.0914 86 46C86 23.9086 68.0914 6 46 6C23.9086 6 6 23.9086 6 46C6 68.0914 23.9086 86 46 86ZM46 92C71.4051 92 92 71.4051 92 46C92 20.5949 71.4051 0 46 0C20.5949 0 0 20.5949 0 46C0 71.4051 20.5949 92 46 92Z"
                  fill=""/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M78.1213 14.8787C79.2929 16.0503 79.2929 17.9497 78.1213 19.1213L20.1213 77.1213C18.9497 78.2929 17.0503 78.2929 15.8787 77.1213C14.7071 75.9497 14.7071 74.0503 15.8787 72.8787L73.8787 14.8787C75.0503 13.7071 76.9497 13.7071 78.1213 14.8787Z"
                  fill=""/>
        </svg>


    }


    static chevronLeft = (classname, onclick) => {
        return (
            <svg className={classname} onClick={onclick} width="24" height="24" viewBox="0 0 92 94" fill="#424445"
                 xmlns="http://www.w3.org/2000/svg">
                {/*<title>Chevron pointing left</title>*/}
                <path strokeWidth="0" fillRule="evenodd" clipRule="evenodd"
                      d="M70.1484 92.1484C68.5863 93.7105 66.0537 93.7105 64.4916 92.1484L23.5858 51.2426C21.2426 48.8995 21.2427 45.1005 23.5858 42.7574L64.4916 1.85159C66.0537 0.28949 68.5863 0.28949 70.1484 1.85159C71.7105 3.41368 71.7105 5.94634 70.1484 7.50844L30.6569 47L70.1484 86.4916C71.7105 88.0537 71.7105 90.5863 70.1484 92.1484Z"
                      fill=""/>
            </svg>
        )
    };

    static chevronRight = (classname, onclick) => {
        return (
            <svg className={classname} onClick={onclick} width="24" height="24" viewBox="0 0 92 94" fill="#424445"
                 xmlns="http://www.w3.org/2000/svg">
                {/*<title>Chevron pointing right</title>*/}
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M21.8516 1.85157C23.4137 0.289476 25.9463 0.289476 27.5084 1.85157L68.4142 42.7574C70.7574 45.1005 70.7573 48.8995 68.4142 51.2426L27.5084 92.1484C25.9463 93.7105 23.4137 93.7105 21.8516 92.1484C20.2895 90.5863 20.2895 88.0537 21.8516 86.4916L61.3431 47L21.8516 7.50843C20.2895 5.94633 20.2895 3.41367 21.8516 1.85157Z"
                      fill=""/>
            </svg>
        )
    }

    static chevronDown = (classname, onclick) => {
        return (
            <svg className={classname} onClick={onclick} width="24" height="24" viewBox="0 0 94 92" fill="#424445"
                 xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M92.1484 21.8516C93.7105 23.4137 93.7105 25.9463 92.1484 27.5084L51.2426 68.4142C48.8995 70.7574 45.1005 70.7573 42.7574 68.4142L1.85159 27.5084C0.28949 25.9463 0.28949 23.4137 1.85159 21.8516C3.41368 20.2895 5.94634 20.2895 7.50844 21.8516L47 61.3431L86.4916 21.8516C88.0537 20.2895 90.5863 20.2895 92.1484 21.8516Z"
                      fill=""/>
            </svg>
        )
    }

    static comments = (classname, onclick) => {
        return <svg className={classname} onClick={onclick} width="24" height="24" viewBox="0 0 92 92" fill="#424445"
                    xmlns="http://www.w3.org/2000/svg">
            {/*<title>Comments</title>*/}
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M16.4348 65.3775H10C7.79086 65.3775 6 63.5866 6 61.3775V12C6 9.79086 7.79086 8 10 8H82C84.2091 8 86 9.79086 86 12V61.3775C86 63.5866 84.2091 65.3775 82 65.3775H32.9783L16.4348 84V65.3775ZM35.6737 71.3775H82C87.5229 71.3775 92 66.9003 92 61.3775V12C92 6.47715 87.5228 2 82 2H10C4.47715 2 0 6.47716 0 12V61.3775C0 66.9003 4.47715 71.3775 10 71.3775H10.4348V84C10.4348 86.4914 11.9744 88.7236 14.3033 89.6086C16.6322 90.4937 19.2658 89.8474 20.9204 87.9849L35.6737 71.3775Z"
                  fill=""/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M13 36C13 34.3431 14.3431 33 16 33H60C61.6569 33 63 34.3431 63 36C63 37.6569 61.6569 39 60 39H16C14.3431 39 13 37.6569 13 36Z"
                  fill=""/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M25 24C25 22.3431 26.3431 21 28 21L72 21C73.6569 21 75 22.3431 75 24C75 25.6569 73.6569 27 72 27L28 27C26.3431 27 25 25.6569 25 24Z"
                  fill=""/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M25 48C25 46.3431 26.3431 45 28 45H72C73.6569 45 75 46.3431 75 48C75 49.6569 73.6569 51 72 51H28C26.3431 51 25 49.6569 25 48Z"
                  fill=""/>
        </svg>

    }

    static contractLines = (classname, onclick) => {
        return <svg className={classname} onClick={onclick} width="60" height="58" viewBox="0 0 60 58" fill="#424445"
                    xmlns="http://www.w3.org/2000/svg">
            {/*<title>Contracts</title>*/}
            <rect width="36" height="3" rx="1.5" fill=""/>
            <rect y="11" width="60" height="3" rx="1.5" fill=""/>
            <rect y="22" width="60" height="3" rx="1.5" fill=""/>
            <rect y="33" width="60" height="3" rx="1.5" fill=""/>
            <rect y="44" width="60" height="3" rx="1.5" fill=""/>
            <rect y="55" width="60" height="3" rx="1.5" fill=""/>
        </svg>


    }

    static contractLines1 = (classname, onclick) => {
        return <div className={classname} onClick={onclick}
                    style={{display: 'flex', flexFlow: 'row wrap', width: 100, paddingTop: 16}}>
            <div style={{flex: '0 1 60%', height: 4, background: '#74757B', marginBottom: 12, borderRadius: 4}}/>
            <div style={{flex: '1 1 100%', height: 4, background: '#74757B', marginBottom: 12, borderRadius: 4}}/>
            <div style={{flex: '1 1 100%', height: 4, background: '#74757B', marginBottom: 12, borderRadius: 4}}/>
            <div style={{flex: '1 1 100%', height: 4, background: '#74757B', marginBottom: 12, borderRadius: 4}}/>
            <div style={{flex: '1 1 100%', height: 4, background: '#74757B', marginBottom: 12, borderRadius: 4}}/>
            <div style={{flex: '1 1 100%', height: 4, background: '#74757B', marginBottom: 12, borderRadius: 4}}/>
        </div>

    }

    static contractStack = (classname, onclick) => {
        return (
            <svg className={classname} onClick={onclick} width="24" height="24" viewBox="0 0 93 92" fill="#424445"
                 xmlns="http://www.w3.org/2000/svg">
                {/*<title>Contract</title>*/}
                <path
                    d="M46.7822 92C45.8951 92.001 45.0267 91.7457 44.2824 91.2648L2.68489 64.3602C1.98886 63.8964 1.42282 63.2636 1.04009 62.5217C0.657361 61.7797 0.470552 60.9528 0.497261 60.1191C0.52397 59.2853 0.763317 58.472 1.19275 57.7558C1.62219 57.0396 2.22757 56.4442 2.95188 56.0255L14.4092 49.6652L17.609 55.2156L8.32355 60.3706L46.7822 85.2372L84.6709 60.4005L75.5715 55.3929L78.7543 49.8315L90.0196 56.0335C90.7448 56.4487 91.3521 57.0408 91.7843 57.7542C92.2166 58.4676 92.4596 59.2788 92.4905 60.1115C92.5214 60.9442 92.3391 61.7711 91.961 62.5143C91.5828 63.2575 91.021 63.8927 90.3286 64.3602L49.3141 91.2469C48.5622 91.7392 47.6819 92.001 46.7822 92Z"
                    fill=""/>
                <path
                    d="M46.7823 75.6912C45.8946 75.6907 45.0262 75.4336 44.2824 74.9511L2.68992 48.0504C1.99375 47.5866 1.42761 46.9538 1.04489 46.2117C0.66216 45.4696 0.475452 44.6426 0.502364 43.8087C0.529276 42.9748 0.768922 42.1615 1.19871 41.4454C1.62849 40.7293 2.23426 40.134 2.9589 39.7157L13.9582 33.6104L17.158 39.1609L8.33358 44.0599L46.7823 68.9275L84.669 44.0898L75.6625 39.132L78.8453 33.5706L90.0216 39.7237C90.7467 40.1391 91.3539 40.7314 91.7859 41.4449C92.218 42.1584 92.4608 42.9696 92.4915 43.8023C92.5222 44.635 92.3398 45.4618 91.9614 46.2049C91.583 46.9481 91.0212 47.5832 90.3286 48.0504L49.3141 74.9401C48.5622 75.4321 47.6818 75.6933 46.7823 75.6912Z"
                    fill=""/>
                <path
                    d="M46.7872 59.3805C45.9001 59.3804 45.032 59.1247 44.2874 58.6443L2.68989 31.7407C1.99373 31.2767 1.42762 30.6438 1.04492 29.9017C0.66221 29.1595 0.475514 28.3324 0.502425 27.4985C0.529336 26.6645 0.768967 25.8511 1.19873 25.135C1.6285 24.4188 2.23424 23.8234 2.95888 23.405L44.0824 0.580507C44.7616 0.201073 45.5271 0.00122623 46.3058 5.6251e-06C47.0846 -0.00121498 47.8507 0.196232 48.5311 0.573534L90.0216 23.414C90.7467 23.8294 91.3538 24.4216 91.7859 25.1351C92.2179 25.8486 92.4607 26.6599 92.4914 27.4926C92.5221 28.3253 92.3397 29.1521 91.9614 29.8952C91.583 30.6383 91.0211 31.2734 90.3286 31.7407L49.3141 58.6274C48.5636 59.1188 47.6852 59.3806 46.7872 59.3805ZM8.33255 27.7511L46.7822 52.6177L84.6679 27.78L46.3153 6.6669L8.33255 27.7511ZM86.7138 26.4392H86.7078H86.7138Z"
                    fill=""/>
            </svg>

        )
    };

    static cross = (classname, onClick) => {
        return <svg className={classname} onClick={onClick} width="24" height="24" viewBox="0 0 92 92" fill="#424445"
                    xmlns="http://www.w3.org/2000/svg">
            {/*<title>Close or delete</title>*/}
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M13.2468 13.2468C14.8089 11.6847 17.3416 11.6847 18.9037 13.2468L78.7532 73.0963C80.3153 74.6584 80.3153 77.1911 78.7532 78.7532C77.1911 80.3153 74.6584 80.3153 73.0963 78.7532L13.2468 18.9037C11.6847 17.3416 11.6847 14.8089 13.2468 13.2468Z"
                  fill=""/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M13.2468 78.7532C11.6847 77.1911 11.6847 74.6584 13.2468 73.0963L73.0963 13.2468C74.6584 11.6847 77.1911 11.6847 78.7532 13.2468C80.3153 14.8089 80.3153 17.3416 78.7532 18.9037L18.9037 78.7532C17.3416 80.3153 14.8089 80.3153 13.2468 78.7532Z"
                  fill=""/>
        </svg>

    }

    static dashboard = (classname, onclick) => {
        return (
            <svg className={classname} onClick={onclick} width="24" height="24" viewBox="0 0 92 92" fill="#424445"
                 xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M20.2617 67C13.0419 67 8.10442 66.984 7.72953 66.9421C5.56109 66.7899 3.53864 65.7958 2.09564 64.1728C0.652632 62.5498 -0.0961042 60.427 0.0098982 58.2595C0.209837 46.2176 4.16262 36.0489 11.7513 28.0349C19.7029 19.6367 29.138 14.7135 39.7927 13.4002C55.134 11.51 68.5899 16.3223 79.7804 27.7046C86.0335 34.1319 90.1336 42.3385 91.5148 51.1916C91.9087 53.9064 92.0632 56.6505 91.9767 59.3922V59.6427C91.9569 61.4875 91.2316 63.255 89.9494 64.5835C88.6671 65.9121 86.9247 66.7012 85.0788 66.7894C81.7968 66.8593 41.3212 67 20.2617 67ZM8.33435 60.7465C11.8933 60.8463 78.9767 60.6926 84.9519 60.5679C85.1641 60.5093 85.3535 60.3877 85.4948 60.219C85.6361 60.0503 85.7225 59.8427 85.7426 59.6237V59.3723C85.8236 56.9911 85.6995 54.6074 85.3717 52.2475C84.231 44.636 80.7202 37.5758 75.3368 32.0668C65.4398 22 54.0593 17.9141 40.5565 19.5788C31.3783 20.7096 23.2108 24.994 16.2809 32.3124C9.70393 39.2585 6.42093 47.7814 6.24199 58.3673C6.19819 58.6652 6.2198 58.9691 6.3053 59.2579C6.39081 59.5467 6.53817 59.8135 6.73719 60.0398C6.93621 60.2661 7.18212 60.4465 7.4579 60.5685C7.73368 60.6906 8.03273 60.7513 8.33435 60.7465ZM8.40732 60.7545H8.42332H8.40732Z"
                    fill=""/>
                <path
                    d="M47.2364 60.0399C45.3531 60.0696 43.5174 59.4486 42.0401 58.282C40.5628 57.1155 39.5347 55.4751 39.1299 53.6387C38.3531 49.3742 40.4895 45.8403 44.7072 44.4161C45.6199 44.118 46.4691 43.653 47.2114 43.0449C49.5177 41.0489 51.836 38.979 54.0783 36.979C56.2107 35.0768 58.416 33.1098 60.6193 31.1906C61.7754 30.2054 63.1863 29.5655 64.6901 29.3443C65.4761 29.2243 66.2799 29.3151 67.0193 29.6072C67.7586 29.8994 68.4069 30.3824 68.8978 31.007C69.3715 31.6262 69.6716 32.3601 69.7673 33.1334C69.8631 33.9066 69.751 34.6914 69.4426 35.4072C69.109 36.161 68.6567 36.8567 68.103 37.468L67.5642 38.0938C64.1922 42.013 60.7063 46.0658 57.2374 50.0289C56.1318 51.1267 55.3642 52.5175 55.025 54.0369C54.4462 57.4541 51.1012 60.0349 47.2444 60.0399H47.2324H47.2364ZM61.0482 37.7185C59.8655 38.7654 58.6909 39.8143 57.5373 40.8423C55.281 42.8553 52.9477 44.9341 50.6124 46.9591C49.3607 48.0082 47.921 48.8108 46.3697 49.3243C44.0474 50.1088 44.0404 51.6327 44.2373 52.7116C44.4203 53.3554 44.8165 53.9182 45.3612 54.3083C45.9059 54.6984 46.5669 54.8926 47.2364 54.8593C47.8044 54.9071 48.3725 54.7684 48.8543 54.4643C49.3361 54.1602 49.7053 53.7074 49.9056 53.1746C50.4042 50.7115 51.5905 48.4394 53.3276 46.6207C55.9098 43.6746 58.5 40.6766 61.0482 37.7195V37.7185Z"
                    fill=""/>
            </svg>
        )

    };

    static dateIcon = (classname, onclick) => {
        return (
            <svg className={classname} onClick={onclick} width="24" height="24" viewBox="0 0 92 92" fill="#434445"
                 xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M50.157 90.002C44.151 90.002 38.174 90.002 32.288 89.995L20.81 89.989C19.932 89.989 19.027 89.946 18.116 89.863C14.0217 89.5001 10.1428 87.8682 7.0205 85.195C3.89821 82.5217 1.68826 78.9405 0.699 74.951C0.472 74.023 0.338 73.13 0.209 72.267C0.156 71.913 0.103 71.559 0.043 71.206L0 70.952V26.838L0.268 24.866C0.650883 22.0634 1.64568 19.3793 3.18186 17.0041C4.71804 14.6288 6.75797 12.6207 9.157 11.122C10.6754 10.1032 12.3406 9.32247 14.095 8.80701C14.657 8.64101 14.913 8.56501 45.922 8.53201C76.856 8.49801 77.085 8.56101 77.586 8.69701L77.635 8.71101C81.7194 9.87917 85.3201 12.3294 87.9058 15.7001C90.4914 19.0708 91.925 23.1834 91.995 27.431C92.003 39.5823 92.003 51.7337 91.995 63.885V70.317C92.0157 73.4569 91.2322 76.5497 89.719 79.301C85.877 86.267 79.59 89.96 71.538 89.98C64.441 89.997 57.278 90.002 50.157 90.002ZM6.136 70.441C6.185 70.741 6.236 71.051 6.278 71.358C6.392 72.119 6.499 72.837 6.66 73.493C7.34527 76.241 8.87135 78.7065 11.0253 80.5455C13.1792 82.3844 15.8535 83.5051 18.675 83.751C19.402 83.818 20.121 83.851 20.813 83.851L32.292 83.858C45.15 83.866 58.447 83.875 71.525 83.843C77.375 83.829 81.569 81.374 84.347 76.337C85.3562 74.4919 85.8761 72.419 85.857 70.316V63.884C85.857 51.734 85.857 39.5843 85.857 27.435C85.7767 24.5836 84.8125 21.8276 83.0977 19.548C81.383 17.2684 79.0023 15.5778 76.285 14.71C71.394 14.61 20.085 14.683 15.504 14.795C14.4678 15.1283 13.4838 15.606 12.581 16.214C10.9028 17.2329 9.47267 18.6128 8.39436 20.2535C7.31605 21.8941 6.61667 23.7543 6.347 25.699L6.136 27.253V70.441Z"
                    fill=""/>
                <path
                    d="M35.461 32.097C35.461 31.889 35.253 31.681 35.045 31.681H28.545C28.285 31.681 28.077 31.837 27.921 32.045C26.361 34.957 23.813 37.141 20.277 38.389C20.017 38.493 19.861 38.701 19.861 38.961V44.161C19.861 44.369 20.069 44.525 20.277 44.473C23.137 43.589 25.633 42.237 27.557 40.417V67.665C27.557 67.873 27.765 68.081 27.973 68.081H35.045C35.253 68.081 35.461 67.873 35.461 67.665V32.097Z"
                    fill=""/>
                <path
                    d="M70.8686 57.681C70.8686 53.573 68.5286 50.401 64.0046 48.841C67.8006 47.385 69.9326 44.213 69.8806 40.625C69.7246 34.333 65.2006 30.901 56.8806 30.901C48.6126 30.901 44.0886 34.333 43.9326 40.625C43.8806 44.213 45.9606 47.489 49.7566 48.841C45.2326 50.401 42.9446 53.573 42.9446 57.681C42.9446 64.805 49.0806 68.861 56.8806 68.861C64.7326 68.861 70.8686 64.805 70.8686 57.681ZM51.5766 41.561C51.5766 38.285 53.6046 36.153 56.8806 36.153C60.1566 36.153 62.1846 38.285 62.1846 41.561C62.1846 44.681 60.1046 46.761 56.8806 46.761C53.6566 46.761 51.5766 44.681 51.5766 41.561ZM50.6406 57.421C50.6406 53.833 53.0846 51.597 56.8806 51.597C60.6766 51.597 63.1206 53.833 63.1206 57.421C63.1206 60.957 60.5206 63.505 56.8806 63.505C53.2406 63.505 50.6406 60.957 50.6406 57.421Z"
                    fill=""/>
                <path
                    d="M24.208 1H24.207C22.3834 1 20.905 2.47836 20.905 4.302V18.342C20.905 20.1656 22.3834 21.644 24.207 21.644H24.208C26.0316 21.644 27.51 20.1656 27.51 18.342V4.302C27.51 2.47836 26.0316 1 24.208 1Z"
                    fill=""/>
                <path
                    d="M67.547 1H67.546C65.7224 1 64.244 2.47836 64.244 4.302V18.342C64.244 20.1656 65.7224 21.644 67.546 21.644H67.547C69.3707 21.644 70.849 20.1656 70.849 18.342V4.302C70.849 2.47836 69.3707 1 67.547 1Z"
                    fill=""/>
            </svg>
        )

    };

    static delete = (classname, onclick) => {
        return (
            <svg className={classname} onClick={onclick} width="24" height="24" viewBox="0 0 92 92" fill="#424445" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M14 81V28H20V81C20 83.7614 22.2386 86 25 86H67C69.7614 86 72 83.7614 72 81V28H78V81C78 87.0751 73.0751 92 67 92H25C18.9249 92 14 87.0751 14 81Z"
                      fill=""/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M30 38C31.6569 38 33 39.3431 33 41V73C33 74.6569 31.6569 76 30 76C28.3431 76 27 74.6569 27 73V41C27 39.3431 28.3431 38 30 38Z"
                      fill=""/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M46 38C47.6569 38 49 39.3431 49 41V73C49 74.6569 47.6569 76 46 76C44.3431 76 43 74.6569 43 73V41C43 39.3431 44.3431 38 46 38Z"
                      fill=""/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M62 38C63.6569 38 65 39.3431 65 41V73C65 74.6569 63.6569 76 62 76C60.3431 76 59 74.6569 59 73V41C59 39.3431 60.3431 38 62 38Z"
                      fill=""/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M15 16V24H77V16H15ZM13 10C10.7909 10 9 11.7909 9 14V26C9 28.2091 10.7909 30 13 30H79C81.2091 30 83 28.2091 83 26V14C83 11.7909 81.2091 10 79 10H13Z"
                      fill=""/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M40 6V10H52V6H40ZM38 0C35.7909 0 34 1.79086 34 4V12C34 14.2091 35.7909 16 38 16H54C56.2091 16 58 14.2091 58 12V4C58 1.79086 56.2091 0 54 0H38Z"
                      fill=""/>
            </svg>

        )

    };

    static discussion = (classname, onclick) => {
        return (
            <svg className={classname} onClick={onclick} width="24" height="24" viewBox="0 0 92 92" fill="#424445" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.97826 44.4502C4.65758 44.4502 3.58696 43.392 3.58696 42.0866V12.9091C3.58696 11.6037 4.65758 10.5454 5.97826 10.5454H49.0217C50.3424 10.5454 51.413 11.6037 51.413 12.9091V36H55V12.9091C55 9.64558 52.3234 7 49.0217 7H5.97826C2.67655 7 0 9.64559 0 12.9091V42.0866C0 45.3501 2.67656 47.9957 5.97826 47.9957H6.23818V55.4544C6.23818 56.9266 7.15863 58.2456 8.5509 58.7686C9.94318 59.2916 11.5176 58.9097 12.5068 57.8091L21.3267 47.9957H38V44.4502H19.7153L9.82514 55.4544V44.4502H5.97826Z" fill=""/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M8.36957 27.6819C8.36957 26.7028 9.17254 25.9091 10.163 25.9091H36.4674C37.4579 25.9091 38.2609 26.7028 38.2609 27.6819C38.2609 28.6609 37.4579 29.4546 36.4674 29.4546H10.163C9.17254 29.4546 8.36957 28.6609 8.36957 27.6819Z" fill=""/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M15.5435 20.5908C15.5435 19.6117 16.3465 18.8181 17.337 18.8181H43.6413C44.6318 18.8181 45.4348 19.6117 45.4348 20.5908C45.4348 21.5698 44.6318 22.3635 43.6413 22.3635H17.337C16.3465 22.3635 15.5435 21.5698 15.5435 20.5908Z" fill=""/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M15.5435 34.7727C15.5435 33.7936 16.3465 33 17.337 33H43.6413C44.6318 33 45.4348 33.7936 45.4348 34.7727C45.4348 35.7517 44.6318 36.5454 43.6413 36.5454H17.337C16.3465 36.5454 15.5435 35.7517 15.5435 34.7727Z" fill=""/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M82.3535 73.4502H86.1304C87.4271 73.4502 88.4783 72.392 88.4783 71.0866V41.9091C88.4783 40.6037 87.4271 39.5454 86.1304 39.5454H43.8696C42.5729 39.5454 41.5217 40.6037 41.5217 41.9091V71.0866C41.5217 72.392 42.5729 73.4502 43.8696 73.4502H72.6432L82.3535 84.4544V73.4502ZM71.0611 76.9957H43.8696C40.6279 76.9957 38 74.3501 38 71.0866V41.9091C38 38.6456 40.6279 36 43.8696 36H86.1304C89.3721 36 92 38.6456 92 41.9091V71.0866C92 74.3501 89.3721 76.9957 86.1304 76.9957H85.8752V84.4544C85.8752 85.9266 84.9715 87.2456 83.6046 87.7686C82.2376 88.2916 80.6918 87.9097 79.7206 86.8091L71.0611 76.9957Z" fill=""/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M83.7826 56.6819C83.7826 55.7028 82.9943 54.9091 82.0218 54.9091H56.1957C55.2232 54.9091 54.4348 55.7028 54.4348 56.6819C54.4348 57.6609 55.2232 58.4546 56.1957 58.4546H82.0218C82.9943 58.4546 83.7826 57.6609 83.7826 56.6819Z" fill=""/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M76.7391 49.5908C76.7391 48.6117 75.9508 47.8181 74.9783 47.8181H49.1522C48.1797 47.8181 47.3913 48.6117 47.3913 49.5908C47.3913 50.5698 48.1797 51.3635 49.1522 51.3635H74.9783C75.9508 51.3635 76.7391 50.5698 76.7391 49.5908Z" fill=""/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M76.7391 63.7727C76.7391 62.7936 75.9508 62 74.9783 62H49.1522C48.1797 62 47.3913 62.7936 47.3913 63.7727C47.3913 64.7517 48.1797 65.5454 49.1522 65.5454H74.9783C75.9508 65.5454 76.7391 64.7517 76.7391 63.7727Z" fill=""/>
                </svg>

                )
    }

    static download = (classname, onclick) => {
        return <svg className={classname} onClick={onclick} width="24" height="24" viewBox="0 0 92 92" fill="#424445"
                    xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M7 85C7 83.3431 8.34315 82 10 82H82C83.6569 82 85 83.3431 85 85C85 86.6569 83.6569 88 82 88H10C8.34315 88 7 86.6569 7 85Z"
                  fill=""/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M72.8646 38.4078C73.9437 39.487 73.9437 41.2367 72.8646 42.3158L48.931 66.6674C47.3123 68.2862 44.6878 68.2862 43.069 66.6674L18.7174 42.3158C17.6382 41.2367 17.6382 39.487 18.7174 38.4078C19.7965 37.3286 21.5462 37.3286 22.6254 38.4078L46 61.7824L68.9566 38.4078C70.0357 37.3287 71.7854 37.3287 72.8646 38.4078Z"
                  fill=""/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M46 4C47.5368 4 48.7826 5.13996 48.7826 6.54616L48.7826 65.1079C48.7826 66.5141 47.5368 67.6541 46 67.6541C44.4632 67.6541 43.2174 66.5141 43.2174 65.1079V6.54616C43.2174 5.13996 44.4632 4 46 4Z"
                  fill=""/>
        </svg>


    };

    static ellipsis = (classname, onclick) => {
        return <svg className={classname} onClick={onclick} width="24" height="24" viewBox="0 0 92 92" fill="#424445"
                    xmlns="http://www.w3.org/2000/svg">
            {/*<title>More</title>*/}
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M10 50C12.2091 50 14 48.2091 14 46C14 43.7909 12.2091 42 10 42C7.79086 42 6 43.7909 6 46C6 48.2091 7.79086 50 10 50ZM10 56C15.5228 56 20 51.5228 20 46C20 40.4772 15.5228 36 10 36C4.47715 36 0 40.4772 0 46C0 51.5228 4.47715 56 10 56Z"
                  fill=""/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M46 50C48.2091 50 50 48.2091 50 46C50 43.7909 48.2091 42 46 42C43.7909 42 42 43.7909 42 46C42 48.2091 43.7909 50 46 50ZM46 56C51.5228 56 56 51.5228 56 46C56 40.4772 51.5228 36 46 36C40.4772 36 36 40.4772 36 46C36 51.5228 40.4772 56 46 56Z"
                  fill=""/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M82 50C84.2091 50 86 48.2091 86 46C86 43.7909 84.2091 42 82 42C79.7909 42 78 43.7909 78 46C78 48.2091 79.7909 50 82 50ZM82 56C87.5228 56 92 51.5228 92 46C92 40.4772 87.5228 36 82 36C76.4772 36 72 40.4772 72 46C72 51.5228 76.4772 56 82 56Z"
                  fill=""/>
        </svg>
    }

    static hide = (classname, onclick) => {
        return <svg className={classname} onClick={onclick} width="24" height="24" viewBox="0 0 92 92" fill="#424445"
                    xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M1.24601 41.8892C5.85706 34.9608 20.739 16 46 16C71.261 16 86.1429 34.9608 90.754 41.8892C92.4153 44.3854 92.4153 47.6146 90.754 50.1108C86.1429 57.0392 71.261 76 46 76C20.739 76 5.85706 57.0392 1.24601 50.1108C-0.415337 47.6146 -0.415337 44.3854 1.24601 41.8892ZM46 21.6292C23.628 21.6292 10.1875 38.4828 5.81767 45.0488C5.42598 45.6373 5.42598 46.3627 5.81767 46.9512C10.1875 53.5172 23.628 70.3708 46 70.3708C68.372 70.3708 81.8125 53.5172 86.1823 46.9512C86.574 46.3627 86.574 45.6373 86.1823 45.0488C81.8125 38.4828 68.372 21.6292 46 21.6292Z"
                  fill=""/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M46.1968 60.3228C53.9984 60.3228 60.3228 53.9984 60.3228 46.1968C60.3228 38.3953 53.9984 32.0709 46.1968 32.0709C38.3953 32.0709 32.0709 38.3953 32.0709 46.1968C32.0709 53.9984 38.3953 60.3228 46.1968 60.3228ZM46.1968 65.3937C56.799 65.3937 65.3937 56.799 65.3937 46.1968C65.3937 35.5947 56.799 27 46.1968 27C35.5947 27 27 35.5947 27 46.1968C27 56.799 35.5947 65.3937 46.1968 65.3937Z"
                  fill=""/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M10.1213 82.1213C8.94975 80.9497 9.24198 78.758 10.774 77.226L77.3509 10.6491C78.8829 9.11709 81.0746 8.82486 82.2462 9.99643C83.4178 11.168 83.1256 13.3597 81.5935 14.8918L15.0167 81.4686C13.4846 83.0007 11.2929 83.2929 10.1213 82.1213Z"
                  fill=""/>
        </svg>

    }

    static info = (classname, onclick) => {
        return <svg className={classname} onClick={onclick} width="24" height="24" viewBox="0 0 92 92" fill="#424445"
                    xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M46 86C68.0914 86 86 68.0914 86 46C86 23.9086 68.0914 6 46 6C23.9086 6 6 23.9086 6 46C6 68.0914 23.9086 86 46 86ZM46 92C71.4051 92 92 71.4051 92 46C92 20.5949 71.4051 0 46 0C20.5949 0 0 20.5949 0 46C0 71.4051 20.5949 92 46 92Z"
                  fill=""/>
            <circle cx="46" cy="25" r="5" fill=""/>
            <rect x="41" y="36" width="10" height="37" rx="5" fill=""/>
        </svg>

    };

    static linked = (classname, onclick) => {
        return <svg className={classname} onClick={onclick} width="24" height="24" viewBox="0 0 92 92" fill="#424445"
                    xmlns="http://www.w3.org/2000/svg">

            <path
                d="M37.0229 51.73C30.0948 43.5262 31.0163 31.2935 38.5838 23.7288L54.8503 7.4684C62.4177 -0.0962266 74.5801 -0.942488 82.7813 5.84727C92.1137 13.6272 92.606 27.6987 84.1899 36.1117L66.1553 54.1396C64.953 55.3414 62.9732 54.4925 62.9736 52.7955C62.9737 51.9469 62.9033 50.6034 62.9033 50.1792C62.9744 48.1286 63.4696 46.9266 64.8841 45.5126L78.9582 31.4438C83.8381 26.5657 84.5467 18.6462 80.305 13.2714C75.1444 6.83579 65.8106 6.40979 60.1527 12.0656L43.4619 28.7502C38.3698 33.8404 37.8026 42.0427 42.5392 47.4884C43.1047 48.1956 43.8824 48.8321 44.5894 49.3979C45.862 50.3881 46.3567 52.0145 45.6493 53.4286C45.5786 53.6407 45.4371 53.7821 45.4371 53.7821C44.5175 55.6912 42.1133 56.2564 40.4871 55.054C39.2145 54.0638 38.0126 53.0029 37.0229 51.73Z"
                fill=""/>
            <path
                d="M6.04566 84.1097C-1.51887 75.9766 -0.880087 63.1781 6.97026 55.3307L26.2071 36.1009C27.268 35.0405 28.9649 35.8893 28.8939 37.3742L28.6103 41.4754C28.5393 42.9603 27.9027 44.3037 26.8418 45.3642L11.7776 60.4227C6.61479 65.5836 6.11825 73.9981 11.067 79.3731C16.2278 85.1016 25.1373 85.2447 30.5831 79.801L47.2032 63.187C52.6489 57.7433 52.8627 48.6217 47.2069 43.3881C46.7827 42.9637 46.2878 42.6101 45.7929 42.2564C44.4495 41.3369 43.9549 39.7105 44.5915 38.2257C44.6623 38.0136 44.8037 37.8722 44.8037 37.8722C45.6526 35.8924 47.9862 35.2565 49.683 36.3882C51.0264 37.3076 52.2283 38.3685 53.2887 39.5708C60.4997 47.4918 59.7902 60.5024 52.152 68.1377L35.6026 84.6809C27.3986 92.8818 14.0344 92.6672 6.04566 84.1097Z"
                fill=""/>

        </svg>

    };

    static list = (classname, onclick) => {
        return (
            <svg className={classname} onClick={onclick} width="24" height="24" viewBox="0 0 92 92" fill="#424445"
                 xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M83.9474 18H28.0526C26.919 18 26 18.919 26 20.0526C26 21.1863 26.919 22.1053 28.0526 22.1053H83.9474C85.081 22.1053 86 21.1863 86 20.0526C86 18.919 85.081 18 83.9474 18ZM28.0526 12C23.6053 12 20 15.6053 20 20.0526C20 24.5 23.6053 28.1053 28.0526 28.1053H83.9474C88.3947 28.1053 92 24.5 92 20.0526C92 15.6053 88.3947 12 83.9474 12H28.0526Z"
                      fill=""/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M6 20.0526C6 21.2085 6.9465 22.1053 8 22.1053C9.0535 22.1053 10 21.2085 10 20.0526C10 18.8968 9.0535 18 8 18C6.9465 18 6 18.8968 6 20.0526ZM8 12C3.58172 12 0 15.6344 0 20.0526C0 24.4709 3.58172 28.1053 8 28.1053C12.4183 28.1053 16 24.4709 16 20.0526C16 15.6344 12.4183 12 8 12Z"
                      fill=""/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M84 43.9474H28C26.8954 43.9474 26 44.8428 26 45.9474C26 47.0519 26.8954 47.9474 28 47.9474H84C85.1046 47.9474 86 47.0519 86 45.9474C86 44.8428 85.1046 43.9474 84 43.9474ZM28 37.9474C23.5817 37.9474 20 41.5291 20 45.9474C20 50.3656 23.5817 53.9474 28 53.9474H84C88.4183 53.9474 92 50.3656 92 45.9474C92 41.5291 88.4183 37.9474 84 37.9474H28Z"
                      fill=""/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M83.9474 69.8947H28.0526C26.919 69.8947 26 70.8137 26 71.9474C26 73.081 26.919 74 28.0526 74H83.9474C85.081 74 86 73.081 86 71.9474C86 70.8137 85.081 69.8947 83.9474 69.8947ZM28.0526 63.8947C23.6053 63.8947 20 67.5 20 71.9474C20 76.3947 23.6053 80 28.0526 80H83.9474C88.3947 80 92 76.3947 92 71.9474C92 67.5 88.3947 63.8947 83.9474 63.8947H28.0526Z"
                      fill=""/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M6 45.9474C6 47.0519 6.89543 47.9474 8 47.9474C9.10457 47.9474 10 47.0519 10 45.9474C10 44.8428 9.10457 43.9474 8 43.9474C6.89543 43.9474 6 44.8428 6 45.9474ZM8 37.9474C3.58172 37.9474 0 41.5291 0 45.9474C0 50.3656 3.58172 53.9474 8 53.9474C12.4183 53.9474 16 50.3656 16 45.9474C16 41.5291 12.4183 37.9474 8 37.9474Z"
                      fill=""/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M6 71.9474C6 73.1032 6.9465 74 8 74C9.0535 74 10 73.1032 10 71.9474C10 70.7915 9.0535 69.8947 8 69.8947C6.9465 69.8947 6 70.7915 6 71.9474ZM8 63.8947C3.58172 63.8947 0 67.5291 0 71.9474C0 76.3657 3.58172 80 8 80C12.4183 80 16 76.3657 16 71.9474C16 67.5291 12.4183 63.8947 8 63.8947Z"
                      fill=""/>
            </svg>

        )
    };

    static menuBars = (classname, onclick) => {
        return (
            <svg className={classname} onClick={onclick} width="24" height="24" viewBox="0 0 92 92"
                 fill="#424445" xmlns="http://www.w3.org/2000/svg">
                <path className={'bar1'} fillRule="evenodd" clipRule="evenodd"
                      d="M0 18C0 15.7909 1.63869 14 3.66011 14H87.8426C89.864 14 91.5027 15.7909 91.5027 18C91.5027 20.2091 89.864 22 87.8426 22H3.66011C1.63869 22 0 20.2091 0 18Z"
                      fill=""/>
                <path className={'bar2'} fillRule="evenodd" clipRule="evenodd"
                      d="M0.497314 46C0.497314 43.7909 2.136 42 4.15742 42H88.3399C90.3613 42 92 43.7909 92 46C92 48.2091 90.3613 50 88.3399 50H4.15742C2.136 50 0.497314 48.2091 0.497314 46Z"
                      fill=""/>
                <path className={'bar3'} fillRule="evenodd" clipRule="evenodd"
                      d="M0.497314 74C0.497314 71.7909 2.136 70 4.15742 70H88.3399C90.3613 70 92 71.7909 92 74C92 76.2091 90.3613 78 88.3399 78H4.15742C2.136 78 0.497314 76.2091 0.497314 74Z"
                      fill=""/>
            </svg>

        )
    };

    static menuCollapse = (classname, onclick) => {
        return (
            <svg className={classname} onClick={onclick} width="44" height="24" viewBox="0 0 166 92" fill="#424445"
                 xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M40.2667 83.6236C38.9649 84.9253 36.8544 84.9253 35.5526 83.6236L1.46447 49.5354C-0.488157 47.5828 -0.488154 44.417 1.46447 42.4644L35.5526 8.37621C36.8544 7.07447 38.9649 7.07447 40.2667 8.37621C41.5684 9.67796 41.5684 11.7885 40.2667 13.0903L7.35702 45.9999L40.2667 78.9095C41.5684 80.2113 41.5684 82.3218 40.2667 83.6236Z"
                      fill=""/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M73.8097 18C73.8097 15.7909 75.4484 14 77.4698 14H161.652C163.674 14 165.312 15.7909 165.312 18C165.312 20.2091 163.674 22 161.652 22H77.4698C75.4484 22 73.8097 20.2091 73.8097 18Z"
                      fill=""/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M74.307 46C74.307 43.7909 75.9457 42 77.9671 42H162.15C164.171 42 165.81 43.7909 165.81 46C165.81 48.2091 164.171 50 162.15 50H77.9671C75.9457 50 74.307 48.2091 74.307 46Z"
                      fill=""/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M74.307 74C74.307 71.7909 75.9457 70 77.9671 70H162.15C164.171 70 165.81 71.7909 165.81 74C165.81 76.2091 164.171 78 162.15 78H77.9671C75.9457 78 74.307 76.2091 74.307 74Z"
                      fill=""/>
            </svg>

        )
    }
    static notification = (classname, onclick) => {
        return (
            <svg className={classname} onClick={onclick} width="24" height="24" viewBox="0 0 92 92" fill="#424445"
                 xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M13.1958 32.3116C13.1958 14.4664 27.6622 0 45.5075 0C63.3527 0 77.8191 14.4664 77.8191 32.3117V53.841C77.8191 54.028 77.8715 54.2112 77.9704 54.3698L84.6565 65.1025C86.7313 68.433 84.3365 72.7463 80.4126 72.7463H10.6023C6.6784 72.7463 4.28364 68.433 6.35845 65.1025L13.0446 54.3698C13.1434 54.2112 13.1958 54.028 13.1958 53.841V32.3116ZM45.5075 6C30.976 6 19.1958 17.7801 19.1958 32.3116V53.841C19.1958 55.1495 18.8291 56.4318 18.1372 57.5424L12.4035 66.7463H78.6115L72.8777 57.5424C72.1859 56.4318 71.8191 55.1495 71.8191 53.841V32.3117C71.8191 17.7801 60.039 6 45.5075 6Z"/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M31.3881 71.2388C33.0449 71.2388 34.3881 72.5819 34.3881 74.2388C34.3881 80.7343 39.6537 86 46.1493 86C52.6448 86 57.9104 80.7343 57.9104 74.2388C57.9104 72.5819 59.2536 71.2388 60.9104 71.2388C62.5673 71.2388 63.9104 72.5819 63.9104 74.2388C63.9104 84.048 55.9585 92 46.1493 92C36.34 92 28.3881 84.048 28.3881 74.2388C28.3881 72.5819 29.7312 71.2388 31.3881 71.2388Z"/>
            </svg>

        )
    }

    static padlock = (classname, onclick) => {
        return (
            <svg className={classname} onClick={onclick} width="24" height="24" viewBox="0 0 92 92" fill="#424445"
                 xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M46 7C36.6198 7 29 14.6298 29 24.061V35H23V24.061C23 11.3334 33.2888 1 46 1C58.7112 1 69 11.3334 69 24.061V35H63V24.061C63 14.6298 55.3802 7 46 7Z"
                      fill=""/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M74 41H18C16.8954 41 16 41.8954 16 43V84C16 85.1046 16.8954 86 18 86H74C75.1046 86 76 85.1046 76 84V43C76 41.8954 75.1046 41 74 41ZM18 35C13.5817 35 10 38.5817 10 43V84C10 88.4183 13.5817 92 18 92H74C78.4183 92 82 88.4183 82 84V43C82 38.5817 78.4183 35 74 35H18Z"
                      fill=""/>
                <path
                    d="M53 58.5C53 54.3579 49.6421 51 45.5 51C41.3579 51 38 54.3579 38 58.5C38 60.7178 38.9626 62.7107 40.4928 64.0838C40.7895 64.35 40.9398 64.7515 40.8467 65.1391L38.592 74.5332C38.2902 75.7909 39.2434 77 40.5368 77H50.4632C51.7566 77 52.7098 75.7909 52.408 74.5332L50.1533 65.1391C50.0602 64.7515 50.2105 64.35 50.5072 64.0838C52.0374 62.7107 53 60.7178 53 58.5Z"
                    fill=""/>
            </svg>


        )
    }

    static parties = (classname, onclick) => {
        return (
            <svg className={classname} onClick={onclick} width="24" height="24" viewBox="0 0 94 92" fill="#424445"
                 xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M64.758 88.2201L61.97 81.9182C77.515 75.0264 84.252 61.0934 86 56C81.4 50.8166 76.837 49.0161 70.737 49.0161C66.3266 49.0352 62.0144 50.3066 58.3123 52.6793C54.6103 55.052 51.674 58.4264 49.852 62.4018L43.593 59.5186C45.9603 54.3529 49.7755 49.968 54.5858 46.8846C59.396 43.8012 64.9991 42.1487 70.73 42.1234H70.736C80.916 42.1234 86.411 46.8337 90.836 51.9221C91.769 53.0168 93.562 55.065 93 57C91.92 60.7166 85.5 79 64.758 88.2201Z"
                    fill=""/>
                <path
                    d="M44.9 92C44.6853 92 44.47 92 44.254 92C36.1866 91.7056 28.023 89.4404 21 85.5C14.948 82.0972 9.99997 77.5 6.49997 73C5.87597 72.2537 1.66663 66 0.999975 64C0.333318 62 2.5 60.5 4.49998 59C6.49996 57.5 17.5991 51.502 26.564 51.5133C36.1731 51.4659 45.5009 54.7211 52.955 60.7232C60.3003 66.7422 65.2012 75.1852 66.755 84.4975L67.023 86.0891L65.966 87.3303C65.624 87.7262 64.715 88.7902 58.28 90.3471C53.9007 91.4146 49.41 91.9694 44.9 92ZM8.59999 63.8548C11.266 67.7684 17.5 75.5143 24.657 79.5388C30.6827 82.9114 37.4316 84.8189 44.348 85.1043C49.4443 85.1207 54.5142 84.3794 59.388 82.905C57.6841 76.304 53.9074 70.4087 48.602 66.0679C42.374 61.0652 34.5848 58.3571 26.564 58.4061C20.1571 58.3888 13.8945 60.2887 8.59799 63.8568L8.59999 63.8548Z"
                    fill=""/>
                <path
                    d="M69.347 39.4569C66.0829 39.4934 62.8816 38.5688 60.1497 36.8004C57.4178 35.0321 55.2787 32.4999 54.0041 29.5255C52.7294 26.5512 52.3768 23.2689 52.9911 20.0958C53.6054 16.9227 55.1588 14.0019 57.4539 11.7045C59.7491 9.40717 62.6824 7.83693 65.8812 7.19332C69.08 6.5497 72.3999 6.86177 75.4191 8.08985C78.4384 9.31794 81.0206 11.4066 82.8378 14.0905C84.655 16.7744 85.6251 19.9324 85.625 23.1633C85.6444 27.459 83.9415 31.5869 80.8899 34.6415C77.8382 37.6961 73.687 39.4279 69.347 39.4569ZM69.347 13.7614C67.4636 13.7404 65.6165 14.274 64.0402 15.2943C62.4639 16.3147 61.2297 17.7758 60.4942 19.492C59.7587 21.2082 59.5553 23.102 59.9096 24.9329C60.264 26.7638 61.1603 28.4491 62.4845 29.7747C63.8088 31.1004 65.5012 32.0065 67.3469 32.3779C69.1926 32.7494 71.1082 32.5695 72.8503 31.861C74.5925 31.1526 76.0825 29.9475 77.1312 28.399C78.1799 26.8505 78.7398 25.0285 78.74 23.1643C78.7517 20.6853 77.7692 18.3029 76.0082 16.5401C74.2472 14.7772 71.8516 13.7779 69.347 13.7614Z"
                    fill=""/>
                <path
                    d="M26.175 49.0518C21.8631 49.1006 17.6338 47.8797 14.0246 45.5442C10.4153 43.2086 7.589 39.8638 5.90468 35.9348C4.22036 32.0058 3.75409 27.6698 4.56511 23.4779C5.37613 19.286 7.42783 15.4274 10.4595 12.3922C13.4912 9.35701 17.3661 7.28235 21.5918 6.43179C25.8175 5.58124 30.2032 5.99318 34.1918 7.61529C38.1804 9.23739 41.5918 11.9964 43.9926 15.5419C46.3933 19.0873 47.6751 23.2591 47.675 27.5272C47.7005 33.2013 45.4514 38.6538 41.421 42.6889C37.3905 46.724 31.9076 49.0122 26.175 49.0518ZM26.175 12.8934C23.2439 12.8604 20.369 13.6905 17.9156 15.2784C15.4622 16.8662 13.5411 19.14 12.3962 21.8109C11.2514 24.4818 10.9345 27.4292 11.4859 30.2788C12.0373 33.1283 13.432 35.7513 15.4928 37.8146C17.5537 39.8778 20.1877 41.2882 23.0602 41.8664C25.9327 42.4447 28.9141 42.1647 31.6255 41.0622C34.3369 39.9597 36.656 38.0843 38.2881 35.6743C39.9203 33.2643 40.7918 30.4285 40.792 27.5272C40.809 23.6699 39.2797 19.9634 36.5396 17.2207C33.7994 14.4779 30.072 12.9228 26.175 12.8963V12.8934Z"
                    fill=""/>
            </svg>

        )
    };

    static pen = (classname, onclick) => {
        return <svg onClick={onclick} className={classname} width="24" height="24" viewBox="0 0 92 92" fill="#424445"
                    xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M73.8358 0.23584C76.245 0.23584 78.5103 1.16757 80.2139 2.85926L89.1415 11.7242C92.6584 15.2165 92.6584 20.8989 89.1415 24.391L30.4057 82.715C29.217 83.8954 27.7647 84.7472 26.1646 85.2097L11.3524 91.5732C10.329 92.0129 9.24884 92.2358 8.14205 92.2358C5.53564 92.2358 3.07507 90.97 1.55994 88.8495C0.0524109 86.7394 -0.332655 84.0185 0.530097 81.5712L6.12938 65.6846C6.57278 63.9814 7.46159 62.4348 8.72213 61.1834L49.5068 20.6843L63.2432 7.04418L67.4577 2.85926C69.1615 1.16757 71.4266 0.23584 73.8358 0.23584ZM12.3395 68.5223L6.80333 84.3406C6.46233 85.315 7.22145 86.2389 8.14207 86.2389C8.32839 86.2389 8.52139 86.2011 8.71315 86.1184L23.5264 79.7092L12.3395 68.5223ZM73.8359 6.88232C73.2284 6.88232 72.621 7.11395 72.1573 7.57752L16.4757 63.2592L28.7599 75.5435L84.4417 19.8619C85.3688 18.9349 85.3688 17.4319 84.4417 16.5049L75.5142 7.57752C75.0508 7.11395 74.4434 6.88232 73.8359 6.88232Z"
                      fill=""/>
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="92" height="92" fill="white"/>
                </clipPath>
            </defs>
        </svg>

    }

    static plus = (classname, onclick) => {
        return <svg className={classname} onClick={onclick} width="24" height="24" viewBox="0 0 94 94" fill="#424445"
                    xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M47 0.68C49.2091 0.68 51 2.47086 51 4.68V89.32C51 91.5291 49.2091 93.32 47 93.32C44.7909 93.32 43 91.5291 43 89.32V4.68C43 2.47086 44.7909 0.68 47 0.68Z"
                  fill=""/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M0.679993 47C0.679993 44.7909 2.47085 43 4.67999 43H89.32C91.5291 43 93.32 44.7909 93.32 47C93.32 49.2091 91.5291 51 89.32 51H4.67999C2.47085 51 0.679993 49.2091 0.679993 47Z"
                  fill=""/>
        </svg>

    };


    static search = (classname, onclick) => {
        return (
            <svg className={classname} onClick={onclick} width="24" height="24" viewBox="0 0 93 92" fill="#424445"
                 xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M68.7471 56.7708C73.3916 49.6393 75.205 41.0302 73.8318 32.6312C72.4587 24.2322 67.9979 16.6489 61.3241 11.3678C54.6502 6.08683 46.2445 3.48895 37.7548 4.08345C29.265 4.67795 21.3034 8.42198 15.4307 14.5816C9.55799 20.7412 6.19766 28.8722 6.00845 37.3806C5.81923 45.889 8.81477 54.1614 14.4078 60.576C20.0009 66.9905 27.7882 71.0848 36.2431 72.0561C44.6981 73.0275 53.211 70.8058 60.113 65.8267C61.7913 64.5351 63.3925 63.1463 64.9084 61.6674C66.3732 60.0905 68.7471 56.7708 68.7471 56.7708ZM14.2617 38.1657C14.2617 33.0427 15.7809 28.0347 18.6271 23.7751C21.4733 19.5155 25.5187 16.1955 30.2517 14.235C34.9848 12.2745 40.1929 11.7616 45.2175 12.761C50.242 13.7605 54.8574 16.2274 58.4799 19.8499C62.1024 23.4725 64.5694 28.0878 65.5689 33.1124C66.5683 38.137 66.0554 43.3451 64.0949 48.0781C62.1344 52.8112 58.8144 56.8566 54.5548 59.7028C50.2951 62.549 45.2872 64.0681 40.1642 64.0681C33.2944 64.0681 26.706 61.3391 21.8484 56.4815C16.9907 51.6238 14.2617 45.0355 14.2617 38.1657Z"
                    fill=""/>
                <path
                    d="M84.0262 77.6062L67.5078 59.3646C65.62 57.2798 62.6489 56.8945 60.8716 58.5039L60.8709 58.5045C59.0936 60.1139 59.1832 63.1086 61.071 65.1934L77.5894 83.435C79.4772 85.5197 82.4483 85.9051 84.2256 84.2957L84.2263 84.295C86.0036 82.6856 85.914 79.6909 84.0262 77.6062Z"
                    fill=""/>
            </svg>

        )
    };

    static send = (classname, onclick) => {
        return (
            <svg className={classname} onClick={onclick} width="24" height="24" viewBox="0 0 93 92" fill="#424445"
                 xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M92.0794 16.7347C92.6415 17.5386 92.6781 18.598 92.1727 19.4387L54.639 81.8832C54.2616 82.511 53.6264 82.9404 52.9031 83.0565C52.1797 83.1726 51.4421 82.9636 50.8871 82.4853L31.5456 65.817L20.6268 71.664C19.869 72.0698 18.9553 72.0551 18.2109 71.6252C17.4666 71.1953 16.9973 70.4111 16.9702 69.552L16.4053 51.6768L1.62256 36.6382C0.980778 35.9853 0.749989 35.0324 1.02192 34.1582C1.29386 33.284 2.02445 32.6302 2.92335 32.4565L89.5717 15.7188C90.5348 15.5328 91.5173 15.9308 92.0794 16.7347ZM21.3976 52.3138L21.8109 65.3925L27.5111 62.3401L26.3757 61.3616C25.8099 60.874 25.4935 60.1578 25.5139 59.4112C25.5344 58.6646 25.8896 57.9668 26.4812 57.511L44.9929 43.2474L21.3976 52.3138ZM31.9292 59.587L51.8983 76.7962L85.042 21.6554L8.41367 36.4576L19.488 47.7236L59.1141 32.4975C60.2658 32.055 61.5678 32.5245 62.172 33.6003C62.7761 34.6761 62.4993 36.0323 61.522 36.7854L31.9292 59.587Z"
                      fill=""/>
            </svg>
        )
    };

    static share = (classname, onclick) => {
        return <svg className={classname} onClick={onclick} width="24" height="24" viewBox="0 0 92 92" fill="#424445"
                    xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M10 46C11.6569 46 13 47.3431 13 49V86H79V49C79 47.3431 80.3431 46 82 46C83.6569 46 85 47.3431 85 49V89C85 90.6569 83.6569 92 82 92H10C8.34315 92 7 90.6569 7 89V49C7 47.3431 8.34315 46 10 46Z"
                  fill=""/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M19.1354 29.5922C18.0563 28.513 18.0563 26.7633 19.1354 25.6842L43.069 1.33257C44.6877 -0.286185 47.3122 -0.286183 48.931 1.33257L73.2826 25.6842C74.3618 26.7633 74.3618 28.513 73.2826 29.5922C72.2035 30.6714 70.4538 30.6714 69.3746 29.5922L46 6.21759L23.0434 29.5922C21.9643 30.6713 20.2146 30.6713 19.1354 29.5922Z"
                  fill=""/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M46 64C44.4632 64 43.2174 62.86 43.2174 61.4538L43.2174 2.89211C43.2174 1.4859 44.4632 0.345947 46 0.345947C47.5368 0.345947 48.7826 1.4859 48.7826 2.89211V61.4538C48.7826 62.86 47.5368 64 46 64Z"
                  fill=""/>
        </svg>
    };

    static sign = (classname, onclick) => {
        return <svg className={classname} onClick={onclick} width="24" height="24" viewBox="0 0 92 92" fill="#424445"
                    xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M9.67729 31.1286C8.81065 31.9805 7.88934 32.8863 7.00689 33.6805C5.77536 34.7888 3.87849 34.689 2.77011 33.4575C1.66174 32.226 1.76157 30.3291 2.9931 29.2207C3.78033 28.5122 4.53187 27.7728 5.33875 26.979C5.76725 26.5574 6.21135 26.1204 6.6847 25.6646C7.97241 24.4246 9.41334 23.1111 10.9609 22.0794C12.5091 21.0473 14.3605 20.1621 16.4701 20.0199C18.666 19.8718 20.8005 20.5509 22.8 22.0506C25.3379 23.9541 25.9353 27.1114 25.9156 29.8882C25.8951 32.7858 25.2062 36.1851 24.1982 39.7018C22.5579 45.4245 19.9212 51.9602 17.2313 58.0839L42.9707 34.9844C44.5499 33.5672 46.5315 33.8421 47.7296 34.5164C48.9162 35.1842 50.1023 36.6503 49.8855 38.6162C49.708 40.2251 49.6528 41.9861 49.7361 43.5436C49.8241 45.1893 50.0518 46.2891 50.2574 46.7688C50.851 48.1538 51.5148 48.9294 52.1331 49.3646C52.725 49.7813 53.4833 50.0345 54.5673 50.0136C56.9311 49.9682 60.4551 48.6267 65.365 45.4353C75.6971 38.7195 82.5408 35.9506 87.5 35.9506C89.1568 35.9506 90.5 37.2937 90.5 38.9506C90.5 40.6075 89.1568 41.9506 87.5 41.9506C84.4592 41.9506 78.9259 43.7768 68.635 50.4659C63.5449 53.7745 58.8189 55.933 54.6827 56.0125C52.5167 56.0542 50.4625 55.5262 48.6794 54.2709C46.9227 53.0343 45.649 51.2474 44.7426 49.1324C44.1065 47.6482 43.8416 45.678 43.7447 43.8639C43.719 43.3833 43.7041 42.8908 43.6996 42.3922L17.7832 65.6505C13.6984 69.3163 7.65785 64.7181 9.87537 59.8359C13.1563 52.6124 16.5371 44.6544 18.4305 38.0486C19.3821 34.7287 19.9009 31.9393 19.9158 29.8457C19.9315 27.6312 19.3919 26.9945 19.2 26.8506C18.1995 26.1002 17.459 25.9668 16.8737 26.0063C16.202 26.0516 15.3659 26.3539 14.2891 27.0717C13.2117 27.79 12.0901 28.7891 10.8465 29.9865C10.4751 30.3442 10.0822 30.7305 9.67729 31.1286Z"
                  fill=""/>
        </svg>

    }

    static signOut = (classname, onclick) => {
        return (
            <svg className={classname} onClick={onclick} width="24" height="24" viewBox="0 0 93 92" fill="#424445"
                 xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M4.5 22C4.5 16.4772 8.97715 12 14.5 12H39C40.6569 12 42 13.3431 42 15C42 16.6569 40.6569 18 39 18H14.5C12.2909 18 10.5 19.7909 10.5 22V69.5C10.5 71.7091 12.2909 73.5 14.5 73.5H39C40.6569 73.5 42 74.8431 42 76.5C42 78.1569 40.6569 79.5 39 79.5H14.5C8.97715 79.5 4.5 75.0228 4.5 69.5V22Z"
                      fill=""/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M59.2859 18.8094C60.3651 17.7302 62.1147 17.7302 63.1939 18.8094L87.5455 42.7429C89.1643 44.3617 89.1642 46.9862 87.5455 48.6049L63.1939 72.9566C62.1147 74.0357 60.365 74.0357 59.2859 72.9566C58.2067 71.8774 58.2067 70.1277 59.2859 69.0486L82.6605 45.6739L59.2859 22.7174C58.2067 21.6382 58.2067 19.8885 59.2859 18.8094Z"
                      fill=""/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M25 45.7826C25 44.2458 26.14 43 27.5462 43H86.1079C87.5141 43 88.6541 44.2458 88.6541 45.7826C88.6541 47.3194 87.5141 48.5652 86.1079 48.5652H27.5462C26.14 48.5652 25 47.3194 25 45.7826Z"
                      fill=""/>
            </svg>

        )
    };

    static spinner = (classname, onclick) => {
        const ua = window.navigator.userAgent;
        const isIE = /MSIE|Trident/.test(ua);

        if (isIE) {
            return <div className="spinner"/>
        } else
            return <svg className={classname} onClick={onclick} fill="#fff" width="16px" height="16px"
                        viewBox="0 0 50 50">
                <path
                    d="M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z"
                    fill="">
                    <animateTransform attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="1.25s"
                                      repeatCount="indefinite"/>
                </path>
            </svg>
    }

    static support = (classname, onclick) => {
        return <svg className={classname} onClick={onclick} width="24" height="24" viewBox="0 0 92 92" fill="#424445"
                    xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M46 86C68.0914 86 86 68.0914 86 46C86 23.9086 68.0914 6 46 6C23.9086 6 6 23.9086 6 46C6 68.0914 23.9086 86 46 86ZM46 92C71.4051 92 92 71.4051 92 46C92 20.5949 71.4051 0 46 0C20.5949 0 0 20.5949 0 46C0 71.4051 20.5949 92 46 92Z"
                  fill=""/>
            <circle cx="46" cy="71" r="5" fill=""/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M37.0467 28.8557C35.4599 31.0038 35 33.5753 35 34.8323C35 37.0415 33.2091 38.8323 31 38.8323C28.7909 38.8323 27 37.0415 27 34.8323C27 32.257 27.7976 27.9123 30.612 24.1024C33.5862 20.0761 38.5662 17 46.0898 17C52.7235 17 57.5242 19.2742 60.602 22.8664C63.5722 26.3333 64.5509 30.5758 64.5509 33.9461C64.5509 37.6582 63.0281 40.7843 60.8329 43.4872C58.6951 46.1196 55.7525 48.5573 52.5176 50.9801C50.5422 52.4595 50.1828 53.5101 50.052 54.192C49.9634 54.6539 49.943 55.189 49.9734 55.9529C49.9803 56.1265 49.9935 56.3604 50.0086 56.6282C50.044 57.2547 50.0898 58.0666 50.0898 58.7245C50.0898 60.9337 48.299 62.7245 46.0898 62.7245C43.8807 62.7245 42.0898 60.9337 42.0898 58.7245C42.0898 58.2636 42.0683 57.9063 42.041 57.4545C42.0216 57.132 41.9992 56.7614 41.9797 56.2706C41.9402 55.2762 41.9367 54.0328 42.1952 52.685C42.7586 49.7476 44.4142 47.0541 47.7219 44.5768C50.8672 42.2211 53.1402 40.2696 54.6229 38.4439C56.0483 36.6888 56.5509 35.2879 56.5509 33.9461C56.5509 31.9721 55.9577 29.7416 54.5268 28.0714C53.2033 26.5267 50.7735 25 46.0898 25C41.0386 25 38.4737 26.9239 37.0467 28.8557Z"
                  fill=""/>
        </svg>

    }

    static thumbdown = (classname, onclick) => {
        return (
            <svg className={classname} onClick={onclick} width="24" height="24" viewBox="0 0 92 92" fill="#424445" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M16 58H24C29.5228 58 34 53.5228 34 48V14C34 8.47715 29.5228 4 24 4H16C10.4772 4 6 8.47715 6 14V48C6 53.5228 10.4772 58 16 58ZM16 52C13.7909 52 12 50.2091 12 48V14C12 11.7909 13.7909 10 16 10H24C26.2091 10 28 11.7909 28 14V48C28 50.2091 26.2091 52 24 52H16Z" fill=""/>
                <path fillRule="evenodd" clipRule="evenodd" d="M53.3402 81.8206C54.5486 82.1267 55.5851 82.1416 56.1956 81.8715C56.422 81.7714 56.6328 81.6163 56.8294 81.2787C57.0483 80.9026 57.3168 80.176 57.3755 78.8529C57.6143 73.4641 55.6472 63.9503 53.834 56.8696C52.7039 52.4565 55.9698 47.9674 60.6739 47.9674H77.7527C80.4899 47.9674 82.5251 45.8456 82.4341 43.3416C82.2074 37.0981 81.4864 28.3394 79.418 21.2226C78.3803 17.652 77.0689 14.7321 75.4929 12.7603C73.974 10.86 72.3612 10.0001 70.5 10.0001H38.5C37.2432 10.0001 35.9886 10.7703 34.8364 12.0273C34.2959 12.6169 33.8679 13.2191 33.5749 13.6777C33.4303 13.9041 33.3235 14.0878 33.2567 14.2071C33.2234 14.2666 33.2004 14.3095 33.188 14.333L33.1803 14.3477L33.1824 14.3434C33.1828 14.3426 33.1832 14.3417 30.5 13.0001C27.8167 11.6584 27.8171 11.6575 27.8176 11.6566L27.8186 11.6547L27.8207 11.6503L27.826 11.6399L27.8399 11.6126C27.8508 11.5915 27.8647 11.5646 27.8817 11.5324C27.9157 11.468 27.9621 11.382 28.0206 11.2774C28.1374 11.0686 28.304 10.7836 28.5187 10.4474C28.9445 9.78106 29.579 8.88321 30.4135 7.9729C32.0113 6.22986 34.7567 4.00008 38.5 4.00008H70.5C74.6448 4.00008 77.8443 6.09221 80.1798 9.01425C82.4581 11.8648 84.0431 15.6376 85.1796 19.5481C87.4598 27.3936 88.1988 36.7512 88.4302 43.1238C88.6528 49.2539 83.6552 53.9674 77.7527 53.9674H60.6739C60.0353 53.9674 59.4512 54.6185 59.6465 55.3812C61.4339 62.3611 63.6536 72.7087 63.3696 79.1186C63.1877 83.2234 61.521 86.0766 58.6229 87.3586C56.1093 88.4705 53.4031 88.0299 51.8313 87.6278C49.0877 86.926 47.6403 84.5682 47.233 82.4773C46.7743 80.1224 45.9096 76.4764 44.6375 73.8129C41.0577 66.3178 35.57 61.2763 30.4787 56.599C29.202 55.4261 27.9502 54.2761 26.7596 53.116L30.9469 48.8187C31.9291 49.7758 33.0324 50.785 34.2105 51.8625C39.3519 56.5654 45.9172 62.5707 50.0517 71.227C51.6705 74.6164 52.6478 78.8941 53.1223 81.3302C53.1679 81.5642 53.2544 81.7181 53.3132 81.7906C53.3251 81.8052 53.3341 81.8147 53.3402 81.8206Z" fill=""/>
            </svg>

        )
    }

    static thumbup = (classname, onclick) => {
        return (
            <svg className={classname} onClick={onclick} width="24" height="24" viewBox="0 0 92 92" fill="#424445" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M16 34H24C29.5228 34 34 38.4772 34 44V78C34 83.5229 29.5228 88 24 88H16C10.4772 88 6 83.5228 6 78V44C6 38.4772 10.4772 34 16 34ZM16 40C13.7909 40 12 41.7909 12 44V78C12 80.2091 13.7909 82 16 82H24C26.2091 82 28 80.2091 28 78V44C28 41.7909 26.2091 40 24 40H16Z" fill=""/>
                <path fillRule="evenodd" clipRule="evenodd" d="M53.3402 10.1795C54.5486 9.87342 55.5851 9.85855 56.1956 10.1286C56.422 10.2288 56.6328 10.3838 56.8294 10.7214C57.0483 11.0975 57.3168 11.8242 57.3755 13.1472C57.6143 18.536 55.6472 28.0498 53.834 35.1305C52.7039 39.5437 55.9698 44.0328 60.6739 44.0328H77.7527C80.4899 44.0328 82.5251 46.1546 82.4341 48.6586C82.2074 54.902 81.4864 63.6607 79.418 70.7775C78.3803 74.3481 77.0689 77.268 75.4929 79.2398C73.974 81.1401 72.3612 82 70.5 82H38.5C37.2432 82 35.9886 81.2298 34.8364 79.9729C34.2959 79.3832 33.8679 78.781 33.5749 78.3224C33.4303 78.096 33.3235 77.9123 33.2567 77.793C33.2234 77.7335 33.2004 77.6907 33.188 77.6672L33.1803 77.6525L33.1824 77.6567C33.1828 77.6576 33.1832 77.6584 30.5 79C27.8167 80.3417 27.8171 80.3426 27.8176 80.3435L27.8186 80.3455L27.8207 80.3498L27.826 80.3602L27.8399 80.3875C27.8508 80.4086 27.8647 80.4355 27.8817 80.4677C27.9157 80.5321 27.9621 80.6181 28.0206 80.7227C28.1374 80.9315 28.304 81.2166 28.5187 81.5527C28.9445 82.2191 29.579 83.1169 30.4135 84.0272C32.0113 85.7703 34.7567 88 38.5 88H70.5C74.6448 88 77.8443 85.9079 80.1798 82.9859C82.4581 80.1353 84.0431 76.3625 85.1796 72.452C87.4598 64.6065 88.1988 55.2489 88.4302 48.8763C88.6528 42.7463 83.6552 38.0328 77.7527 38.0328H60.6739C60.0353 38.0328 59.4512 37.3816 59.6465 36.619C61.4339 29.639 63.6536 19.2914 63.3696 12.8815C63.1877 8.77672 61.521 5.92355 58.6229 4.64152C56.1093 3.52959 53.4031 3.97024 51.8313 4.37229C49.0877 5.07408 47.6403 7.43192 47.233 9.52279C46.7743 11.8777 45.9096 15.5238 44.6375 18.1872C41.0577 25.6823 35.57 30.7238 30.4787 35.4011C29.202 36.574 27.9502 37.7241 26.7596 38.8841L30.9469 43.1814C31.9291 42.2244 33.0324 41.2152 34.2105 40.1376C39.3519 35.4347 45.9172 29.4295 50.0517 20.7731C51.6705 17.3837 52.6478 13.106 53.1223 10.6699C53.1679 10.436 53.2544 10.282 53.3132 10.2095C53.3251 10.1949 53.3341 10.1854 53.3402 10.1795Z" fill=""/>
            </svg>
        )
    }

    static tiles = (classname, onclick) => {
        return (
            <svg className={classname} onClick={onclick} width="24" height="24" viewBox="0 0 92 92" fill="#424445"
                 xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M8 8V36H36V8H8ZM6 2C3.79086 2 2 3.79086 2 6V38C2 40.2091 3.79086 42 6 42H38C40.2091 42 42 40.2091 42 38V6C42 3.79086 40.2091 2 38 2H6Z"
                      fill=""/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M56 8V36H84V8H56ZM54 2C51.7909 2 50 3.79086 50 6V38C50 40.2091 51.7909 42 54 42H86C88.2091 42 90 40.2091 90 38V6C90 3.79086 88.2091 2 86 2H54Z"
                      fill=""/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M8 56V84H36V56H8ZM6 50C3.79086 50 2 51.7909 2 54V86C2 88.2091 3.79086 90 6 90H38C40.2091 90 42 88.2091 42 86V54C42 51.7909 40.2091 50 38 50H6Z"
                      fill=""/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M56 56V84H84V56H56ZM54 50C51.7909 50 50 51.7909 50 54V86C50 88.2091 51.7909 90 54 90H86C88.2091 90 90 88.2091 90 86V54C90 51.7909 88.2091 50 86 50H54Z"
                      fill=""/>
            </svg>

        )
    };

    static check = (classname, onclick) => {
        return <svg className={classname} onClick={onclick} width="24" height="24" viewBox="0 0 92 92" fill="#424445"
                    xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M89.4712 15.258C91.0281 16.8253 91.0196 19.358 89.4522 20.9148L34.2223 75.7753C31.8305 78.1511 27.9695 78.1511 25.5776 75.7753L2.29216 52.6456C0.724834 51.0887 0.716335 48.5561 2.27318 46.9888C3.83003 45.4214 6.36267 45.4129 7.93 46.9698L29.9 68.7928L83.8144 15.239C85.3817 13.6822 87.9144 13.6907 89.4712 15.258Z"
                  fill=""/>
        </svg>
    }

    static user = (classname, onclick) => {
        return (
            <svg className={classname} onClick={onclick} width="24" height="24" viewBox="0 0 92 94" fill="#424445"
                 xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M46.433 93.9999C33.0124 94.0248 20.0351 89.2004 9.89235 80.4158C7.27265 78.1418 6.8074 75.5599 8.47772 72.5234C16.5003 57.9474 27.175 50.1922 44.3383 49.4737H48.7981C66.1929 50.3116 76.8812 58.2721 84.7079 73.1288C85.3981 74.2288 85.6493 75.548 85.4117 76.8245C85.1741 78.101 84.465 79.2416 83.4253 80.0199C73.2226 89.0497 60.0602 94.024 46.433 93.9999ZM14.6424 75.6699C14.6969 75.7233 14.7639 75.7851 14.8467 75.8563C23.9759 83.7718 35.0708 86.7622 46.5367 86.9392C57.945 87.1172 68.6711 84.0588 78.2278 75.6762C71.3809 62.8693 61.7195 57.3137 46.5493 56.5438C31.4734 57.2111 21.7806 62.6881 14.7608 75.4416C14.7116 75.5285 14.6738 75.6081 14.6424 75.6678V75.6699Z"
                    fill=''/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M46.7935 7.64227C37.4398 7.64227 29.8598 15.2212 29.8598 24.5671C29.8598 33.9131 37.4398 41.492 46.7935 41.492C56.1471 41.492 63.7271 33.9131 63.7271 24.5671C63.7271 15.2212 56.1471 7.64227 46.7935 7.64227ZM22.8598 24.5671C22.8598 11.3524 33.5767 0.642273 46.7935 0.642273C60.0102 0.642273 70.7271 11.3524 70.7271 24.5671C70.7271 37.7819 60.0102 48.492 46.7935 48.492C33.5767 48.492 22.8598 37.7819 22.8598 24.5671Z"
                      fill=""/>
            </svg>

        )
    };

    static workflow = (classname, onclick) => {
        return <svg className={classname} onClick={onclick} width="24" height="24" viewBox="0 0 92 92" fill="#424445"
                    xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M75 20H32V14H78C79.6569 14 81 15.3431 81 17V45.4827C81 47.1396 79.6569 48.4827 78 48.4827H16V72H60V78H13C11.3431 78 10 76.6569 10 75V45.4827C10 43.8259 11.3431 42.4827 13 42.4827H75V20Z"
                  fill=""/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M5.8 5.8V26.2H26.2V5.8H5.8ZM3.2 0C1.43269 0 0 1.43269 0 3.2V28.8C0 30.5673 1.43269 32 3.2 32H28.8C30.5673 32 32 30.5673 32 28.8V3.2C32 1.43269 30.5673 0 28.8 0H3.2Z"
                  fill=""/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M65.8 65.8V86.2H86.2V65.8H65.8ZM63.2 60C61.4327 60 60 61.4327 60 63.2V88.8C60 90.5673 61.4327 92 63.2 92H88.8C90.5673 92 92 90.5673 92 88.8V63.2C92 61.4327 90.5673 60 88.8 60H63.2Z"
                  fill=""/>
        </svg>
    }

    static drawIcon = (classname, onclick) => {
        return (
            <svg className={classname} onClick={onclick} viewBox="0 0 333.49 256.63">
                <defs>
                    <clipPath id="a" transform="translate(24.2 3.89)">
                        <path
                            d="M28.14,198.84q7.29-6.26,14.58-12.51A61.18,61.18,0,0,1,53.35,179c3.83-2.08,7.94-1.77,10.37.65a7.09,7.09,0,0,1,2.14,4.49,34.14,34.14,0,0,1-.14,8.45c-1.12,6.59,1.18,8.71,7.84,7A68.65,68.65,0,0,0,88.4,193.5c6.55-3.45,12.95-7.23,20.16-9.31,1.18-.34,2.42-.51,3.63-.74a3,3,0,0,1,3.93,3c.08,1.61-.06,3.23,0,4.84a2.86,2.86,0,0,1-2.48,3.24c-7.82,1.38-14.17,5.81-20.94,9.39-6.45,3.41-13,6.59-20.39,7.5-10.18,1.26-18.29-3.91-18.42-14.76,0-1.21,0-2.42,0-3.79-1.94.61-3.1,2.09-4.45,3.21-5.82,4.84-11.36,10-17,15.06s-10.79,10.61-16.55,15.53c-2.64,2.24-5.27,2.45-7.54.9S5.26,223.06,6.51,220c2.49-6.11,5-12.21,7.54-18.31,3.15-7.67,6.32-15.32,9.45-23a5.66,5.66,0,0,0,.75-3.08,4,4,0,0,0-6.2-2.84c-3.18,1.9-6.23,4-9.34,6-2.72,1.74-4.05,1.48-5.84-1.16-.8-1.18-1.54-2.39-2.31-3.59a2.45,2.45,0,0,1,.5-3.61c4.58-3.22,8.92-6.93,14.32-8.75A15.52,15.52,0,0,1,36,175.07a16.75,16.75,0,0,1-1.63,7.62c-2.22,5.32-4.41,10.66-6.61,16Z"
                            clipRule="evenodd"/>
                    </clipPath>
                    <clipPath id="b" transform="translate(24.2 3.89)">
                        <rect x="-822.83" y="-1470.36" width="4946.64" height="5106.37"/>
                    </clipPath>
                    <clipPath id="c" transform="translate(24.2 3.89)">
                        <rect x="176" y="20.31" width="109.09" height="106.18"/>
                    </clipPath>
                    <clipPath id="d" transform="translate(24.2 3.89)">
                        <rect x="-822.83" y="-1475.36" width="4946.64" height="5106.37"/>
                    </clipPath>
                    <clipPath id="e" transform="translate(24.2 3.89)">
                        <rect x="174.25" y="15.41" width="111.32" height="116.16"/>
                    </clipPath>
                    <clipPath id="f" transform="translate(24.2 3.89)">
                        <path
                            d="M285.08,50.47a20.67,20.67,0,0,1-6,12.8c-9.87,10-20.76,19-31.21,28.5-8.47,7.67-17,15.28-25.5,22.92-3.87,3.48-7.79,6.91-11.61,10.44-1.92,1.77-3.26,1.91-5.33-.16q-13.71-13.74-27.85-27.08c-2.35-2.21-1.79-3.61,0-5.48,5.48-5.8,10.83-11.72,16.4-17.44,15.21-15.62,29.72-31.86,45.14-47.3,12.2-12.21,29.56-7.66,38.47,2.23,4.82,5.36,7.69,11.47,7.53,20.57"
                            clipRule="evenodd"/>
                    </clipPath>
                    <clipPath id="g" transform="translate(24.2 3.89)">
                        <rect x="174.25" y="20.25" width="111.32" height="106.48"/>
                    </clipPath>
                    <clipPath id="h" transform="translate(24.2 3.89)">
                        <path
                            d="M169.63,146.87a10.94,10.94,0,0,0-11.51-10.67c-5.66.36-10.57,6-9.64,11.5.33,2-.53,2.81-1.59,3.86q-13.38,13.38-26.8,26.71c-.81.8-1.45,2.54-3,1.68-1.8-1-.49-2.56-.1-3.73q8.07-24.22,16.26-48.41c.49-1.44.95-2.9,1.32-4.37a14.8,14.8,0,0,1,10.22-11.28c7.8-2.71,15.44-5.91,23.06-9.1,2.22-.93,3.57-.67,5.27,1.06q14.19,14.44,28.65,28.63c1.77,1.74,1.85,3.13,1,5.29-3.29,8-6.41,16.06-9.51,24.13a13.66,13.66,0,0,1-9.09,8.52c-18.22,6-36.39,12.14-54.6,18.17-1.17.39-2.76,1.67-3.7-.19-.78-1.53,1-2.17,1.76-3q13.17-13.31,26.44-26.52c1.13-1.13,2-2.16,4.16-1.79,5.84,1,11.44-4.41,11.43-10.5"
                            clipRule="evenodd"/>
                    </clipPath>
                </defs>
                {/*<title>draw-icon</title>*/}
                <g clipPath="url(#a)">
                    <g clipPath="url(#b)">
                        <rect y="140.46" width="164.57" height="116.16"/>
                    </g>
                </g>
                <g clipPath="url(#c)">
                    <g clipPath="url(#d)">
                        <g style={{isolation: 'isolate'}}>
                            <g clipPath="url(#e)">
                                <g clipPath="url(#f)">
                                    <g clipPath="url(#g)">
                                        <rect x="176" width="157.49" height="154.59"/>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
                <g clipPath="url(#h)">
                    <g clipPath="url(#d)">
                        <rect x="116.16" y="82.22" width="135.52" height="135.52"/>
                    </g>
                </g>
            </svg>
        )
    };

    static text = (classname, onclick) => {
        return (
            <svg className={classname} onClick={onclick} viewBox="0 0 360.85 375.76">
                <defs>
                    <clipPath id="a" transform="translate(37.88 37.88)">
                        <polyline points="285.09 120.48 285.09 300 0 300 0 0 285.09 0 285.09 120.48"
                                  clipRule="evenodd"/>
                    </clipPath>
                    <clipPath id="b" transform="translate(37.88 37.88)">
                        <rect x="-1363.68" y="-1757.63" width="7742.66" height="7992.67"/>
                    </clipPath>
                    <clipPath id="c" transform="translate(37.88 37.88)">
                        <rect x="-7.58" y="-7.58" width="295.46" height="310.62"/>
                    </clipPath>
                    <clipPath id="d" transform="translate(37.88 37.88)">
                        <path
                            d="M142.47,0q64.31,0,128.61,0c10.64,0,14,3.44,14,14.45q.06,23.7,0,47.42c0,9.06-3.79,13-12.32,13.11-2.18,0-4.36.05-6.54,0-7.57-.21-11.78-4.29-11.68-12.3.23-20.24.23-19.4-19.48-19.76-21.55-.4-43.11.08-64.67-.17-5.32-.06-7.59.94-7.57,7.42q.36,105.15,0,210.33c0,5.5,1.1,7.86,6.79,7.46,7.23-.5,14.52-.19,21.79-.1,8.47.1,12.12,4.1,12.23,13.2,0,2.55.1,5.1,0,7.65-.32,6.64-4.23,11.17-10.43,11.2q-50.51.18-101,0c-6.28,0-10.3-4.44-10.63-11-.16-3.3-.16-6.63,0-9.94.3-6.59,4.27-10.89,10.54-11.08,7.51-.21,15-.45,22.52.06,6,.42,7.78-1.56,7.75-8-.25-52.77-.14-105.55-.14-158.32,0-17.34-.19-34.68.11-52,.09-5.41-1.56-6.92-6.6-6.87-26.16.23-52.32.28-78.47,0-5.71-.07-7.2,2-6.72,7.51a110.53,110.53,0,0,1,.05,13C30.36,70.7,26.38,74.76,19.17,75c-2.9.09-5.82.18-8.72,0C4.24,74.52.31,70.88.21,64.39-.08,46.54,0,28.69.17,10.85.26,3.6,4.84,0,13.13,0Q77.79,0,142.47,0"
                            clipRule="evenodd"/>
                    </clipPath>
                    <clipPath id="e" transform="translate(37.88 37.88)">
                        <rect width="287.89" height="303.04"/>
                    </clipPath>
                </defs>
                <g clipPath="url(#a)">
                    <g clipPath="url(#b)">
                        <g clipPath="url(#c)">
                            <g clipPath="url(#d)">
                                <g clipPath="url(#e)">
                                    <rect width="360.85" height="375.76"/>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        )
    };

    static view = (classname, onclick) => {
        return <svg className={classname} onClick={onclick} width="24" height="24" viewBox="0 0 92 92" fill="#424445"
                    xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M1.24601 41.8892C5.85706 34.9608 20.739 16 46 16C71.261 16 86.1429 34.9608 90.754 41.8892C92.4153 44.3854 92.4153 47.6146 90.754 50.1108C86.1429 57.0392 71.261 76 46 76C20.739 76 5.85706 57.0392 1.24601 50.1108C-0.415337 47.6146 -0.415337 44.3854 1.24601 41.8892ZM46 21.6292C23.628 21.6292 10.1875 38.4828 5.81767 45.0488C5.42598 45.6373 5.42598 46.3627 5.81767 46.9512C10.1875 53.5172 23.628 70.3708 46 70.3708C68.372 70.3708 81.8125 53.5172 86.1823 46.9512C86.574 46.3627 86.574 45.6373 86.1823 45.0488C81.8125 38.4828 68.372 21.6292 46 21.6292Z"
                  fill=""/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M46.1968 60.3228C53.9984 60.3228 60.3228 53.9984 60.3228 46.1968C60.3228 38.3953 53.9984 32.0709 46.1968 32.0709C38.3953 32.0709 32.0709 38.3953 32.0709 46.1968C32.0709 53.9984 38.3953 60.3228 46.1968 60.3228ZM46.1968 65.3937C56.799 65.3937 65.3937 56.799 65.3937 46.1968C65.3937 35.5947 56.799 27 46.1968 27C35.5947 27 27 35.5947 27 46.1968C27 56.799 35.5947 65.3937 46.1968 65.3937Z"
                  fill=""/>
            <circle cx="51.1417" cy="49.1417" r="10.1417" fill=""/>
        </svg>

    }

    static witness = (classname, onclick) => {
        return <svg className={classname} onClick={onclick} width="24" height="24" viewBox="0 0 92 92" fill="#424445"
                    xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M2.69072 30.6705C5.24685 26.7444 13.4966 16 27.5 16C41.5034 16 49.7532 26.7444 52.3093 30.6705C53.2302 32.0851 53.2302 33.9149 52.3093 35.3295C49.7532 39.2556 41.5034 50 27.5 50C13.4966 50 5.24685 39.2556 2.69072 35.3295C1.76976 33.9149 1.76976 32.0851 2.69072 30.6705ZM27.5 19.1899C15.0981 19.1899 7.64743 28.7403 5.22501 32.461C5.00788 32.7945 5.00788 33.2055 5.22502 33.539C7.64743 37.2597 15.0981 46.8101 27.5 46.8101C39.9019 46.8101 47.3526 37.2597 49.775 33.539C49.9921 33.2055 49.9921 32.7945 49.775 32.461C47.3526 28.7403 39.9019 19.1899 27.5 19.1899Z"
                  fill=""/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M40.858 59.1071C40.3096 59.6512 39.7266 60.2296 39.1682 60.7368C38.3889 61.4447 37.1887 61.3809 36.4873 60.5944C35.786 59.8079 35.8491 58.5965 36.6284 57.8887C37.1266 57.4362 37.6021 56.964 38.1127 56.457C38.3838 56.1878 38.6648 55.9087 38.9643 55.6176C39.7792 54.8257 40.6909 53.9869 41.6702 53.328C42.6499 52.6688 43.8213 52.1035 45.1562 52.0127C46.5457 51.9182 47.8964 52.3519 49.1616 53.3096C50.7675 54.5252 51.1455 56.5416 51.1331 58.315C51.1201 60.1655 50.6842 62.3364 50.0463 64.5823C49.0084 68.237 47.34 72.4109 45.6379 76.3217L61.925 61.5696C62.9243 60.6645 64.1781 60.84 64.9363 61.2707C65.6871 61.6971 66.4377 62.6335 66.3004 63.889C66.1881 64.9164 66.1532 66.0411 66.2059 67.0358C66.2616 68.0868 66.4057 68.7891 66.5358 69.0955C66.9114 69.98 67.3314 70.4753 67.7227 70.7533C68.0972 71.0194 68.577 71.1811 69.2629 71.1678C70.7587 71.1387 72.9885 70.282 76.0954 68.2439C82.6332 63.9549 86.9637 62.1866 90.1017 62.1866C91.1501 62.1866 92 63.0444 92 64.1025C92 65.1606 91.1501 66.0184 90.1017 66.0184C88.1776 66.0184 84.6763 67.1847 78.1645 71.4566C74.9437 73.5696 71.9532 74.9481 69.3359 74.9989C67.9654 75.0255 66.6656 74.6883 65.5372 73.8866C64.4257 73.0969 63.6197 71.9557 63.0462 70.605C62.6437 69.6571 62.4761 68.3989 62.4147 67.2404C62.3985 66.9334 62.389 66.6189 62.3862 66.3004L45.9871 81.154C43.4024 83.4951 39.5801 80.5586 40.9833 77.4406C43.0594 72.8274 45.1986 67.7451 46.3967 63.5265C46.9988 61.4062 47.3272 59.6249 47.3366 58.2878C47.3465 56.8735 47.0051 56.4669 46.8836 56.375C46.2506 55.8958 45.782 55.8106 45.4116 55.8358C44.9866 55.8647 44.4575 56.0578 43.7762 56.5163C43.0944 56.975 42.3847 57.613 41.5978 58.3778C41.3628 58.6062 41.1141 58.8529 40.858 59.1071Z"
                  fill=""/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M28 41.2264C32.4704 41.2264 36.0943 37.7672 36.0943 33.5C36.0943 29.2328 32.4704 25.7736 28 25.7736C23.5296 25.7736 19.9057 29.2328 19.9057 33.5C19.9057 37.7672 23.5296 41.2264 28 41.2264ZM28 44C34.0751 44 39 39.299 39 33.5C39 27.701 34.0751 23 28 23C21.9249 23 17 27.701 17 33.5C17 39.299 21.9249 44 28 44Z"
                  fill=""/>
            <ellipse cx="31" cy="34.5" rx="6" ry="5.5" fill=""/>
        </svg>
    }
}
