import React from "react";
import {Icons} from "../../utils/Icons";
import styles from './ProgressIcon.module.scss';
import PropTypes from 'prop-types'
import ReactTooltip from "react-tooltip";

const ProgressIcon = ({status, size, showTitle}) => {

    const icon = () => {
        switch (status) {
            case 'IN_PROGRESS':
                return Icons.pen()
            case 'COMPLETED':
                return Icons.check()
            case 'RESOLVED':
                return Icons.check()
            case 'ACTIVE':
                return Icons.comments()
            case 'SIGNED':
                return Icons.check()
            case 'CANCELLED':
                return Icons.cross()
            case 'REJECTED':
                return Icons.alert()
            case 'PENDING_DEPS' || 'DRAFT':
                return Icons.ellipsis()
            case 'ACTION_REQUIRED':
                return Icons.alert()
            case 'SENT':
                return Icons.send()
            case 'AWAITING_SIGNATURES' :
                return Icons.sign()
            default :
                return Icons.ellipsis()
        }
    }

    const title = () => {
        switch (status) {
            case 'IN_PROGRESS':
                return 'In Progress'
            case 'COMPLETED':
                return 'Completed'
            case 'CANCELLED':
                return 'Cancelled'
            case 'PENDING_DEPS':
                return 'Pending'
            case 'ACTION_REQUIRED':
                return 'Action Required'
            case 'AWAITING_SIGNATURES':
                return 'Awaiting Signatures'
            case 'SENT':
                return 'Sent'
            case 'DRAFT':
                return 'Draft'
            case 'ACTIVE':
                return 'Active'
            case 'RESOLVED':
                return 'Resolved'
            case 'REJECTED':
                return 'Rejected'
            default :
                return 'In Progress'
        }
    }

    const statusClass = () => {
        switch (status) {
            case 'IN_PROGRESS':
                return styles.inProgress
            case 'COMPLETED':
                return styles.completed
            case 'RESOLVED':
                return styles.completed
            case 'CANCELLED':
                return styles.cancelled
            case 'REJECTED':
                return styles.rejected
            case 'PENDING_DEPS':
                return styles.pendingDeps
            case 'ACTION_REQUIRED':
                return styles.pendingAction
            case 'AWAITING_SIGNATURES':
                return styles.awaitingSignatures
            default :
                return styles.pendingDeps
        }
    }

    const sizeClass = () => {
        switch (size) {
            case 'sm':
                return styles.small
            case 'md':
                return styles.medium
            case 'lg':
                return styles.large
            case 'xl':
                return styles.xLarge
            default :
                return styles.medium
        }
    }

    const iconClass = `${statusClass()} ${sizeClass()} ${styles.progressIcon}`

    return <>
        <ReactTooltip delayShow={800} className={'toolTip'} id={'progress-icon'} effect={'solid'}/>
        <div data-for={'progress-icon'} data-tip={title()} className={iconClass}>
            {icon()}
            {showTitle && <span style={{margin: '0 4px'}}>{title()}</span>}
        </div>
    </>

}

ProgressIcon.propTypes = {
    status: PropTypes.string,
    size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
    showTitle: PropTypes.bool
}

ProgressIcon.defaultProps = {
    status: 'PENDING_DEPS',
    size: 'md'
}
export default ProgressIcon