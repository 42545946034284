import styles from 'LandingSite/layout/Footer/Footer.module.scss';
import React from 'react';
import {Link, withRouter} from 'react-router-dom'
import {Icons} from "utils/Icons";

const Footer = withRouter((props) => {
    const exclude = ['/login', '/register', '/account-verification', '/password', '/external/sign'];
    const path = props.location.pathname;
    if (exclude.some(e => path.includes(e))) {
        return <div/>
    }
    return (
        <footer className={styles.footer}>
            <div className={styles.left}>
                <Link id={'footer-home-link'} to={'/'}>
                    {Icons.acculexLogoInverted(styles.logo)}
                </Link>
            </div>
            <div className={styles.right}>
                <div className={styles.menuContainer}>
                    <h3 className={styles.heading}>Product</h3>
                    <div className={styles.content}>
                        <Link id={'footer-support-link'} to='/contact'>Support</Link>
                        <br/>
                        <Link id={'footer-pricing-link'} to='/pricing'>Pricing</Link>
                    </div>
                </div>
                <div className={styles.menuContainer}>
                    <h3 className={styles.heading}>Connect</h3>
                    <div className={styles.content}>
                        <a id={'footer-social-link-1'} href="https://facebook.com/acculexuk" target='_blank'
                           rel="noopener noreferrer">Facebook</a>
                        <br/>
                        <a id={'footer-social-link-2'} href="https://www.twitter.com/acculex"
                           target='_blank'
                           rel="noopener noreferrer">Twitter</a>
                        <br/>
                        <a id={'footer-social-link-2'} href="https://www.instagram.com/acculex"
                           target='_blank'
                           rel="noopener noreferrer">Instagram</a>
                        <br/>
                        <a id={'footer-social-link-2'} href="https://www.linkedin.com/company/acculex"
                           target='_blank'
                           rel="noopener noreferrer">Linkedin</a>
                    </div>
                </div>
                <div className={styles.menuContainer}>
                    <h3 className={styles.heading}>Information</h3>
                    <div className={styles.content}>
                        <Link id={'footer-about-link'} to='/about'>About</Link>
                        <br/>
                        <Link id={'footer-contact-link'} to='/contact'>Contact</Link>
                        <br/>
                        <Link id={'footer-contact-link'} to='/resources'>Resources</Link>
                        <br/>
                        <Link id={'footer-contact-link'} to='/faqs'>FAQs</Link>
                    </div>
                </div>
                <div className={styles.menuContainer}>
                    <h3 className={styles.heading}>Terms</h3>
                    <div className={styles.content}>
                        <Link id={'footer-terms-link'} to='/terms'>Site Terms and Conditions</Link>
                        <br/>
                        <Link id={'footer-privacy-link'} to='/privacy'>Privacy Policy</Link>
                    </div>
                </div>
                <div className={styles.disclaimer}>
                    © {new Date().getFullYear()} Claritas Research Ltd.
                </div>
            </div>
        </footer>
    );
});

export default Footer;