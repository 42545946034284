import HttpService from "service/web/HttpService";

export default class WorkflowWebService {

    static createWorkflow = async (workflow) => {
        return HttpService.postAsync('/workflow', workflow).then(r => r.data);
    }

    static createQfAnswers = async (qfAnswers) => {
        return HttpService.postAsync('/workflow/qf-answer', qfAnswers);
    }

    static approveContract = async (ctrApproval) => {
        return HttpService.postAsync('/workflow/approve-contract', ctrApproval).then(r => r.data);
    }

    static performAction = async (performAction) => {
        return HttpService.postAsync('/workflow/perform-action', performAction).then(r => r.data)
    }

    static deleteWorkflow = async (id) => {
        return HttpService.postAsync('/workflow/delete', {id}).then(r => r.data);
    }

    static updateWorkflow = async (workflow) => {
        return HttpService.putAsync('/workflow', workflow).then(r => r.data);
    }

    static validateQFStage = async (qfNodeId, stageId) => {
        return HttpService.postAsync('/workflow/qf/validate', {qfNodeId, stageId}).then(r => r.data);
    }
}