import {userStore} from "../stores";

export const PERSONAL_ACC_ID = 'personal';

const nthIndex = (str, pat, n) => {
    let L = str.length, i = -1;
    while (n-- && i++ < L) {
        i = str.indexOf(pat, i);
        if (i < 0) break;
    }
    return i;
}

export const getProductUrl = (id) => {
    return id.toLowerCase().replaceAll('_', '-')
}

export default class Nav {

    static keyPressWithTarget = (event, target) => {
        const code = event.keyCode || event.which;
        if (code === 13) {
            target.current.click();
        }
    }

    // Do not use
    static goTo(props, path) {
        let selectedAccount = userStore.selectedCompanyId;
        if (!path) {
            const currentPath = props.location.pathname;
            const idx = nthIndex(currentPath, '/', 3);
            if (idx >= 0) {
                path = currentPath.substring(idx + 1);
            } else {
                path = '';
            }
        }
        if (selectedAccount == null) {
            selectedAccount = PERSONAL_ACC_ID;
        }
        props.history.push(`/user/${selectedAccount}/${path}`);
    }
}