import styles from 'components/ButtonGroup/ButtonGroup.module.scss';
import React from 'react';
import PropTypes from 'prop-types';

const ButtonGroup = (props) => {
    return (
        <div style={props.column && {flex: '0 1 auto'}} className={styles.buttonGroup + ' ' + props.addClass}>
            {props.children}
        </div>
    )
}

ButtonGroup.propTypes = {
    addClass: PropTypes.string,
    column: PropTypes.bool
}

export default ButtonGroup