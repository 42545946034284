import styles from 'LandingSite/layout/Header/components/HeaderUser.module.scss';

import React, {useState} from 'react';
import PropTypes from 'prop-types'

import Button from "components/Button/Buttons";
import AuthWebService from "service/web/AuthWebService";
import {Icons} from "utils/Icons";
import {observer} from "mobx-react";
import {userStore} from "stores";
import UserImage from "components/UserCircle/UserImage";
import {useNavigation} from "hooks/Navigation";
import ExpandingMenu from "components/ExpandingMenu/ExpandingMenu";

const HeaderUser = ({showMobileMenu}) => {
    const [showUserMenu] = useState(false);

    const navigation = useNavigation();

    const renderUser = () => {
        if (!userStore.initialised) {
            return <UserImage/>;
        }
        if (userStore.isUser()) {
            const user = userStore.user;
            return <>
                <ExpandingMenu id={'user-menu'} trigger={<UserImage image={user.profileImageId} id={user.id} initials={user.initials}/>}>
                    {userStore.user.authorities.includes('ROLE_ADMIN') &&
                        <Button isLink onClick={() => navigation.goTo('/admin')}
                                icon={Icons.padlock()}
                                buttonClass={styles.userDropdownLink}
                                fillContainer={true}
                                title={'Admin Console'}/>
                    }
                    <Button buttonClass={styles.userDropdownLink}
                            icon={Icons.dashboard()}
                            isLink
                            title={'My dashboard'}
                            onClick={() => navigation.goToUserPath('dashboard')}/>
                    <Button buttonClass={styles.userDropdownLink}
                            icon={Icons.signOut()}
                            isLink
                            title={'Sign out'}
                            onClick={AuthWebService.logOut}/>
                </ExpandingMenu>
            </>;
        }

        return (
            <div className={styles.userLogin}>
                <Button buttonId={'header-user-register'} style={{marginRight: 16}}
                        onClick={() => navigation.goTo('/register')}
                        title={'Try for free'}
                        fillContainer={true}
                />
                <Button buttonId={'header-sign-in'} onClick={() => navigation.goTo('/login')}
                        isSecondary
                        icon={Icons.user()}
                        fillContainer={true}
                        title={'Sign in'}/>

            </div>
        );
    };

    const renderUserMenu = () => {
        if (userStore.user && (showUserMenu || showMobileMenu)) {
            return (
                <div className={styles.userMenu}>
                    {userStore.user.authorities.includes('ROLE_ADMIN') &&
                        <Button onClick={() => navigation.goTo('/admin')}
                                icon={Icons.padlock()}
                                buttonClass={styles.adminButton}
                                style={{marginRight: '24px'}}
                                fillContainer={true}
                                title={'Admin Console'}/>
                    }
                    <Button buttonId={'header-user-account-link'} class={styles.userMenuItem}
                            title={'Go to my dashboard'}
                            style={{marginRight: '24px'}}
                            onClick={() => navigation.goToUserPath('dashboard')}/>
                    <Button buttonId={'header-user-sign-out'}
                            title={'Sign out'}
                            isSecondary
                            onClick={AuthWebService.logOut}/>
                </div>
            );
        }
        return (<div/>);
    };

    return (
        <div className={styles.container}>
            {renderUserMenu()}
            {renderUser()}
        </div>
    );
};

HeaderUser.propTypes = {
    showMobileMenu: PropTypes.bool
};

export default observer(HeaderUser);