import 'LandingSite/layout/AccountServices/AccountServices.scss';
import React, {useEffect, useState} from 'react';
import AuthWebService from "service/web/AuthWebService";
import Button from "components/Button/Buttons";
import Loader from "components/Loader/Loader";
import {useHistory, useParams} from "react-router";


const AccountVerification = () => {

    const [verified, setVerified] = useState(false);
    const history = useHistory();

    const {token} = useParams();

    useEffect(() => {
        const fetchData = async () => {
            await AuthWebService.verifyAccount(token);
            setVerified(true);
        }
        fetchData();
    }, [token]);

    const showLogin = () => {
        history.push('/login');
    };

    if (!verified) {
        return <div style={{marginTop: '25%', width: '100%'}}><Loader/></div>;
    }

    return <>
        <h4 className={'acc-heading'}>Email verified</h4>
        <p style={{marginBottom: '24px'}}>Your account is ready to use. Sign in using the link below</p>
        <Button style={{width: '100%'}} onClick={showLogin} title={'Sign in'}/>
    </>
}

export default AccountVerification;