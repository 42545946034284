import React from "react";
import PropTypes from 'prop-types'
import Button from "components/Button/Buttons";
import styles from "modals/ConfirmationModal/ConfirmationModal.module.scss";
import {Icons} from "utils/Icons";
import ButtonGroup from "components/ButtonGroup/ButtonGroup";
import ModalDialog, {ModalHeader} from "patterns/ModalDialog/ModalDialog";

const ConfirmationModal = (props) => {

    const renderDescription = () => {
        return (
            <div className={styles.description}>{Icons.info(styles.infoIcon)}{props.description}</div>
        )
    }

    return (
        <ModalDialog fillMobile={false} size={'sm'} showCloseButton={false} title={props.title}
                     isOpen={props.isOpen} toggle={props.toggle}>
            <ModalHeader showCloseButton={false} title={props.title} toggle={props.toggle}/>
            <div className={styles.bodyText}>{props.text}</div>
            {props.description && renderDescription()}
            {props.children && <div style={{padding: '0 20px 24px'}}>{props.children}</div>}
            <ButtonGroup addClass={styles.buttonGroup}>
                <Button isLink title={props.cancelMessage ? props.cancelMessage : 'No, take me back'} onClick={props.toggle}
                        style={{marginRight: '24px'}}/>
                <Button title={props.confirmMessage ? props.confirmMessage : 'Yes, I\'m sure'} onClick={props.submit}/>
            </ButtonGroup>
        </ModalDialog>
    );
}

ConfirmationModal.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    description: PropTypes.string,
    isOpen: PropTypes.bool,
    submit: PropTypes.func,
    toggle: PropTypes.func,
    confirmMessage: PropTypes.string,
    cancelMessage: PropTypes.string
}

export default ConfirmationModal;