import {action, makeAutoObservable} from "mobx";

class WebsiteConfigStore {
    websiteConfig = {}
    //
    initialised = false;

    initialise = (websiteConfig) => {
        if (websiteConfig) {
            this.setWebsiteConfig(websiteConfig);
        }
        this.initialised = true;
    }

    @action setWebsiteConfig(websiteConfig) {
        this.websiteConfig = websiteConfig
    }

    constructor() {
        makeAutoObservable(this);
    }
}

export const websiteConfigStore = new WebsiteConfigStore();
