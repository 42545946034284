import ConfigService from "service/ConfigService";
import HttpService, {NotificationPolicy, ReportIssuePolicy, RetryPolicy} from "service/web/HttpService";
import ReactGA from "react-ga4";

export default class EventWebService {
    static initialise = async () => {
        ReactGA.initialize("G-DWW3ZSNL81");
    }

    static pageView = async (page = window.location.pathname + window.location.search, prevPage = null) => {
        const isActive = await EventWebService.isActive();
        if (isActive) {
            await EventWebService._send({url: page, prevUrl: prevPage})
        }
        if(!page.includes('/user') && !page.includes('/password') && !page.includes('/account-verification')) {
            ReactGA.send({ hitType: "pageview", page});
        }
    }

    static event = async (category, action, label = null, value = null, nonInteraction = false) => {
        const isActive = await EventWebService.isActive();
        if (isActive) {
            await EventWebService._send({category, action, label, value, nonInteraction});
        }
    }

    static _send = async (data) => {
        try {
            await HttpService.postAsync('/data/ping', data, {
                retryPolicy: RetryPolicy.NO_RETRY,
                notificationPolicy: NotificationPolicy.HIDE,
                reportIssuePolicy: ReportIssuePolicy.SUPPRESS
            });
        } catch (error) {
            //
        }
    }

    static isActive = async () => {
        return ConfigService.getConfigAsync('is_page_events_enabled');
    };
}