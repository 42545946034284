import styles from 'LandingSite/layout/Error404/Error404.module.scss'
import React from 'react'
import Button from "components/Button/Buttons";
import Error from "images/home/searching_for_a_404.svg"
import {userStore} from "stores";
import {observer} from "mobx-react";
import * as PropTypes from "prop-types";
import {useNavigation} from "hooks/Navigation";
import ButtonGroup from "components/ButtonGroup/ButtonGroup";

const ErrorPage = observer((props) => {

    const navigation = useNavigation();

    return (
        <div className={styles.errorContents}>
            <div className={styles.errorHero}>
                <div className={styles.errorContent}>
                    <div className={styles.errorImg}>
                        <img src={Error} loading={'lazy'}
                             alt={'woman with a magnifying glass looking for a webpage'}/>
                    </div>
                    <div className={styles.errorText}>
                        <h1>{props.message}</h1>
                        <h5>Let’s not focus on the bad, let’s move on together.</h5>
                        <ButtonGroup addClass={styles.buttonGroup}>
                            <Button style={{marginRight: 16}} buttonId={'home-hero-create-account'} class={styles.button}
                                    onClick={userStore.isUser() ? () => navigation.goToUserPath('dashboard') : () => navigation.goTo('/register')}
                                    title={userStore.isUser() ? 'Take me to my dashboard' : 'Set up a free account'}/>
                            {!userStore.isUser() &&
                                <Button isSecondary buttonId={'home-hero-create-account'} class={styles.button}
                                        onClick={() => navigation.goTo('/')}
                                        title={'Go to the homepage'}/>}
                        </ButtonGroup>
                    </div>
                </div>
            </div>
        </div>
    );
});

ErrorPage.propTypes = {
    message: PropTypes.string
};

ErrorPage.defaultProps = {
    message: 'Oops, we can’t seem to find that page'
};

export default ErrorPage;

